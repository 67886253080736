import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { AuthContext } from '../../hoc/AuthProvider';
import PartnersList from '../../components/Partners/PartnersList';
import PartnerService from '../../services/PartnerService';
import { getPageCount, getPagesArray } from '../../utils/pages';
import PaginationPortal from '../../components/Pagination/PaginationPortal';
import config from '../../settings/settings';
import Modal from './PartnersModal';
import { useDebounce } from '../../hooks/useDebounce';
import { ReactComponent as ResetFilter } from '../../img/cross.svg';
import s from './partner.module.css';

const Partners = () => {
	const { store } = useContext(AuthContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const params = useParams();

	const [message, setMessage] = useState('');

	const [partner, setPartner] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const limit = config?.partnerDoc;
	const [page, setPage] = useState(parseInt(params.page));
	const [inputValue, setInputValue] = useState(parseInt(params.page));
	const [xTotal, setXtotal] = useState('0');

	let pagesArray = getPagesArray(totalPages);

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [listVidi, setListVidi] = useState([]);

	//Строка поиска
	const startQueryValue = searchParams.get('text') || '';
	const [queryValue, setQueryValue] = useState(startQueryValue);
	//Вид обработки
	const startCategory = searchParams.get('category')
		? searchParams.get('category').split('.')
		: [];
	const [selected, setSelected] = useState(startCategory);
	//Колбэк выбора вида обработки
	const onSelect = (select) => {
		setSelected(select);
		setPage(1);
		setInputValue(1);
	};

	const resetSearch = () => {
		setPage(1);
		setInputValue(1);
		setQueryValue('');
		setSelected([]);
		setSearchParams({ text: '', category: '' });
	};

	const debouncedSearchTerm = useDebounce(queryValue, 500);

	useEffect(() => {
		const getPartners = async () => {
			try {
				let vidi = [];
				setIsLoading(true);

				if (store.isAuth) vidi = await PartnerService.getUsersToms();

				setListVidi(vidi.data);

				setMessage('');
				store.isLoading = true;

				let response = [];
				if (!store.isAuth)
					response = await PartnerService.fetchPartner(page, limit);
				if (store.isAuth) {
					response = await PartnerService.postPartnersRequest(
						page,
						limit,
						queryValue,
						selected
					);
					setSearchParams({ text: queryValue, category: selected.join('.') });
				}

				setPartner(response.data);
				setIsLoading(false);

				const totalCount = response.headers['x-total-partners'];
				setXtotal(totalCount);
				setTotalPages(getPageCount(totalCount, limit));

				window.scrollTo(0, 0);
			} catch (e) {
				setMessage(e?.response?.data?.message);
				setIsError(true);
				setIsLoading(false);
			} finally {
				store.isLoading = false;
			}
		};
		getPartners();
	}, [page, debouncedSearchTerm, selected]);

	const changePage = async (event, value) => {
		setPage(value);
		setInputValue(value);
	};

	const handlerTegFind = async (e) => {
		const value = e.target.value;
		setPage(1);
		setInputValue(1);
		setQueryValue(value);
	};

	const handleOpenModal = (e) => {
		e.preventDefault();
		setIsOpenMenu(true);
	};

	const [isOpenMenu, setIsOpenMenu] = useState(false);

	return (
		<div className={s.wrapper1}>
			<div className={s.wrapper2}>
				<section className={s.body}>
					<div className=" max-w-2xl md:max-w-3xl lg:px-0 lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl mx-auto pb-8 w-full">
						<div className="flex justify-center items-center">
							<h1 className="block text-xl py-6 md:py-12 md:text-2xl xl:text-2xl font-bold text-center text-gray-850">
								НАШИ ПАРТНЕРЫ И УЧАСТНИКИ АССОЦИАЦИИ
							</h1>
						</div>

						{store.isAuth && (
							<form onSubmit={(e) => e.preventDefault()} className={s.form}>
								<span className="span-title">
									Здесь осуществляется поиск по компаниям производителям
								</span>
								<div className={s.search}>
									<input
										type="text"
										placeholder="Введите тег, город или название предриятия"
										onChange={handlerTegFind}
										value={queryValue}
										id="form-input"
									/>
									<button onClick={handleOpenModal} type="button">
										Выберете интересующие виды мехобработки
									</button>
									<ResetFilter
										className={s.resetFilterIcon}
										onClick={resetSearch}
									/>
									{selected?.length > 0 ? (
										<div className={s.index}>{selected?.length}</div>
									) : null}
								</div>
								<div className={s.finded}>
									<span>Найдено &nbsp;</span> {xTotal} компаний
								</div>
							</form>
						)}

						{isOpenMenu && (
							<div className={s.findModal}>
								<Modal
									setIsOpenMenu={setIsOpenMenu}
									data={listVidi}
									selected={selected}
									setSelected={onSelect}
								/>
							</div>
						)}

						<div className="text-red-500 p-y-2 font-semibold">{message}</div>

						<PartnersList data={partner} isLoading={isLoading} />

						{isError && (
							<p className="text-red-500 text-center font-semibold mt-60 mb-60">
								Cервер недоступен
							</p>
						)}
					</div>

					{partner?.length && (
						<PaginationPortal
							data={pagesArray.length}
							page={page}
							prefixUrl={'partners'}
							changePage={changePage}
							setPage={setPage}
							inputValue={inputValue}
							setInputValue={setInputValue}
						/>
					)}
				</section>
			</div>
		</div>
	);
};

export default observer(Partners);
