import s from './preloader.module.css';

export const Preloader = () => {
	const bodyHeight = window.document.body.offsetHeight;
	return (
		<div className={s.wrapper} style={{ height: bodyHeight }}>
			<div className={s.lds}>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};
