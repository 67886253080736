import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Tooltip from '@mui/material/Tooltip';
import DraftService from '../../services/DraftService';
import DetailSlider from '../../components/DetailSlider/DetailSlider';
import config, { filesUrl } from '../../settings/settings';
import { postFiles } from '../../utils/services';
import { SkeletonPlantDetails } from '../../components/Skeletons/SkeletonPlantDetails/SkeletonPlantDetails';
import { AuthContext } from '../../hoc/AuthProvider';
import { ChatContext } from '../../context/ChatContext';
import { DialogFirstMessage } from '../../components/Dialogs/DialogFirstMessage/DialogFirstMessage';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Paper, Box } from '@mui/material';

import docIco from '../../img/doc-ico.svg';
import imgIco from '../../img/img-ico.svg';
import s from './draftDetails.module.css';
import { Preloader } from '../../components/Preloader/Preloader';
import { numFormat } from '../../utils/numFormat';

const { ZNAK, ZNAK2 } = config;

const DraftDetails = () => {
	const location = useLocation();
	const { store } = useContext(AuthContext);
	const { sendTextMessage, setNewMessage } = useContext(ChatContext);
	const navigate = useNavigate();
	const { id } = useParams();

	const urlBackNavigate = '/draft/' + (location.state?.page || 1);

	const [item, setItem] = useState([]);
	const [img, setImg] = useState([]);

	const [textAlert, setTextAlert] = useState('');

	const [openAlert, setOpenAlert] = useState(false);
	const [zopen, setZopen] = useState(false);
	// dialog
	//Отправка сообщения админу
	const [openDialog, setOpenDialog] = useState(false);
	//Отправка сообщения пользователю в чат
	const [zOpenDialog, setZopenDialog] = useState(false);
	const [isServiceOwner, setIsServiceOwner] = useState({
		legend: '',
		raiting: 0,
		service: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	//Preloader
	const [isShowPreloader, setIsShowPreloader] = useState(false);

	const [textValue, setTextValue] = useState('');
	const [filesList, setFilesList] = useState([]);

	const handleClickOpen = () => {
		setOpenDialog(true);
	};

	const handleClickOpenZ = () => {
		setZopenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setTextValue('');
	};

	const handleCloseDialogZ = () => {
		setZopenDialog(false);
		setTextValue('');
	};

	//запрос актуализации
	const requestActual = () => {
		setTextValue('Здравствуйте, этот заказ еще актуален?');
		const isServiceUser = isServiceOwner.service;
		if (isServiceUser) {
			//Если пользователь служебный
			setOpenDialog(true); //Модалка для отправки письма админу
		} else {
			setZopenDialog(true); //Модалка для отправки сообщения в чат пользователю
		}
	};

	//Отправка письма на почту админу
	const sendMailToAdmin = async (text) => {
		const url = `${config?.mainUrl}${location?.pathname}`;

		const formData = new FormData();
		formData.append('textValue', text);
		formData.append('url', url);

		Object.values(uploadFile).forEach((photo) => {
			formData.append('uploadFile', photo);
		});

		//Отправка письма на почту админу
		return await DraftService.sendZvk(formData);
	};

	//Связь через АМП
	const handleSend = async (e) => {
		try {
			if (uploadFile.length > 5) {
				store?.setMessage('Загружено больше 5 файлов');
				return;
			}
			store?.setMessage('');
			store?.setLoading(true);
			setIsShowPreloader(true);
			//Отправка письма на почту админу
			const response = await sendMailToAdmin(textValue);
			console.log(response);
			setOpenDialog(false);
			setOpenAlert(true);
			setTextValue('');
			setUploadFile([]);
		} catch (error) {
			store?.setMessage(error?.response?.data?.message);
			store?.setLoading(false);
		} finally {
			store?.setLoading(false);
			setIsShowPreloader(false);
		}
	};
	//Отправка сообщения владельцу заказа
	const handleSendZ = async (e) => {
		try {
			const formData = new FormData();
			formData.append('senderId', store?.user?._id);
			formData.append('title', `${item?.title} ( № ${item?.number} )`);
			formData.append('text', textValue.trim());
			formData.append('receiverId', item?.inhere_user);

			store?.setMessage('');
			store?.setLoading(true);
			setIsShowPreloader(true);
			const response = await DraftService.firstMessage(formData); //создается чат
			//Сетаем новое сообщение в контекст для отправки нотификации получателю
			//и загрузки нового списка чатов
			setNewMessage({ ...response.data, recipientId: item?.inhere_user });
			//Если были загружены файлы - отправляются допол. сообщения с ссылками на файлы
			for (const key in filesList) {
				if (Object.hasOwnProperty.call(filesList, key)) {
					const fData = new FormData();
					const element = filesList[key];
					fData.append('files', element);
					const res = await postFiles(fData);

					const pathFile = `${filesUrl}/files/${res?.data[0]?.filename}`;
					const realNameFile = element?.name;
					await sendTextMessage(
						textValue,
						store?.user,
						response?.data.chatId,
						setTextValue,
						pathFile,
						realNameFile
					);
					setFilesList([]);
				}
			}
			// Если в тексте есть слова про актуальность
			const matchWords = ['актуальную', 'аткуальную', 'актуален', 'аткуален'];
			const isActualRequest = matchWords.some((word) =>
				textValue.includes(word)
			);
			if (isActualRequest) {
				// отправляем почтовое сообщение админу
				sendMailToAdmin(
					`Интересуется актуальностью заказа № ${item?.number}. Между пользователями создан чат.`
				);
			}
			setZopenDialog(false);
			setTextAlert('В диалоги добавлено сообщение.');
			setOpenAlert(true);
			setTextValue('');
			setFilesList([]);
		} catch (error) {
			store?.setMessage(error?.response?.data?.message);
			store?.setLoading(false);
		} finally {
			store?.setLoading(false);
			setIsShowPreloader(false);
		}
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenAlert(false);
		setTextAlert('');
		store?.setMessage('');
	};

	const handleCloseZ = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setZopen(false);
	};

	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});
	// enddialog

	useEffect(() => {
		const getDraft = async (id) => {
			try {
				setIsLoading(true);
				const response = await DraftService.fetchItemDraft(id);
				setIsLoading(false);
				const data = response.data?.data;
				const img = data?.photo_url.filter(
					(f) => !f?.filename.includes('pdf') && !f?.filename.includes('DVG')
				);
				setImg(img);
				data.kl = numFormat(data.kl, false);
				setItem(data);
				setIsServiceOwner(response?.data.service);
			} catch (e) {
				console.log(e);
			} finally {
				setIsLoading(false);
			}
		};
		getDraft(id);
	}, []);

	const pageFromUrl = window.location.pathname.split('/').splice(-3, 1)[0];

	const [uploadFile, setUploadFile] = useState([]);

	const fillingStar = (raiting) => {
		const number = 5 - raiting;
		// const arg1 = [...Array(raiting).keys()].fill(ZNAK);
		// const arg2 = [...Array(number).keys()].fill(ZNAK2);
		const arg1 = Array(raiting).fill(ZNAK);
		const arg2 = Array(number).fill(ZNAK2);
		const result = arg1.concat(arg2);
		return result;
	};
	const grafRaiting = fillingStar(isServiceOwner.raiting);

	if (isLoading) {
		return <SkeletonPlantDetails />;
	}

	return (
		<div className={s.wrapper}>
			<div className={s.wrapper2}>
				{isShowPreloader && <Preloader />}
				<Snackbar
					open={openAlert}
					autoHideDuration={4000}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						severity="success"
						sx={{ width: '100%' }}
					>
						Заявка отправлена! {textAlert}
					</Alert>
				</Snackbar>
				{/* Show error message */}
				<Snackbar
					open={store.message}
					autoHideDuration={4000}
					onClose={handleClose}
				>
					<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
						{store.message}
					</Alert>
				</Snackbar>
				{/* dialogform */}
				{/* Связь с АМП - можно прикрепить до 5 файлов. Отправка сообщения на email  */}
				<DialogFirstMessage
					open={openDialog}
					onClose={handleCloseDialog}
					textValue={textValue}
					setTextValue={setTextValue}
					handleSend={handleSend}
					filesList={uploadFile}
					setFilesList={setUploadFile}
					variant={1}
					title="Отправить заявку"
					details="В поле ниже вы можете описать ваше коммерческое предложение по
          заказу"
				/>

				{/* dialogform */}
				<DialogFirstMessage
					open={zOpenDialog}
					onClose={handleCloseDialogZ}
					textValue={textValue}
					setTextValue={setTextValue}
					handleSend={handleSendZ}
					filesList={filesList}
					setFilesList={setFilesList}
					title="Написать заказчику"
					details="В поле ниже вы можете описать ваши вопросы к заказчику"
				/>

				<div className={s.content}>
					<div className={s.image}>
						<button
							onClick={() => navigate(urlBackNavigate)}
							className={s.backBtn}
						>
							Назад
						</button>
						<DetailSlider items={item?.photo_url} />
					</div>

					<div className={s.info}>
						<div className={s.infoNumber}>{item?.number}</div>
						<div className={s.infoTitle}>{item?.title}</div>

						<div className={s.infoDetails}>{item?.details}</div>
						<div className={s.infoCategory}>
							<strong>Виды работ:</strong>
							{item?.work_info?.map((item, index) => (
								<div key={index}>{item?.name}</div>
							))}
						</div>

						<div className={s.infoKl}>
							Количество:
							{item?.kl === 0 && 'не указано'}
							{item?.kl !== 0 &&
								item?.kl_text === 'партия' &&
								` ${item?.kl} шт.`}
							{item?.kl !== 0 &&
								item?.kl_text === 'мес/шт' &&
								` ${item?.kl} шт/мес`}
							{item?.kl !== 0 &&
								item?.kl_text === 'год/шт' &&
								` ${item?.kl} шт/год`}
							{item?.kl !== 0 && item?.kl_text === 'шт.' && ` ${item?.kl} шт.`}
						</div>

						<div className={s.infoMany}>
							<strong>Бюджет:</strong>
							{item?.many !== null &&
							item?.many !== undefined &&
							item?.many !== '' &&
							item?.many != 0
								? ` ${
										numFormat(item?.many) ||
										parseFloat(item?.many).toLocaleString()
								  }`
								: ` договорной`}
						</div>

						<div className={s.infoCities}>
							Город:
							{item?.cities ? ` ${item?.cities}` : ` информация уточняется`}
						</div>
						{/* Инфо о неакутальности */}
						{!item.isActual && (
							<div className={s.requestActual}>
								<span>
									Заказ был опубликован больше месяца назад. Рекомендуется
									уточнить актуальность информации.
								</span>
								<button className={s.actBtn} onClick={requestActual}>
									Уточнить актуальность
								</button>
							</div>
						)}
						<Paper
							elevation={3}
							sx={{ mt: 2, p: 2, width: '75%', fontSize: 18 }}
						>
							<Box sx={{ mb: 1 }}>
								<strong>Звездный рейтинг АМП:</strong> <br />
								<span style={{ fontSize: 20 }}>{grafRaiting}</span>
							</Box>
							{!!isServiceOwner.legend && (
								<Box>
									<strong>Статус:</strong> {isServiceOwner.legend}
								</Box>
							)}
						</Paper>

						<div className={s.infoDoc}>
							{item?.file_url?.[0] && <span>Документы для скачивания:</span>}

							{item?.file_url?.map((item, index) => {
								const info = item?.filename.split('.').splice(-1, 1)[0];
								return (
									<div className={s.docItem} key={index}>
										<img
											alt=""
											src={info == 'jpg' || info == 'png' ? imgIco : docIco}
										/>
										<a
											href={
												config?.baseUrlUpload + '/uploads/' + item?.filename
											}
											target="_blank"
											noreferel="true"
										>
											{item?.filename}
										</a>
									</div>
								);
							})}
						</div>
						<button
							type="submit"
							onClick={handleClickOpen}
							className={s.ampBtn}
						>
							Связь с АМП
						</button>
						{isServiceOwner.service ? (
							<Tooltip
								title="Заказчик предпочитает контактировать через АМП"
								followCursor
							>
								<button className={s.btnDisabled}>Написать заказчику</button>
							</Tooltip>
						) : (
							<button
								type="submit"
								onClick={handleClickOpenZ}
								className={s.ampBtn}
							>
								Написать заказчику
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(DraftDetails);
