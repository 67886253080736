let cities = [
	{
		coords: {
			lat: '52.65',
			lon: '90.08333',
		},
		district: 'Сибирский',
		value: 'Абаза',
		population: 17111,
		subject: 'Хакасия',
	},
	{
		coords: {
			lat: '53.71667',
			lon: '91.41667',
		},
		district: 'Сибирский',
		value: 'Абакан',
		population: 165183,
		subject: 'Хакасия',
	},
	{
		coords: {
			lat: '53.68333',
			lon: '53.65',
		},
		district: 'Приволжский',
		value: 'Абдулино',
		population: 20663,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '44.86667',
			lon: '38.16667',
		},
		district: 'Южный',
		value: 'Абинск',
		population: 34926,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '55.9',
			lon: '53.93333',
		},
		district: 'Приволжский',
		value: 'Агидель',
		population: 16365,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '56.51667',
			lon: '52.98333',
		},
		district: 'Приволжский',
		value: 'Агрыз',
		population: 19299,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '44.884525',
			lon: '39.19202',
		},
		district: 'Южный',
		value: 'Адыгейск',
		population: 12689,
		subject: 'Адыгея',
	},
	{
		coords: {
			lat: '54.85',
			lon: '53.06667',
		},
		district: 'Приволжский',
		value: 'Азнакаево',
		population: 34859,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '47.1',
			lon: '39.41667',
		},
		district: 'Южный',
		value: 'Азов',
		population: 82882,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '51.16667',
			lon: '90.6',
		},
		district: 'Сибирский',
		value: 'Ак-Довурак',
		population: 13469,
		subject: 'Тыва',
	},
	{
		coords: {
			lat: '47.26667',
			lon: '39.86667',
		},
		district: 'Южный',
		value: 'Аксай',
		population: 41984,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '43.04167',
			lon: '44.21056',
		},
		district: 'Северо-Кавказский',
		value: 'Алагир',
		population: 20949,
		subject: 'Северная Осетия — Алания',
	},
	{
		coords: {
			lat: '57.85',
			lon: '61.7',
		},
		district: 'Уральский',
		value: 'Алапаевск',
		population: 38198,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '54.85',
			lon: '46.58333',
		},
		district: 'Приволжский',
		value: 'Алатырь',
		population: 38202,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '58.6',
			lon: '125.38333',
		},
		district: 'Дальневосточный',
		value: 'Алдан',
		population: 21277,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '52.5',
			lon: '82.78333',
		},
		district: 'Сибирский',
		value: 'Алейск',
		population: 28528,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '56.39361',
			lon: '38.715',
		},
		district: 'Центральный',
		value: 'Александров',
		population: 61544,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '50.9',
			lon: '142.15',
		},
		district: 'Дальневосточный',
		value: 'Александровск-Сахалинский',
		population: 10613,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '59.16667',
			lon: '57.58333',
		},
		district: 'Приволжский',
		value: 'Александровск',
		population: 15022,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '50.63',
			lon: '38.68639',
		},
		district: 'Центральный',
		value: 'Алексеевка',
		population: 39026,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '54.5',
			lon: '37.06667',
		},
		district: 'Центральный',
		value: 'Алексин',
		population: 61738,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '55.55',
			lon: '98.66667',
		},
		district: 'Сибирский',
		value: 'Алзамай',
		population: 6751,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '44.41972',
			lon: '34.04306',
		},
		district: 'Южный',
		value: 'Алупка',
		population: 7771,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '44.66722',
			lon: '34.39778',
		},
		district: 'Южный',
		value: 'Алушта',
		population: 29078,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '54.9',
			lon: '52.3',
		},
		district: 'Приволжский',
		value: 'Альметьевск',
		population: 146309,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '50.21667',
			lon: '136.9',
		},
		district: 'Дальневосточный',
		value: 'Амурск',
		population: 42977,
		subject: 'Хабаровский край',
	},
	{
		coords: {
			lat: '64.73333',
			lon: '177.51667',
		},
		district: 'Дальневосточный',
		value: 'Анадырь',
		population: 13053,
		subject: 'Чукотский АО',
	},
	{
		coords: {
			lat: '44.89444',
			lon: '37.31667',
		},
		district: 'Южный',
		value: 'Анапа',
		population: 58983,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '52.56667',
			lon: '103.91667',
		},
		district: 'Сибирский',
		value: 'Ангарск',
		population: 233765,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '56.65',
			lon: '32.26667',
		},
		district: 'Центральный',
		value: 'Андреаполь',
		population: 8265,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '56.08333',
			lon: '86.03333',
		},
		district: 'Сибирский',
		value: 'Анжеро-Судженск',
		population: 76669,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '46.71667',
			lon: '142.51667',
		},
		district: 'Дальневосточный',
		value: 'Анива',
		population: 8449,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '67.5675',
			lon: '33.39333',
		},
		district: 'Северо-Западный',
		value: 'Апатиты',
		population: 59690,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '55.53333',
			lon: '37.05',
		},
		district: 'Центральный',
		value: 'Апрелевка',
		population: 18467,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '44.46083',
			lon: '39.74056',
		},
		district: 'Южный',
		value: 'Апшеронск',
		population: 40229,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '56.7',
			lon: '60.83333',
		},
		district: 'Уральский',
		value: 'Арамиль',
		population: 14227,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '43.29444',
			lon: '45.88389',
		},
		district: 'Северо-Кавказский',
		value: 'Аргун',
		population: 29528,
		subject: 'Чечня',
	},
	{
		coords: {
			lat: '54.85',
			lon: '46.23333',
		},
		district: 'Приволжский',
		value: 'Ардатов',
		population: 9400,
		subject: 'Мордовия',
	},
	{
		coords: {
			lat: '43.17278',
			lon: '44.29222',
		},
		district: 'Северо-Кавказский',
		value: 'Ардон',
		population: 18774,
		subject: 'Северная Осетия — Алания',
	},
	{
		coords: {
			lat: '55.38333',
			lon: '43.8',
		},
		district: 'Приволжский',
		value: 'Арзамас',
		population: 106367,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '51.93333',
			lon: '43.5',
		},
		district: 'Приволжский',
		value: 'Аркадак',
		population: 12846,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '45',
			lon: '41.11667',
		},
		district: 'Южный',
		value: 'Армавир',
		population: 188897,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '46.10694',
			lon: '33.69306',
		},
		district: 'Южный',
		value: 'Армянск',
		population: 21987,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '44.16667',
			lon: '133.26667',
		},
		district: 'Дальневосточный',
		value: 'Арсеньев',
		population: 56742,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '56.09056',
			lon: '49.87639',
		},
		district: 'Приволжский',
		value: 'Арск',
		population: 18114,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '43.35',
			lon: '132.18333',
		},
		district: 'Дальневосточный',
		value: 'Артём',
		population: 102636,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '54.35',
			lon: '93.43333',
		},
		district: 'Сибирский',
		value: 'Артёмовск',
		population: 2180,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '57.33639',
			lon: '61.89194',
		},
		district: 'Уральский',
		value: 'Артёмовский',
		population: 32878,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '64.55',
			lon: '40.53333',
		},
		district: 'Северо-Западный',
		value: 'Архангельск',
		population: 348716,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '57.01027679',
			lon: '61.45639038',
		},
		district: 'Уральский',
		value: 'Асбест',
		population: 70067,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '57',
			lon: '86.15',
		},
		district: 'Сибирский',
		value: 'Асино',
		population: 25614,
		subject: 'Томская область',
	},
	{
		coords: {
			lat: '46.33333',
			lon: '48.03333',
		},
		district: 'Южный',
		value: 'Астрахань',
		population: 520662,
		subject: 'Астраханская область',
	},
	{
		coords: {
			lat: '51.86667',
			lon: '45',
		},
		district: 'Приволжский',
		value: 'Аткарск',
		population: 25620,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '48.28333',
			lon: '46.16667',
		},
		district: 'Южный',
		value: 'Ахтубинск',
		population: 41898,
		subject: 'Астраханская область',
	},
	{
		coords: {
			lat: '56.26667',
			lon: '90.5',
		},
		district: 'Сибирский',
		value: 'Ачинск',
		population: 109156,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '55',
			lon: '57.25',
		},
		district: 'Уральский',
		value: 'Аша',
		population: 31916,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '59.38333',
			lon: '35.95',
		},
		district: 'Северо-Западный',
		value: 'Бабаево',
		population: 12074,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '51.71667',
			lon: '105.86667',
		},
		district: 'Сибирский',
		value: 'Бабушкин',
		population: 4542,
		subject: 'Бурятия',
	},
	{
		coords: {
			lat: '54.4',
			lon: '53.25',
		},
		district: 'Приволжский',
		value: 'Бавлы',
		population: 22109,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '54.38333',
			lon: '20.63333',
		},
		district: 'Северо-Западный',
		value: 'Багратионовск',
		population: 6399,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '51.51722',
			lon: '104.15611',
		},
		district: 'Сибирский',
		value: 'Байкальск',
		population: 13589,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '52.58333',
			lon: '58.31667',
		},
		district: 'Приволжский',
		value: 'Баймак',
		population: 17710,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '54.93333',
			lon: '58.8',
		},
		district: 'Уральский',
		value: 'Бакал',
		population: 20953,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '43.683333',
			lon: '43.533333',
		},
		district: 'Северо-Кавказский',
		value: 'Баксан',
		population: 36857,
		subject: 'Кабардино-Балкария',
	},
	{
		coords: {
			lat: '55.18333',
			lon: '36.65',
		},
		district: 'Центральный',
		value: 'Балабаново',
		population: 26337,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '52.03333',
			lon: '47.78333',
		},
		district: 'Приволжский',
		value: 'Балаково',
		population: 199576,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '56.48083',
			lon: '43.54028',
		},
		district: 'Приволжский',
		value: 'Балахна',
		population: 51526,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '55.8',
			lon: '37.93333',
		},
		district: 'Центральный',
		value: 'Балашиха',
		population: 215353,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '51.55',
			lon: '43.16667',
		},
		district: 'Приволжский',
		value: 'Балашов',
		population: 82222,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '51.57694',
			lon: '116.64917',
		},
		district: 'Сибирский',
		value: 'Балей',
		population: 12536,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '54.65',
			lon: '19.91667',
		},
		district: 'Северо-Западный',
		value: 'Балтийск',
		population: 32670,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '55.35',
			lon: '78.35',
		},
		district: 'Сибирский',
		value: 'Барабинск',
		population: 30250,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '53.347361',
			lon: '83.77833',
		},
		district: 'Сибирский',
		value: 'Барнаул',
		population: 635585,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '53.65',
			lon: '47.11667',
		},
		district: 'Приволжский',
		value: 'Барыш',
		population: 17149,
		subject: 'Ульяновская область',
	},
	{
		coords: {
			lat: '47.13333',
			lon: '39.75',
		},
		district: 'Южный',
		value: 'Батайск',
		population: 112400,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '44.75278',
			lon: '33.86083',
		},
		district: 'Южный',
		value: 'Бахчисарай',
		population: 27448,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '57.78333',
			lon: '36.7',
		},
		district: 'Центральный',
		value: 'Бежецк',
		population: 24517,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '48.17472',
			lon: '40.79306',
		},
		district: 'Южный',
		value: 'Белая Калитва',
		population: 43688,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '58.83333',
			lon: '50.85',
		},
		district: 'Приволжский',
		value: 'Белая Холуница',
		population: 11232,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '50.6',
			lon: '36.6',
		},
		district: 'Центральный',
		value: 'Белгород',
		population: 356426,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '54.1',
			lon: '54.13333',
		},
		district: 'Приволжский',
		value: 'Белебей',
		population: 60183,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '52.96667',
			lon: '43.41667',
		},
		district: 'Приволжский',
		value: 'Белинский',
		population: 8567,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '54.41667',
			lon: '86.3',
		},
		district: 'Сибирский',
		value: 'Белово',
		population: 76752,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '50.91667',
			lon: '128.48333',
		},
		district: 'Дальневосточный',
		value: 'Белогорск',
		population: 68220,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '45.05444',
			lon: '34.60222',
		},
		district: 'Южный',
		value: 'Белогорск',
		population: 16354,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '60.03333',
			lon: '37.78333',
		},
		district: 'Северо-Западный',
		value: 'Белозерск',
		population: 9614,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '52',
			lon: '84.98333',
		},
		district: 'Сибирский',
		value: 'Белокуриха',
		population: 15072,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '64.51667',
			lon: '34.76667',
		},
		district: 'Северо-Западный',
		value: 'Беломорск',
		population: 11217,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '55.46139',
			lon: '38.44222',
		},
		district: 'Центральный',
		value: 'Белоозёрский',
		population: 17842,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '53.96667',
			lon: '58.4',
		},
		district: 'Приволжский',
		value: 'Белорецк',
		population: 68804,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '44.76667',
			lon: '39.86667',
		},
		district: 'Южный',
		value: 'Белореченск',
		population: 53891,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '55.09167',
			lon: '36.66667',
		},
		district: 'Центральный',
		value: 'Белоусово',
		population: 8432,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '63.71667',
			lon: '66.66667',
		},
		district: 'Уральский',
		value: 'Белоярский',
		population: 20283,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '55.83333',
			lon: '32.93333',
		},
		district: 'Центральный',
		value: 'Белый',
		population: 3771,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '53.8',
			lon: '36.13333',
		},
		district: 'Центральный',
		value: 'Белёв',
		population: 13918,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '54.75',
			lon: '83.1',
		},
		district: 'Сибирский',
		value: 'Бердск',
		population: 98809,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '59.40806',
			lon: '56.80528',
		},
		district: 'Приволжский',
		value: 'Березники',
		population: 156350,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '55.66667',
			lon: '86.25',
		},
		district: 'Сибирский',
		value: 'Берёзовский',
		population: 47292,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '56.9',
			lon: '60.8',
		},
		district: 'Уральский',
		value: 'Берёзовский',
		population: 51583,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '43.18889',
			lon: '44.521691',
		},
		district: 'Северо-Кавказский',
		value: 'Беслан',
		population: 36724,
		subject: 'Северная Осетия — Алания',
	},
	{
		coords: {
			lat: '52.51667',
			lon: '85.16667',
		},
		district: 'Сибирский',
		value: 'Бийск',
		population: 203826,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '46.81667',
			lon: '134.25',
		},
		district: 'Дальневосточный',
		value: 'Бикин',
		population: 17156,
		subject: 'Хабаровский край',
	},
	{
		coords: {
			lat: '68.05',
			lon: '166.45',
		},
		district: 'Дальневосточный',
		value: 'Билибино',
		population: 5504,
		subject: 'Чукотский АО',
	},
	{
		coords: {
			lat: '48.78333',
			lon: '132.93333',
		},
		district: 'Дальневосточный',
		value: 'Биробиджан',
		population: 75419,
		subject: 'Еврейская АО',
	},
	{
		coords: {
			lat: '55.41667',
			lon: '55.53333',
		},
		district: 'Приволжский',
		value: 'Бирск',
		population: 41637,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '55.95',
			lon: '97.81667',
		},
		district: 'Сибирский',
		value: 'Бирюсинск',
		population: 8981,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '50.65',
			lon: '38.4',
		},
		district: 'Центральный',
		value: 'Бирюч',
		population: 7842,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '50.25778',
			lon: '127.53639',
		},
		district: 'Дальневосточный',
		value: 'Благовещенск',
		population: 214397,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '55.03333',
			lon: '55.98333',
		},
		district: 'Приволжский',
		value: 'Благовещенск',
		population: 34246,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '45.1029039',
			lon: '43.4251513',
		},
		district: 'Северо-Кавказский',
		value: 'Благодарный',
		population: 32736,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '51.09444',
			lon: '40.03222',
		},
		district: 'Центральный',
		value: 'Бобров',
		population: 19738,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '56.78333',
			lon: '62.05',
		},
		district: 'Уральский',
		value: 'Богданович',
		population: 31752,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '53.76667',
			lon: '38.13333',
		},
		district: 'Центральный',
		value: 'Богородицк',
		population: 31897,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '56.09972',
			lon: '43.50722',
		},
		district: 'Приволжский',
		value: 'Богородск',
		population: 25497,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '56.2',
			lon: '89.51667',
		},
		district: 'Сибирский',
		value: 'Боготол',
		population: 21029,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '49.93333',
			lon: '40.55',
		},
		district: 'Центральный',
		value: 'Богучар',
		population: 11811,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '57.85',
			lon: '114.2',
		},
		district: 'Сибирский',
		value: 'Бодайбо',
		population: 15331,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '59.47333',
			lon: '33.84806',
		},
		district: 'Северо-Западный',
		value: 'Бокситогорск',
		population: 16593,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '54.96667',
			lon: '49.03333',
		},
		district: 'Приволжский',
		value: 'Болгар',
		population: 8650,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '57.87083',
			lon: '34.07361',
		},
		district: 'Центральный',
		value: 'Бологое',
		population: 23499,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '55.66667',
			lon: '84.4',
		},
		district: 'Сибирский',
		value: 'Болотное',
		population: 16969,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '54.08333',
			lon: '37.81667',
		},
		district: 'Центральный',
		value: 'Болохово',
		population: 9619,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '53.45',
			lon: '36',
		},
		district: 'Центральный',
		value: 'Болхов',
		population: 11421,
		subject: 'Орловская область',
	},
	{
		coords: {
			lat: '43.11667',
			lon: '132.35',
		},
		district: 'Дальневосточный',
		value: 'Большой Камень',
		population: 39257,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '56.36028',
			lon: '44.05917',
		},
		district: 'Приволжский',
		value: 'Бор',
		population: 78079,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '50.38333',
			lon: '116.53333',
		},
		district: 'Сибирский',
		value: 'Борзя',
		population: 31376,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '51.36667',
			lon: '42.08333',
		},
		district: 'Центральный',
		value: 'Борисоглебск',
		population: 65585,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '58.38694',
			lon: '33.91139',
		},
		district: 'Северо-Западный',
		value: 'Боровичи',
		population: 54731,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '55.2',
			lon: '36.48333',
		},
		district: 'Центральный',
		value: 'Боровск',
		population: 12283,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '55.9',
			lon: '94.9',
		},
		district: 'Сибирский',
		value: 'Бородино',
		population: 17423,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '56.11667',
			lon: '101.6',
		},
		district: 'Сибирский',
		value: 'Братск',
		population: 246348,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '55.42278',
			lon: '38.25889',
		},
		district: 'Центральный',
		value: 'Бронницы',
		population: 21102,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '53.25',
			lon: '34.36667',
		},
		district: 'Центральный',
		value: 'Брянск',
		population: 415640,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '54.53333',
			lon: '52.78333',
		},
		district: 'Приволжский',
		value: 'Бугульма',
		population: 89144,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '53.61667',
			lon: '52.41667',
		},
		district: 'Приволжский',
		value: 'Бугуруслан',
		population: 52249,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '44.79',
			lon: '44.14',
		},
		district: 'Северо-Кавказский',
		value: 'Будённовск',
		population: 64628,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '52.76667',
			lon: '52.26667',
		},
		district: 'Приволжский',
		value: 'Бузулук',
		population: 82816,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '54.96667',
			lon: '48.28333',
		},
		district: 'Приволжский',
		value: 'Буинск',
		population: 20342,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '58.48333',
			lon: '41.51667',
		},
		district: 'Центральный',
		value: 'Буй',
		population: 25763,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '42.81667',
			lon: '47.11667',
		},
		district: 'Северо-Кавказский',
		value: 'Буйнакск',
		population: 65735,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '50.825',
			lon: '40.58889',
		},
		district: 'Центральный',
		value: 'Бутурлиновка',
		population: 27208,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '57.96667',
			lon: '33.25',
		},
		district: 'Северо-Западный',
		value: 'Валдай',
		population: 16099,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '50.18333',
			lon: '38.11667',
		},
		district: 'Центральный',
		value: 'Валуйки',
		population: 35322,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '55.6',
			lon: '31.2',
		},
		district: 'Центральный',
		value: 'Велиж',
		population: 7620,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '56.35',
			lon: '30.51667',
		},
		district: 'Северо-Западный',
		value: 'Великие Луки',
		population: 98778,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '58.525',
			lon: '31.275',
		},
		district: 'Северо-Западный',
		value: 'Великий Новгород',
		population: 218724,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '60.75889',
			lon: '46.30389',
		},
		district: 'Северо-Западный',
		value: 'Великий Устюг',
		population: 31664,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '61.06667',
			lon: '42.1',
		},
		district: 'Северо-Западный',
		value: 'Вельск',
		population: 23885,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '54.35',
			lon: '38.26667',
		},
		district: 'Центральный',
		value: 'Венёв',
		population: 15220,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '58.06667',
			lon: '54.65',
		},
		district: 'Приволжский',
		value: 'Верещагино',
		population: 22760,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '55.33333',
			lon: '36.18333',
		},
		district: 'Центральный',
		value: 'Верея',
		population: 5368,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '53.88333',
			lon: '59.21667',
		},
		district: 'Уральский',
		value: 'Верхнеуральск',
		population: 9459,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '57.38333',
			lon: '59.93333',
		},
		district: 'Уральский',
		value: 'Верхний Тагил',
		population: 11843,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '56.05',
			lon: '60.23333',
		},
		district: 'Уральский',
		value: 'Верхний Уфалей',
		population: 30504,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '56.96667',
			lon: '60.58333',
		},
		district: 'Уральский',
		value: 'Верхняя Пышма',
		population: 58707,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '58.05',
			lon: '60.55',
		},
		district: 'Уральский',
		value: 'Верхняя Салда',
		population: 46240,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '58.3574333',
			lon: '59.8224444',
		},
		district: 'Уральский',
		value: 'Верхняя Тура',
		population: 9468,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '58.86667',
			lon: '60.8',
		},
		district: 'Уральский',
		value: 'Верхотурье',
		population: 8815,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '67.55',
			lon: '133.38333',
		},
		district: 'Дальневосточный',
		value: 'Верхоянск',
		population: 1311,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '58.65',
			lon: '37.26667',
		},
		district: 'Центральный',
		value: 'Весьегонск',
		population: 7330,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '57.85556',
			lon: '45.78111',
		},
		district: 'Приволжский',
		value: 'Ветлуга',
		population: 8956,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '55.55',
			lon: '37.7',
		},
		district: 'Центральный',
		value: 'Видное',
		population: 56798,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '63.75',
			lon: '121.61667',
		},
		district: 'Дальневосточный',
		value: 'Вилюйск',
		population: 10233,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '52.93333',
			lon: '158.4',
		},
		district: 'Дальневосточный',
		value: 'Вилючинск',
		population: 22905,
		subject: 'Камчатский край',
	},
	{
		coords: {
			lat: '56.11667',
			lon: '101.16667',
		},
		district: 'Сибирский',
		value: 'Вихоревка',
		population: 22528,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '57.2',
			lon: '41.91667',
		},
		district: 'Центральный',
		value: 'Вичуга',
		population: 37609,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '43.11667',
			lon: '131.9',
		},
		district: 'Дальневосточный',
		value: 'Владивосток',
		population: 592069,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '43.01667',
			lon: '44.683315',
		},
		district: 'Северо-Кавказский',
		value: 'Владикавказ',
		population: 311635,
		subject: 'Северная Осетия — Алания',
	},
	{
		coords: {
			lat: '56.13333',
			lon: '40.41667',
		},
		district: 'Центральный',
		value: 'Владимир',
		population: 348256,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '48.71167',
			lon: '44.51389',
		},
		district: 'Южный',
		value: 'Волгоград',
		population: 1021244,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '47.54',
			lon: '42.20722',
		},
		district: 'Южный',
		value: 'Волгодонск',
		population: 170621,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '57.43333',
			lon: '41.16667',
		},
		district: 'Центральный',
		value: 'Волгореченск',
		population: 17108,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '55.86667',
			lon: '48.35',
		},
		district: 'Приволжский',
		value: 'Волжск',
		population: 55671,
		subject: 'Марий Эл',
	},
	{
		coords: {
			lat: '48.78333',
			lon: '44.76667',
		},
		district: 'Южный',
		value: 'Волжский',
		population: 314436,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '59.21667',
			lon: '39.9',
		},
		district: 'Северо-Западный',
		value: 'Вологда',
		population: 301642,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '56.23333',
			lon: '43.2',
		},
		district: 'Приволжский',
		value: 'Володарск',
		population: 9924,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '56.03333',
			lon: '35.95',
		},
		district: 'Центральный',
		value: 'Волоколамск',
		population: 23386,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '59.45',
			lon: '29.48333',
		},
		district: 'Северо-Западный',
		value: 'Волосово',
		population: 12162,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '59.91667',
			lon: '32.35',
		},
		district: 'Северо-Западный',
		value: 'Волхов',
		population: 47344,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '59.93333',
			lon: '60.05',
		},
		district: 'Уральский',
		value: 'Волчанск',
		population: 10008,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '52.05',
			lon: '47.38333',
		},
		district: 'Приволжский',
		value: 'Вольск',
		population: 66520,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '67.5',
			lon: '64.03333',
		},
		district: 'Северо-Западный',
		value: 'Воркута',
		population: 70551,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '51.67167',
			lon: '39.21056',
		},
		district: 'Центральный',
		value: 'Воронеж',
		population: 889680,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '55.98333',
			lon: '43.26667',
		},
		district: 'Приволжский',
		value: 'Ворсма',
		population: 11622,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '55.32333',
			lon: '38.68056',
		},
		district: 'Центральный',
		value: 'Воскресенск',
		population: 91301,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.05',
			lon: '54',
		},
		district: 'Приволжский',
		value: 'Воткинск',
		population: 100034,
		subject: 'Удмуртия',
	},
	{
		coords: {
			lat: '60.01667',
			lon: '30.65',
		},
		district: 'Северо-Западный',
		value: 'Всеволожск',
		population: 59689,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '63.86667',
			lon: '57.31667',
		},
		district: 'Северо-Западный',
		value: 'Вуктыл',
		population: 12357,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '60.70917',
			lon: '28.74417',
		},
		district: 'Северо-Западный',
		value: 'Выборг',
		population: 80013,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '55.31944',
			lon: '42.17306',
		},
		district: 'Приволжский',
		value: 'Выкса',
		population: 56196,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '56.31667',
			lon: '36.55',
		},
		district: 'Центральный',
		value: 'Высоковск',
		population: 10642,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '60.61667',
			lon: '28.56667',
		},
		district: 'Северо-Западный',
		value: 'Высоцк',
		population: 1244,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '61',
			lon: '36.45',
		},
		district: 'Северо-Западный',
		value: 'Вытегра',
		population: 10490,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '57.58333',
			lon: '34.56667',
		},
		district: 'Центральный',
		value: 'Вышний Волочёк',
		population: 52326,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '47.53333',
			lon: '134.75',
		},
		district: 'Дальневосточный',
		value: 'Вяземский',
		population: 14556,
		subject: 'Хабаровский край',
	},
	{
		coords: {
			lat: '56.24333',
			lon: '42.12917',
		},
		district: 'Центральный',
		value: 'Вязники',
		population: 41252,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '55.21028',
			lon: '34.285',
		},
		district: 'Центральный',
		value: 'Вязьма',
		population: 57103,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '56.218417',
			lon: '51.068583',
		},
		district: 'Приволжский',
		value: 'Вятские Поляны',
		population: 35159,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '56.56667',
			lon: '40.11667',
		},
		district: 'Центральный',
		value: 'Гаврилов Посад',
		population: 6434,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '57.3',
			lon: '39.85',
		},
		district: 'Центральный',
		value: 'Гаврилов-Ям',
		population: 17792,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '55.55',
			lon: '35',
		},
		district: 'Центральный',
		value: 'Гагарин',
		population: 31721,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '69.25528',
			lon: '33.31667',
		},
		district: 'Северо-Западный',
		value: 'Гаджиево',
		population: 11089,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '51.46667',
			lon: '58.45',
		},
		district: 'Приволжский',
		value: 'Гай',
		population: 38302,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '58.38333',
			lon: '42.35',
		},
		district: 'Центральный',
		value: 'Галич',
		population: 17346,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '59.56667',
			lon: '30.13333',
		},
		district: 'Северо-Западный',
		value: 'Гатчина',
		population: 92566,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '54.65',
			lon: '21.06667',
		},
		district: 'Северо-Западный',
		value: 'Гвардейск',
		population: 13888,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '58.73333',
			lon: '27.81667',
		},
		district: 'Северо-Западный',
		value: 'Гдов',
		population: 4379,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '44.56083',
			lon: '38.07667',
		},
		district: 'Южный',
		value: 'Геленджик',
		population: 54813,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '44.15',
			lon: '43.46667',
		},
		district: 'Северо-Кавказский',
		value: 'Георгиевск',
		population: 72126,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '58.14083',
			lon: '52.67417',
		},
		district: 'Приволжский',
		value: 'Глазов',
		population: 95835,
		subject: 'Удмуртия',
	},
	{
		coords: {
			lat: '55.61472',
			lon: '36.98722',
		},
		district: 'Центральный',
		value: 'Голицыно',
		population: 17447,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '56.13333',
			lon: '43.06667',
		},
		district: 'Приволжский',
		value: 'Горбатов',
		population: 2278,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '51.96',
			lon: '85.96',
		},
		district: 'Сибирский',
		value: 'Горно-Алтайск',
		population: 62861,
		subject: 'Алтай',
	},
	{
		coords: {
			lat: '58.38333',
			lon: '58.31667',
		},
		district: 'Приволжский',
		value: 'Горнозаводск',
		population: 12334,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '50.993028',
			lon: '81.467934',
		},
		district: 'Сибирский',
		value: 'Горняк',
		population: 13040,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '56.65028',
			lon: '43.47028',
		},
		district: 'Приволжский',
		value: 'Городец',
		population: 30699,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '53.26667',
			lon: '45.7',
		},
		district: 'Приволжский',
		value: 'Городище',
		population: 8102,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '46.08333',
			lon: '41.93333',
		},
		district: 'Южный',
		value: 'Городовиковск',
		population: 9565,
		subject: 'Калмыкия',
	},
	{
		coords: {
			lat: '56.20278',
			lon: '42.6925',
		},
		district: 'Центральный',
		value: 'Гороховец',
		population: 14015,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '44.63333',
			lon: '39.13333',
		},
		district: 'Южный',
		value: 'Горячий Ключ',
		population: 30093,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '50.48333',
			lon: '35.66667',
		},
		district: 'Центральный',
		value: 'Грайворон',
		population: 6234,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '58.56667',
			lon: '57.83333',
		},
		district: 'Приволжский',
		value: 'Гремячинск',
		population: 11005,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '43.31667',
			lon: '45.7',
		},
		district: 'Северо-Кавказский',
		value: 'Грозный',
		population: 271596,
		subject: 'Чечня',
	},
	{
		coords: {
			lat: '52.5',
			lon: '39.93333',
		},
		district: 'Центральный',
		value: 'Грязи',
		population: 46798,
		subject: 'Липецкая область',
	},
	{
		coords: {
			lat: '58.88333',
			lon: '40.25',
		},
		district: 'Северо-Западный',
		value: 'Грязовец',
		population: 15528,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '58.86667',
			lon: '57.58333',
		},
		district: 'Приволжский',
		value: 'Губаха',
		population: 27544,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '51.28333',
			lon: '37.55',
		},
		district: 'Центральный',
		value: 'Губкин',
		population: 88562,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '64.43333',
			lon: '76.5',
		},
		district: 'Уральский',
		value: 'Губкинский',
		population: 23340,
		subject: 'Ямало-Ненецкий АО',
	},
	{
		coords: {
			lat: '43.355298',
			lon: '46.099175',
		},
		district: 'Северо-Кавказский',
		value: 'Гудермес',
		population: 45643,
		subject: 'Чечня',
	},
	{
		coords: {
			lat: '48.05',
			lon: '39.93333',
		},
		district: 'Южный',
		value: 'Гуково',
		population: 67268,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '45.36028',
			lon: '40.69444',
		},
		district: 'Южный',
		value: 'Гулькевичи',
		population: 35225,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '54.76667',
			lon: '20.6',
		},
		district: 'Северо-Западный',
		value: 'Гурьевск',
		population: 12433,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '54.28333',
			lon: '85.93333',
		},
		district: 'Сибирский',
		value: 'Гурьевск',
		population: 24816,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '54.58333',
			lon: '22.2',
		},
		district: 'Северо-Западный',
		value: 'Гусев',
		population: 28260,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '51.28556',
			lon: '106.52917',
		},
		district: 'Сибирский',
		value: 'Гусиноозёрск',
		population: 23280,
		subject: 'Бурятия',
	},
	{
		coords: {
			lat: '55.61667',
			lon: '40.65',
		},
		district: 'Центральный',
		value: 'Гусь-Хрустальный',
		population: 60773,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '54.21667',
			lon: '55.03333',
		},
		district: 'Приволжский',
		value: 'Давлеканово',
		population: 24040,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '42.11667',
			lon: '48.2',
		},
		district: 'Северо-Кавказский',
		value: 'Дагестанские Огни',
		population: 30671,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '56.26667',
			lon: '62.91667',
		},
		district: 'Уральский',
		value: 'Далматово',
		population: 13913,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '44.56667',
			lon: '135.61667',
		},
		district: 'Дальневосточный',
		value: 'Дальнегорск',
		population: 37503,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '45.93333',
			lon: '133.73333',
		},
		district: 'Дальневосточный',
		value: 'Дальнереченск',
		population: 27601,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '58.18333',
			lon: '40.18333',
		},
		district: 'Центральный',
		value: 'Данилов',
		population: 15861,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '53.25',
			lon: '39.15',
		},
		district: 'Центральный',
		value: 'Данков',
		population: 21056,
		subject: 'Липецкая область',
	},
	{
		coords: {
			lat: '56.7',
			lon: '60.1',
		},
		district: 'Уральский',
		value: 'Дегтярск',
		population: 15521,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '55.86667',
			lon: '37.13333',
		},
		district: 'Центральный',
		value: 'Дедовск',
		population: 29280,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '55.26667',
			lon: '31.51667',
		},
		district: 'Центральный',
		value: 'Демидов',
		population: 7333,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '42.06917',
			lon: '48.29583',
		},
		district: 'Северо-Кавказский',
		value: 'Дербент',
		population: 119961,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '54.1530861',
			lon: '33.2902917',
		},
		district: 'Центральный',
		value: 'Десногорск',
		population: 29677,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '45.70861',
			lon: '34.39333',
		},
		district: 'Южный',
		value: 'Джанкой',
		population: 38622,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '56.23333',
			lon: '43.45',
		},
		district: 'Приволжский',
		value: 'Дзержинск',
		population: 240762,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '55.63333',
			lon: '37.85',
		},
		district: 'Центральный',
		value: 'Дзержинский',
		population: 47125,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '55.95',
			lon: '92.38333',
		},
		district: 'Сибирский',
		value: 'Дивногорск',
		population: 28271,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '43.15',
			lon: '44.15',
		},
		district: 'Северо-Кавказский',
		value: 'Дигора',
		population: 10856,
		subject: 'Северная Осетия — Алания',
	},
	{
		coords: {
			lat: '54.23333',
			lon: '49.58333',
		},
		district: 'Приволжский',
		value: 'Димитровград',
		population: 122549,
		subject: 'Ульяновская область',
	},
	{
		coords: {
			lat: '52.12889',
			lon: '35.07556',
		},
		district: 'Центральный',
		value: 'Дмитриев',
		population: 7721,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '56.34667',
			lon: '37.52167',
		},
		district: 'Центральный',
		value: 'Дмитров',
		population: 61454,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '52.505504',
			lon: '35.141478',
		},
		district: 'Центральный',
		value: 'Дмитровск',
		population: 5956,
		subject: 'Орловская область',
	},
	{
		coords: {
			lat: '57.83333',
			lon: '29.96667',
		},
		district: 'Северо-Западный',
		value: 'Дно',
		population: 9061,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '58.45',
			lon: '56.41667',
		},
		district: 'Приволжский',
		value: 'Добрянка',
		population: 35720,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '55.93333',
			lon: '37.5',
		},
		district: 'Центральный',
		value: 'Долгопрудный',
		population: 90976,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '47.31667',
			lon: '142.8',
		},
		district: 'Дальневосточный',
		value: 'Долинск',
		population: 12200,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '55.44389',
			lon: '37.75806',
		},
		district: 'Центральный',
		value: 'Домодедово',
		population: 96123,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '48.3369194',
			lon: '39.9448917',
		},
		district: 'Южный',
		value: 'Донецк',
		population: 50085,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '53.96667',
			lon: '38.31667',
		},
		district: 'Центральный',
		value: 'Донской',
		population: 64561,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '54.92',
			lon: '33.30778',
		},
		district: 'Центральный',
		value: 'Дорогобуж',
		population: 10720,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '55.74444',
			lon: '38.84944',
		},
		district: 'Центральный',
		value: 'Дрезна',
		population: 11815,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '56.75',
			lon: '37.15',
		},
		district: 'Центральный',
		value: 'Дубна',
		population: 70569,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '49.05',
			lon: '44.83333',
		},
		district: 'Южный',
		value: 'Дубовка',
		population: 14345,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '69.4',
			lon: '86.18333',
		},
		district: 'Сибирский',
		value: 'Дудинка',
		population: 22207,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '55.2',
			lon: '32.41667',
		},
		district: 'Центральный',
		value: 'Духовщина',
		population: 4370,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '55.48333',
			lon: '54.86667',
		},
		district: 'Приволжский',
		value: 'Дюртюли',
		population: 31272,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '53.6',
			lon: '34.33333',
		},
		district: 'Центральный',
		value: 'Дятьково',
		population: 29438,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '45.2',
			lon: '33.35833',
		},
		district: 'Южный',
		value: 'Евпатория',
		population: 105719,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '55.38333',
			lon: '39.03361',
		},
		district: 'Центральный',
		value: 'Егорьевск',
		population: 70133,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '46.71056',
			lon: '38.27778',
		},
		district: 'Южный',
		value: 'Ейск',
		population: 87771,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '56.83333',
			lon: '60.58333',
		},
		district: 'Уральский',
		value: 'Екатеринбург',
		population: 1377738,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '55.76667',
			lon: '52.03333',
		},
		district: 'Приволжский',
		value: 'Елабуга',
		population: 70750,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '52.61667',
			lon: '38.46667',
		},
		district: 'Центральный',
		value: 'Елец',
		population: 108404,
		subject: 'Липецкая область',
	},
	{
		coords: {
			lat: '53.18333',
			lon: '158.38333',
		},
		district: 'Дальневосточный',
		value: 'Елизово',
		population: 39548,
		subject: 'Камчатский край',
	},
	{
		coords: {
			lat: '54.56667',
			lon: '33.16667',
		},
		district: 'Центральный',
		value: 'Ельня',
		population: 10095,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '54.75',
			lon: '61.31667',
		},
		district: 'Уральский',
		value: 'Еманжелинск',
		population: 30218,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '62.58333',
			lon: '50.85',
		},
		district: 'Северо-Западный',
		value: 'Емва',
		population: 14574,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '58.46667',
			lon: '92.13333',
		},
		district: 'Сибирский',
		value: 'Енисейск',
		population: 18769,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '55.20556',
			lon: '36.57',
		},
		district: 'Центральный',
		value: 'Ермолино',
		population: 10409,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '51.35',
			lon: '48.28333',
		},
		district: 'Приволжский',
		value: 'Ершов',
		population: 21447,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '44.04306',
			lon: '42.86417',
		},
		district: 'Северо-Кавказский',
		value: 'Ессентуки',
		population: 100969,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '53.149167',
			lon: '38.082585',
		},
		district: 'Центральный',
		value: 'Ефремов',
		population: 42350,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '44.13333',
			lon: '43.03333',
		},
		district: 'Северо-Кавказский',
		value: 'Железноводск',
		population: 24496,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '56.58333',
			lon: '104.11667',
		},
		district: 'Сибирский',
		value: 'Железногорск-Илимский',
		population: 26134,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '56.25',
			lon: '93.53333',
		},
		district: 'Сибирский',
		value: 'Железногорск',
		population: 85559,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '52.339174',
			lon: '35.351582',
		},
		district: 'Центральный',
		value: 'Железногорск',
		population: 95057,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '51.83333',
			lon: '41.46667',
		},
		district: 'Центральный',
		value: 'Жердевка',
		population: 15211,
		subject: 'Тамбовская область',
	},
	{
		coords: {
			lat: '53.4',
			lon: '49.5',
		},
		district: 'Приволжский',
		value: 'Жигулёвск',
		population: 57565,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '53.75028',
			lon: '34.73611',
		},
		district: 'Центральный',
		value: 'Жиздра',
		population: 5585,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '50.98028',
			lon: '44.78083',
		},
		district: 'Южный',
		value: 'Жирновск',
		population: 16890,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '55.03333',
			lon: '36.75',
		},
		district: 'Центральный',
		value: 'Жуков',
		population: 12150,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '53.53333',
			lon: '33.73333',
		},
		district: 'Центральный',
		value: 'Жуковка',
		population: 18269,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '55.60111',
			lon: '38.11611',
		},
		district: 'Центральный',
		value: 'Жуковский',
		population: 102729,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '50.11667',
			lon: '129.43333',
		},
		district: 'Дальневосточный',
		value: 'Завитинск',
		population: 11481,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '56.5',
			lon: '66.55',
		},
		district: 'Уральский',
		value: 'Заводоуковск',
		population: 25657,
		subject: 'Тюменская область',
	},
	{
		coords: {
			lat: '57.48333',
			lon: '42.13333',
		},
		district: 'Центральный',
		value: 'Заволжск',
		population: 12045,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '56.6425',
			lon: '43.39278',
		},
		district: 'Приволжский',
		value: 'Заволжье',
		population: 40265,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '52.38333',
			lon: '38.91667',
		},
		district: 'Центральный',
		value: 'Задонск',
		population: 9695,
		subject: 'Липецкая область',
	},
	{
		coords: {
			lat: '55.3',
			lon: '52.01667',
		},
		district: 'Приволжский',
		value: 'Заинск',
		population: 41798,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '50.38333',
			lon: '103.28333',
		},
		district: 'Сибирский',
		value: 'Закаменск',
		population: 11249,
		subject: 'Бурятия',
	},
	{
		coords: {
			lat: '55.96667',
			lon: '94.7',
		},
		district: 'Сибирский',
		value: 'Заозёрный',
		population: 10683,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '69.4',
			lon: '32.45',
		},
		district: 'Северо-Западный',
		value: 'Заозёрск',
		population: 11206,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '56.26667',
			lon: '32.08333',
		},
		district: 'Центральный',
		value: 'Западная Двина',
		population: 9376,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '69.41667',
			lon: '30.8',
		},
		district: 'Северо-Западный',
		value: 'Заполярный',
		population: 15835,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '54.76528',
			lon: '38.88361',
		},
		district: 'Центральный',
		value: 'Зарайск',
		population: 24648,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '53.2',
			lon: '45.16667',
		},
		district: 'Приволжский',
		value: 'Заречный',
		population: 63579,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '56.81667',
			lon: '61.31667',
		},
		district: 'Уральский',
		value: 'Заречный',
		population: 26803,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '53.7',
			lon: '84.91667',
		},
		district: 'Сибирский',
		value: 'Заринск',
		population: 47035,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '55.96667',
			lon: '48.01667',
		},
		district: 'Приволжский',
		value: 'Звенигово',
		population: 11945,
		subject: 'Марий Эл',
	},
	{
		coords: {
			lat: '55.73333',
			lon: '36.85',
		},
		district: 'Центральный',
		value: 'Звенигород',
		population: 16395,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '48.01667',
			lon: '40.11667',
		},
		district: 'Южный',
		value: 'Зверево',
		population: 22416,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '56.1',
			lon: '94.58333',
		},
		district: 'Сибирский',
		value: 'Зеленогорск',
		population: 66018,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '54.95',
			lon: '20.48333',
		},
		district: 'Северо-Западный',
		value: 'Зеленоградск',
		population: 13015,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '55.85',
			lon: '48.51667',
		},
		district: 'Приволжский',
		value: 'Зеленодольск',
		population: 97651,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '44.4070115',
			lon: '43.8731235',
		},
		district: 'Северо-Кавказский',
		value: 'Зеленокумск',
		population: 35790,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '46.846525',
			lon: '40.3040333',
		},
		district: 'Южный',
		value: 'Зерноград',
		population: 26850,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '53.73333',
			lon: '127.25',
		},
		district: 'Дальневосточный',
		value: 'Зея',
		population: 25042,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '53.91667',
			lon: '102.05',
		},
		district: 'Сибирский',
		value: 'Зима',
		population: 32522,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '55.16667',
			lon: '59.66667',
		},
		district: 'Уральский',
		value: 'Златоуст',
		population: 174985,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '52.43333',
			lon: '31.73333',
		},
		district: 'Центральный',
		value: 'Злынка',
		population: 5507,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '51.16667',
			lon: '82.16667',
		},
		district: 'Сибирский',
		value: 'Змеиногорск',
		population: 10569,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '48.58333',
			lon: '45.75',
		},
		district: 'Южный',
		value: 'Знаменск',
		population: 29357,
		subject: 'Астраханская область',
	},
	{
		coords: {
			lat: '56.16667',
			lon: '34.58333',
		},
		district: 'Центральный',
		value: 'Зубцов',
		population: 6937,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '58.403333',
			lon: '51.130361',
		},
		district: 'Приволжский',
		value: 'Зуевка',
		population: 11198,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '59.375',
			lon: '28.20528',
		},
		district: 'Северо-Западный',
		value: 'Ивангород',
		population: 9797,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '57',
			lon: '41',
		},
		district: 'Центральный',
		value: 'Иваново',
		population: 409277,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '55.97',
			lon: '37.92',
		},
		district: 'Центральный',
		value: 'Ивантеевка',
		population: 58594,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '60.68333',
			lon: '60.43333',
		},
		district: 'Уральский',
		value: 'Ивдель',
		population: 17764,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '67.46667',
			lon: '86.56667',
		},
		district: 'Сибирский',
		value: 'Игарка',
		population: 6183,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '56.85306',
			lon: '53.21222',
		},
		district: 'Приволжский',
		value: 'Ижевск',
		population: 628117,
		subject: 'Удмуртия',
	},
	{
		coords: {
			lat: '42.56667',
			lon: '47.86667',
		},
		district: 'Северо-Кавказский',
		value: 'Избербаш',
		population: 56301,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '45.36667',
			lon: '41.71667',
		},
		district: 'Северо-Кавказский',
		value: 'Изобильный',
		population: 40546,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '56.23333',
			lon: '96.06667',
		},
		district: 'Сибирский',
		value: 'Иланский',
		population: 16108,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '53.85',
			lon: '46.35',
		},
		district: 'Приволжский',
		value: 'Инза',
		population: 18547,
		subject: 'Ульяновская область',
	},
	{
		coords: {
			lat: '55.763633',
			lon: '48.736553',
		},
		district: 'Приволжский',
		value: 'Иннополис',
		population: 96,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '53.86667',
			lon: '44.36667',
		},
		district: 'Приволжский',
		value: 'Инсар',
		population: 8687,
		subject: 'Мордовия',
	},
	{
		coords: {
			lat: '66.0398139',
			lon: '60.1315194',
		},
		district: 'Северо-Западный',
		value: 'Инта',
		population: 32021,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '45.71667',
			lon: '42.9',
		},
		district: 'Северо-Кавказский',
		value: 'Ипатово',
		population: 26055,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '57.66667',
			lon: '63.06667',
		},
		district: 'Уральский',
		value: 'Ирбит',
		population: 38352,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '52.28333',
			lon: '104.3',
		},
		district: 'Сибирский',
		value: 'Иркутск',
		population: 587225,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '54.90889',
			lon: '71.26056',
		},
		district: 'Сибирский',
		value: 'Исилькуль',
		population: 25905,
		subject: 'Омская область',
	},
	{
		coords: {
			lat: '54.63333',
			lon: '83.3',
		},
		district: 'Сибирский',
		value: 'Искитим',
		population: 60072,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '55.91667',
			lon: '36.86667',
		},
		district: 'Центральный',
		value: 'Истра',
		population: 35106,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '56.11667',
			lon: '69.5',
		},
		district: 'Уральский',
		value: 'Ишим',
		population: 69567,
		subject: 'Тюменская область',
	},
	{
		coords: {
			lat: '53.45444',
			lon: '56.04389',
		},
		district: 'Приволжский',
		value: 'Ишимбай',
		population: 66259,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '56.63278',
			lon: '47.89583',
		},
		district: 'Приволжский',
		value: 'Йошкар-Ола',
		population: 248688,
		subject: 'Марий Эл',
	},
	{
		coords: {
			lat: '59.5',
			lon: '40.33333',
		},
		district: 'Северо-Западный',
		value: 'Кадников',
		population: 4797,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '55.79083',
			lon: '49.11444',
		},
		district: 'Приволжский',
		value: 'Казань',
		population: 1216965,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '48.68333',
			lon: '43.53333',
		},
		district: 'Южный',
		value: 'Калач-на-Дону',
		population: 26892,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '50.42583',
			lon: '41.01556',
		},
		district: 'Центральный',
		value: 'Калач',
		population: 20046,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '55.05',
			lon: '74.58333',
		},
		district: 'Сибирский',
		value: 'Калачинск',
		population: 24000,
		subject: 'Омская область',
	},
	{
		coords: {
			lat: '54.71667',
			lon: '20.5',
		},
		district: 'Северо-Западный',
		value: 'Калининград',
		population: 431491,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '51.5',
			lon: '44.45',
		},
		district: 'Приволжский',
		value: 'Калининск',
		population: 16442,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '53.51667',
			lon: '87.26667',
		},
		district: 'Сибирский',
		value: 'Калтан',
		population: 21893,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '54.53333',
			lon: '36.26667',
		},
		district: 'Центральный',
		value: 'Калуга',
		population: 325185,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '57.23333',
			lon: '37.85',
		},
		district: 'Центральный',
		value: 'Калязин',
		population: 13870,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '56.26667',
			lon: '54.2',
		},
		district: 'Приволжский',
		value: 'Камбарка',
		population: 11028,
		subject: 'Удмуртия',
	},
	{
		coords: {
			lat: '53.18333',
			lon: '44.05',
		},
		district: 'Приволжский',
		value: 'Каменка',
		population: 39579,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '60.95081',
			lon: '29.130882',
		},
		district: 'Северо-Западный',
		value: 'Каменногорск',
		population: 6761,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '56.4',
			lon: '61.93333',
		},
		district: 'Уральский',
		value: 'Каменск-Уральский',
		population: 174710,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '48.31667',
			lon: '40.26667',
		},
		district: 'Южный',
		value: 'Каменск-Шахтинский',
		population: 95306,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '53.79194',
			lon: '81.34861',
		},
		district: 'Сибирский',
		value: 'Камень-на-Оби',
		population: 41787,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '56.34917',
			lon: '40.99778',
		},
		district: 'Центральный',
		value: 'Камешково',
		population: 13113,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '46.11556',
			lon: '48.07694',
		},
		district: 'Южный',
		value: 'Камызяк',
		population: 16291,
		subject: 'Астраханская область',
	},
	{
		coords: {
			lat: '50.08333',
			lon: '45.4',
		},
		district: 'Южный',
		value: 'Камышин',
		population: 119924,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '56.85',
			lon: '62.71667',
		},
		district: 'Уральский',
		value: 'Камышлов',
		population: 26875,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '55.50694',
			lon: '47.49139',
		},
		district: 'Приволжский',
		value: 'Канаш',
		population: 45608,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '67.15694',
			lon: '32.41167',
		},
		district: 'Северо-Западный',
		value: 'Кандалакша',
		population: 35659,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '56.2',
			lon: '95.7',
		},
		district: 'Сибирский',
		value: 'Канск',
		population: 94230,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '56.30889',
			lon: '38.70139',
		},
		district: 'Центральный',
		value: 'Карабаново',
		population: 14868,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '55.48333',
			lon: '60.2',
		},
		district: 'Уральский',
		value: 'Карабаш',
		population: 13151,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '43.306285',
			lon: '44.909763',
		},
		district: 'Северо-Кавказский',
		value: 'Карабулак',
		population: 31081,
		subject: 'Ингушетия',
	},
	{
		coords: {
			lat: '53.73333',
			lon: '78.03333',
		},
		district: 'Сибирский',
		value: 'Карасук',
		population: 28929,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '43.769713',
			lon: '41.911369',
		},
		district: 'Северо-Кавказский',
		value: 'Карачаевск',
		population: 23848,
		subject: 'Карачаево-Черкесия',
	},
	{
		coords: {
			lat: '53.11667',
			lon: '34.98333',
		},
		district: 'Центральный',
		value: 'Карачев',
		population: 19715,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '55.2',
			lon: '80.28333',
		},
		district: 'Сибирский',
		value: 'Каргат',
		population: 10620,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '61.5',
			lon: '38.93333',
		},
		district: 'Северо-Западный',
		value: 'Каргополь',
		population: 10148,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '59.76667',
			lon: '60',
		},
		district: 'Уральский',
		value: 'Карпинск',
		population: 29118,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '53.05',
			lon: '60.65',
		},
		district: 'Уральский',
		value: 'Карталы',
		population: 29136,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '54.95',
			lon: '41.39722',
		},
		district: 'Центральный',
		value: 'Касимов',
		population: 33494,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '55.88333',
			lon: '60.75',
		},
		district: 'Уральский',
		value: 'Касли',
		population: 16998,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '42.88333',
			lon: '47.63333',
		},
		district: 'Северо-Кавказский',
		value: 'Каспийск',
		population: 103914,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '54.75',
			lon: '58.2',
		},
		district: 'Уральский',
		value: 'Катав-Ивановск',
		population: 17640,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '56.3',
			lon: '62.56667',
		},
		district: 'Уральский',
		value: 'Катайск',
		population: 14017,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '58.7',
			lon: '59.48333',
		},
		district: 'Уральский',
		value: 'Качканар',
		population: 42563,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '57.35',
			lon: '37.61667',
		},
		district: 'Центральный',
		value: 'Кашин',
		population: 16174,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '54.83333',
			lon: '38.15',
		},
		district: 'Центральный',
		value: 'Кашира',
		population: 41880,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.56667',
			lon: '79.56667',
		},
		district: 'Сибирский',
		value: 'Кедровый',
		population: 2451,
		subject: 'Томская область',
	},
	{
		coords: {
			lat: '55.35417',
			lon: '86.08972',
		},
		district: 'Сибирский',
		value: 'Кемерово',
		population: 532884,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '64.95',
			lon: '34.6',
		},
		district: 'Северо-Западный',
		value: 'Кемь',
		population: 13061,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '45.33861',
			lon: '36.46806',
		},
		district: 'Южный',
		value: 'Керчь',
		population: 147033,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '59.05',
			lon: '57.65',
		},
		district: 'Приволжский',
		value: 'Кизел',
		population: 20277,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '43.2',
			lon: '46.86667',
		},
		district: 'Северо-Кавказский',
		value: 'Кизилюрт',
		population: 36187,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '43.85',
			lon: '46.71667',
		},
		district: 'Северо-Кавказский',
		value: 'Кизляр',
		population: 49169,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '53.96667',
			lon: '38.53333',
		},
		district: 'Центральный',
		value: 'Кимовск',
		population: 28493,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '56.86667',
			lon: '37.35',
		},
		district: 'Центральный',
		value: 'Кимры',
		population: 49623,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '59.36667',
			lon: '28.6',
		},
		district: 'Северо-Западный',
		value: 'Кингисепп',
		population: 48667,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '53.23333',
			lon: '50.61667',
		},
		district: 'Приволжский',
		value: 'Кинель',
		population: 34472,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '57.45',
			lon: '42.15',
		},
		district: 'Центральный',
		value: 'Кинешма',
		population: 88113,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '53.93333',
			lon: '37.93333',
		},
		district: 'Центральный',
		value: 'Киреевск',
		population: 25585,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '57.78333',
			lon: '108.1',
		},
		district: 'Сибирский',
		value: 'Киренск',
		population: 12652,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '56.15',
			lon: '38.86667',
		},
		district: 'Центральный',
		value: 'Киржач',
		population: 30044,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '59.86667',
			lon: '38.38333',
		},
		district: 'Северо-Западный',
		value: 'Кириллов',
		population: 7735,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '59.45',
			lon: '32.01667',
		},
		district: 'Северо-Западный',
		value: 'Кириши',
		population: 52826,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '54.08333',
			lon: '34.3',
		},
		district: 'Центральный',
		value: 'Киров',
		population: 31888,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '58.6',
			lon: '49.65',
		},
		district: 'Приволжский',
		value: 'Киров',
		population: 473668,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '57.43333',
			lon: '60.06667',
		},
		district: 'Уральский',
		value: 'Кировград',
		population: 21959,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '58.55',
			lon: '50.01667',
		},
		district: 'Приволжский',
		value: 'Кирово-Чепецк',
		population: 80920,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '59.86667',
			lon: '30.98333',
		},
		district: 'Северо-Западный',
		value: 'Кировск',
		population: 25633,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '67.61417',
			lon: '33.67167',
		},
		district: 'Северо-Западный',
		value: 'Кировск',
		population: 28659,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '59.337167',
			lon: '52.245472',
		},
		district: 'Приволжский',
		value: 'Кирс',
		population: 10420,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '52.65',
			lon: '42.73333',
		},
		district: 'Центральный',
		value: 'Кирсанов',
		population: 17240,
		subject: 'Тамбовская область',
	},
	{
		coords: {
			lat: '53.98333',
			lon: '86.7',
		},
		district: 'Сибирский',
		value: 'Киселёвск',
		population: 98382,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '43.90333',
			lon: '42.72444',
		},
		district: 'Северо-Кавказский',
		value: 'Кисловодск',
		population: 128502,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '56.33389',
			lon: '36.7125',
		},
		district: 'Центральный',
		value: 'Клин',
		population: 80584,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '52.75278',
			lon: '32.23611',
		},
		district: 'Центральный',
		value: 'Клинцы',
		population: 62510,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '55.81667',
			lon: '45.03333',
		},
		district: 'Приволжский',
		value: 'Княгинино',
		population: 6708,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '67.55944',
			lon: '30.46667',
		},
		district: 'Северо-Западный',
		value: 'Ковдор',
		population: 18836,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '56.36056',
			lon: '41.31972',
		},
		district: 'Центральный',
		value: 'Ковров',
		population: 145492,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '54.03333',
			lon: '43.91667',
		},
		district: 'Приволжский',
		value: 'Ковылкино',
		population: 21307,
		subject: 'Мордовия',
	},
	{
		coords: {
			lat: '62.26667',
			lon: '74.48333',
		},
		district: 'Уральский',
		value: 'Когалым',
		population: 58192,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '58.6',
			lon: '99.18333',
		},
		district: 'Сибирский',
		value: 'Кодинск',
		population: 14835,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '54.03333',
			lon: '35.78333',
		},
		district: 'Центральный',
		value: 'Козельск',
		population: 18203,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '55.83333',
			lon: '48.25',
		},
		district: 'Приволжский',
		value: 'Козловка',
		population: 10355,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '56.33333',
			lon: '46.56667',
		},
		district: 'Приволжский',
		value: 'Козьмодемьянск',
		population: 21262,
		subject: 'Марий Эл',
	},
	{
		coords: {
			lat: '68.88306',
			lon: '33.02194',
		},
		district: 'Северо-Западный',
		value: 'Кола',
		population: 10447,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '58.8252278',
			lon: '44.311444',
		},
		district: 'Центральный',
		value: 'Кологрив',
		population: 3314,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '55.09389',
			lon: '38.76806',
		},
		district: 'Центральный',
		value: 'Коломна',
		population: 144642,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '58.31667',
			lon: '82.91667',
		},
		district: 'Сибирский',
		value: 'Колпашево',
		population: 24126,
		subject: 'Томская область',
	},
	{
		coords: {
			lat: '56.3',
			lon: '39.38333',
		},
		district: 'Центральный',
		value: 'Кольчугино',
		population: 45804,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '59.625',
			lon: '30.4',
		},
		district: 'Северо-Западный',
		value: 'Коммунар',
		population: 20265,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '50.55',
			lon: '137',
		},
		district: 'Дальневосточный',
		value: 'Комсомольск-на-Амуре',
		population: 263906,
		subject: 'Хабаровский край',
	},
	{
		coords: {
			lat: '57.03333',
			lon: '40.38333',
		},
		district: 'Центральный',
		value: 'Комсомольск',
		population: 8693,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '56.7',
			lon: '36.76667',
		},
		district: 'Центральный',
		value: 'Конаково',
		population: 41303,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '62.2',
			lon: '34.26667',
		},
		district: 'Северо-Западный',
		value: 'Кондопога',
		population: 32978,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '54.8',
			lon: '35.93333',
		},
		district: 'Центральный',
		value: 'Кондрово',
		population: 16672,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '47.58333',
			lon: '41.1',
		},
		district: 'Южный',
		value: 'Константиновск',
		population: 17926,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '55.1',
			lon: '61.61667',
		},
		district: 'Уральский',
		value: 'Копейск',
		population: 137604,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '53.91667',
			lon: '40.01667',
		},
		district: 'Центральный',
		value: 'Кораблино',
		population: 12657,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '45.46667',
			lon: '39.45',
		},
		district: 'Южный',
		value: 'Кореновск',
		population: 41179,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '54.88333',
			lon: '61.4',
		},
		district: 'Уральский',
		value: 'Коркино',
		population: 38950,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '55.91667',
			lon: '37.81667',
		},
		district: 'Центральный',
		value: 'Королёв',
		population: 225858,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '50.81361',
			lon: '37.18139',
		},
		district: 'Центральный',
		value: 'Короча',
		population: 5877,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '46.63333',
			lon: '142.76667',
		},
		district: 'Дальневосточный',
		value: 'Корсаков',
		population: 33526,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '61.3',
			lon: '47.16667',
		},
		district: 'Северо-Западный',
		value: 'Коряжма',
		population: 39629,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '55.93333',
			lon: '39.63333',
		},
		district: 'Центральный',
		value: 'Костерёво',
		population: 9136,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '64.58333',
			lon: '30.6',
		},
		district: 'Северо-Западный',
		value: 'Костомукша',
		population: 28433,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '57.76667',
			lon: '40.93333',
		},
		district: 'Центральный',
		value: 'Кострома',
		population: 268617,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '55.6625',
			lon: '37.86722',
		},
		district: 'Центральный',
		value: 'Котельники',
		population: 32347,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '47.63333',
			lon: '43.13333',
		},
		district: 'Южный',
		value: 'Котельниково',
		population: 20441,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '58.3',
			lon: '48.33333',
		},
		district: 'Приволжский',
		value: 'Котельнич',
		population: 24979,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '61.25',
			lon: '46.65',
		},
		district: 'Северо-Западный',
		value: 'Котлас',
		population: 60562,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '50.31667',
			lon: '44.8',
		},
		district: 'Южный',
		value: 'Котово',
		population: 24104,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '52.58333',
			lon: '41.5',
		},
		district: 'Центральный',
		value: 'Котовск',
		population: 31851,
		subject: 'Тамбовская область',
	},
	{
		coords: {
			lat: '56.93333',
			lon: '41.08333',
		},
		district: 'Центральный',
		value: 'Кохма',
		population: 29408,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '60.96667',
			lon: '46.48333',
		},
		district: 'Северо-Западный',
		value: 'Красавино',
		population: 7003,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '56.12278',
			lon: '38.14611',
		},
		district: 'Центральный',
		value: 'Красноармейск',
		population: 26294,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '51.01667',
			lon: '45.7',
		},
		district: 'Приволжский',
		value: 'Красноармейск',
		population: 24362,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '60.4',
			lon: '57.06667',
		},
		district: 'Приволжский',
		value: 'Красновишерск',
		population: 17129,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '55.81667',
			lon: '37.33333',
		},
		district: 'Центральный',
		value: 'Красногорск',
		population: 116738,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '45.03333',
			lon: '38.98333',
		},
		district: 'Южный',
		value: 'Краснодар',
		population: 744933,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '56.43806',
			lon: '38.22944',
		},
		district: 'Центральный',
		value: 'Краснозаводск',
		population: 13432,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '54.95',
			lon: '22.5',
		},
		district: 'Северо-Западный',
		value: 'Краснознаменск',
		population: 3522,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '55.6',
			lon: '37.03333',
		},
		district: 'Центральный',
		value: 'Краснознаменск',
		population: 36057,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '50.1',
			lon: '118.03333',
		},
		district: 'Сибирский',
		value: 'Краснокаменск',
		population: 55668,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '58.08333',
			lon: '55.75',
		},
		district: 'Приволжский',
		value: 'Краснокамск',
		population: 52632,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '45.955306',
			lon: '33.795',
		},
		district: 'Южный',
		value: 'Красноперекопск',
		population: 26268,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '48.7',
			lon: '44.56667',
		},
		district: 'Южный',
		value: 'Краснослободск',
		population: 16007,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '54.43333',
			lon: '43.78333',
		},
		district: 'Приволжский',
		value: 'Краснослободск',
		population: 10151,
		subject: 'Мордовия',
	},
	{
		coords: {
			lat: '59.76667',
			lon: '60.2',
		},
		district: 'Уральский',
		value: 'Краснотурьинск',
		population: 59701,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '58.35',
			lon: '60.05',
		},
		district: 'Уральский',
		value: 'Красноуральск',
		population: 24973,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '56.61667',
			lon: '57.76667',
		},
		district: 'Уральский',
		value: 'Красноуфимск',
		population: 39765,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '56.012083',
			lon: '92.871295',
		},
		district: 'Сибирский',
		value: 'Красноярск',
		population: 973826,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '50.95',
			lon: '46.96667',
		},
		district: 'Приволжский',
		value: 'Красный Кут',
		population: 14420,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '47.88333',
			lon: '40.06667',
		},
		district: 'Южный',
		value: 'Красный Сулин',
		population: 40866,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '58.06329',
			lon: '37.122983',
		},
		district: 'Центральный',
		value: 'Красный Холм',
		population: 5608,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '54.88917',
			lon: '37.12333',
		},
		district: 'Центральный',
		value: 'Кремёнки',
		population: 11617,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '45.43333',
			lon: '40.56667',
		},
		district: 'Южный',
		value: 'Кропоткин',
		population: 80743,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '44.93333',
			lon: '38',
		},
		district: 'Южный',
		value: 'Крымск',
		population: 57370,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '56.15167',
			lon: '44.19556',
		},
		district: 'Приволжский',
		value: 'Кстово',
		population: 66641,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '55.57639',
			lon: '36.69472',
		},
		district: 'Центральный',
		value: 'Кубинка',
		population: 22918,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '51.48333',
			lon: '57.35',
		},
		district: 'Приволжский',
		value: 'Кувандык',
		population: 26176,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '57.03333',
			lon: '34.16667',
		},
		district: 'Центральный',
		value: 'Кувшиново',
		population: 10008,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '59.90694',
			lon: '30.50556',
		},
		district: 'Северо-Западный',
		value: 'Кудрово',
		population: 13501,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '59.01667',
			lon: '54.66667',
		},
		district: 'Приволжский',
		value: 'Кудымкар',
		population: 30711,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '53.11667',
			lon: '46.6',
		},
		district: 'Приволжский',
		value: 'Кузнецк',
		population: 88886,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '55.45028',
			lon: '78.3075',
		},
		district: 'Сибирский',
		value: 'Куйбышев',
		population: 47278,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '56.1825',
			lon: '50.90639',
		},
		district: 'Приволжский',
		value: 'Кукмор',
		population: 17700,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '55.41667',
			lon: '42.51667',
		},
		district: 'Приволжский',
		value: 'Кулебаки',
		population: 35762,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '52.76667',
			lon: '55.78333',
		},
		district: 'Приволжский',
		value: 'Кумертау',
		population: 62854,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '57.43333',
			lon: '56.93333',
		},
		district: 'Приволжский',
		value: 'Кунгур',
		population: 67857,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '54.36667',
			lon: '77.3',
		},
		district: 'Сибирский',
		value: 'Купино',
		population: 15448,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '55.44083',
			lon: '65.34111',
		},
		district: 'Уральский',
		value: 'Курган',
		population: 333640,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '44.88333',
			lon: '40.6',
		},
		district: 'Южный',
		value: 'Курганинск',
		population: 47974,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '45.25',
			lon: '147.883333',
		},
		district: 'Дальневосточный',
		value: 'Курильск',
		population: 2070,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '55.45',
			lon: '40.61667',
		},
		district: 'Центральный',
		value: 'Курлово',
		population: 6791,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '55.58333',
			lon: '38.91667',
		},
		district: 'Центральный',
		value: 'Куровское',
		population: 21821,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '51.71667',
			lon: '36.18333',
		},
		district: 'Центральный',
		value: 'Курск',
		population: 414595,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '54.9',
			lon: '64.43333',
		},
		district: 'Уральский',
		value: 'Куртамыш',
		population: 17098,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '43.20444',
			lon: '46.087812',
		},
		district: 'Северо-Кавказский',
		value: 'Курчалой',
		population: 25672,
		subject: 'Чечня',
	},
	{
		coords: {
			lat: '51.66667',
			lon: '35.65',
		},
		district: 'Центральный',
		value: 'Курчатов',
		population: 42691,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '55.33333',
			lon: '59.43333',
		},
		district: 'Уральский',
		value: 'Куса',
		population: 18792,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '58.28333',
			lon: '59.73333',
		},
		district: 'Уральский',
		value: 'Кушва',
		population: 33027,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '51.7',
			lon: '94.36667',
		},
		district: 'Сибирский',
		value: 'Кызыл',
		population: 109906,
		subject: 'Тыва',
	},
	{
		coords: {
			lat: '55.7',
			lon: '60.55',
		},
		district: 'Уральский',
		value: 'Кыштым',
		population: 38950,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '50.35',
			lon: '106.45',
		},
		district: 'Сибирский',
		value: 'Кяхта',
		population: 20013,
		subject: 'Бурятия',
	},
	{
		coords: {
			lat: '44.63333',
			lon: '40.73333',
		},
		district: 'Южный',
		value: 'Лабинск',
		population: 62822,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '66.65806',
			lon: '66.38389',
		},
		district: 'Уральский',
		value: 'Лабытнанги',
		population: 26948,
		subject: 'Ямало-Ненецкий АО',
	},
	{
		coords: {
			lat: '45.3925',
			lon: '47.355',
		},
		district: 'Южный',
		value: 'Лагань',
		population: 14323,
		subject: 'Калмыкия',
	},
	{
		coords: {
			lat: '54.56667',
			lon: '20.16667',
		},
		district: 'Северо-Западный',
		value: 'Ладушкин',
		population: 3788,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '55.4',
			lon: '49.55',
		},
		district: 'Приволжский',
		value: 'Лаишево',
		population: 7735,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '56.01694',
			lon: '39.94944',
		},
		district: 'Центральный',
		value: 'Лакинск',
		population: 15707,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '61.25',
			lon: '75.16667',
		},
		district: 'Уральский',
		value: 'Лангепас',
		population: 41675,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '61.51667',
			lon: '30.2',
		},
		district: 'Северо-Западный',
		value: 'Лахденпохья',
		population: 7818,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '53.0115528',
			lon: '39.1281167',
		},
		district: 'Центральный',
		value: 'Лебедянь',
		population: 20991,
		subject: 'Липецкая область',
	},
	{
		coords: {
			lat: '54.5988694',
			lon: '52.4422722',
		},
		district: 'Приволжский',
		value: 'Лениногорск',
		population: 64145,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '54.65',
			lon: '86.16667',
		},
		district: 'Сибирский',
		value: 'Ленинск-Кузнецкий',
		population: 101666,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '48.7',
			lon: '45.2',
		},
		district: 'Южный',
		value: 'Ленинск',
		population: 15527,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '60.71667',
			lon: '114.9',
		},
		district: 'Дальневосточный',
		value: 'Ленск',
		population: 24955,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '44.10528',
			lon: '42.97167',
		},
		district: 'Северо-Кавказский',
		value: 'Лермонтов',
		population: 22540,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '58.63333',
			lon: '59.78333',
		},
		district: 'Уральский',
		value: 'Лесной',
		population: 52464,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '45.46667',
			lon: '133.4',
		},
		district: 'Дальневосточный',
		value: 'Лесозаводск',
		population: 36975,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '58.23333',
			lon: '92.48333',
		},
		district: 'Сибирский',
		value: 'Лесосибирск',
		population: 61146,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '52.425306',
			lon: '37.608306',
		},
		district: 'Центральный',
		value: 'Ливны',
		population: 50430,
		subject: 'Орловская область',
	},
	{
		coords: {
			lat: '55.71528',
			lon: '38.95778',
		},
		district: 'Центральный',
		value: 'Ликино-Дулёво',
		population: 31331,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '52.61667',
			lon: '39.6',
		},
		district: 'Центральный',
		value: 'Липецк',
		population: 508124,
		subject: 'Липецкая область',
	},
	{
		coords: {
			lat: '53.95',
			lon: '37.7',
		},
		district: 'Центральный',
		value: 'Липки',
		population: 8741,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '50.98222',
			lon: '39.49944',
		},
		district: 'Центральный',
		value: 'Лиски',
		population: 55864,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '57.11667',
			lon: '35.46667',
		},
		district: 'Центральный',
		value: 'Лихославль',
		population: 12259,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '56.01194',
			lon: '37.47444',
		},
		district: 'Центральный',
		value: 'Лобня',
		population: 74350,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '60.73333',
			lon: '33.55',
		},
		district: 'Северо-Западный',
		value: 'Лодейное Поле',
		population: 21053,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '55.86667',
			lon: '38.2',
		},
		district: 'Центральный',
		value: 'Лосино-Петровский',
		population: 22550,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '58.73333',
			lon: '29.85',
		},
		district: 'Северо-Западный',
		value: 'Луга',
		population: 36409,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '60.61667',
			lon: '47.28333',
		},
		district: 'Приволжский',
		value: 'Луза',
		population: 11262,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '55.03833',
			lon: '44.49778',
		},
		district: 'Приволжский',
		value: 'Лукоянов',
		population: 14949,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '54.96667',
			lon: '39.025',
		},
		district: 'Центральный',
		value: 'Луховицы',
		population: 29849,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '56.01278',
			lon: '45.02528',
		},
		district: 'Приволжский',
		value: 'Лысково',
		population: 21882,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '58.1003806',
			lon: '57.8043278',
		},
		district: 'Приволжский',
		value: 'Лысьва',
		population: 67712,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '55.58361',
			lon: '37.90556',
		},
		district: 'Центральный',
		value: 'Лыткарино',
		population: 55147,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '51.66667',
			lon: '35.26667',
		},
		district: 'Центральный',
		value: 'Льгов',
		population: 21452,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '59.35',
			lon: '31.25',
		},
		district: 'Северо-Западный',
		value: 'Любань',
		population: 4188,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '55.68139',
			lon: '37.89389',
		},
		district: 'Центральный',
		value: 'Люберцы',
		population: 171978,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '58.35',
			lon: '40.7',
		},
		district: 'Центральный',
		value: 'Любим',
		population: 5553,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '53.86667',
			lon: '34.46667',
		},
		district: 'Центральный',
		value: 'Людиново',
		population: 40550,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '61.61667',
			lon: '72.16667',
		},
		district: 'Уральский',
		value: 'Лянтор',
		population: 38922,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '59.56667',
			lon: '150.8',
		},
		district: 'Дальневосточный',
		value: 'Магадан',
		population: 95925,
		subject: 'Магаданская область',
	},
	{
		coords: {
			lat: '43.16667',
			lon: '44.81667',
		},
		district: 'Северо-Кавказский',
		value: 'Магас',
		population: 2505,
		subject: 'Ингушетия',
	},
	{
		coords: {
			lat: '53.38333',
			lon: '59.03333',
		},
		district: 'Уральский',
		value: 'Магнитогорск',
		population: 408401,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '44.611',
			lon: '40.111',
		},
		district: 'Южный',
		value: 'Майкоп',
		population: 144055,
		subject: 'Адыгея',
	},
	{
		coords: {
			lat: '43.630508',
			lon: '44.067733',
		},
		district: 'Северо-Кавказский',
		value: 'Майский',
		population: 26755,
		subject: 'Кабардино-Балкария',
	},
	{
		coords: {
			lat: '48.61667',
			lon: '142.78333',
		},
		district: 'Дальневосточный',
		value: 'Макаров',
		population: 6788,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '57.88333',
			lon: '43.8',
		},
		district: 'Центральный',
		value: 'Макарьев',
		population: 7114,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '55.2',
			lon: '67.25',
		},
		district: 'Уральский',
		value: 'Макушино',
		population: 8337,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '58.85',
			lon: '32.21667',
		},
		district: 'Северо-Западный',
		value: 'Малая Вишера',
		population: 12461,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '43.508882',
			lon: '44.585563',
		},
		district: 'Северо-Кавказский',
		value: 'Малгобек',
		population: 31076,
		subject: 'Ингушетия',
	},
	{
		coords: {
			lat: '56.52278',
			lon: '50.68083',
		},
		district: 'Приволжский',
		value: 'Малмыж',
		population: 8265,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '52.4',
			lon: '36.5',
		},
		district: 'Центральный',
		value: 'Малоархангельск',
		population: 3872,
		subject: 'Орловская область',
	},
	{
		coords: {
			lat: '55',
			lon: '36.46667',
		},
		district: 'Центральный',
		value: 'Малоярославец',
		population: 30401,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '55.7',
			lon: '51.4',
		},
		district: 'Приволжский',
		value: 'Мамадыш',
		population: 14432,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '54.46667',
			lon: '19.93333',
		},
		district: 'Северо-Западный',
		value: 'Мамоново',
		population: 7757,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '58.33333',
			lon: '44.76667',
		},
		district: 'Центральный',
		value: 'Мантурово',
		population: 17479,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '56.21667',
			lon: '87.75',
		},
		district: 'Сибирский',
		value: 'Мариинск',
		population: 40522,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '56.11667',
			lon: '47.71667',
		},
		district: 'Приволжский',
		value: 'Мариинский Посад',
		population: 10186,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '51.7',
			lon: '46.75',
		},
		district: 'Приволжский',
		value: 'Маркс',
		population: 31535,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '42.98333',
			lon: '47.5',
		},
		district: 'Северо-Кавказский',
		value: 'Махачкала',
		population: 577990,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '53.06111',
			lon: '32.84833',
		},
		district: 'Центральный',
		value: 'Мглин',
		population: 7916,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '61.03306',
			lon: '76.10972',
		},
		district: 'Уральский',
		value: 'Мегион',
		population: 49471,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '62.91667',
			lon: '34.45',
		},
		district: 'Северо-Западный',
		value: 'Медвежьегорск',
		population: 15536,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '51.422085',
			lon: '57.595296',
		},
		district: 'Приволжский',
		value: 'Медногорск',
		population: 27253,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '54.96667',
			lon: '35.86667',
		},
		district: 'Центральный',
		value: 'Медынь',
		population: 8298,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '54.05',
			lon: '57.81667',
		},
		district: 'Приволжский',
		value: 'Межгорье',
		population: 17353,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '53.68333',
			lon: '88.05',
		},
		district: 'Сибирский',
		value: 'Междуреченск',
		population: 101995,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '65.85',
			lon: '44.23333',
		},
		district: 'Северо-Западный',
		value: 'Мезень',
		population: 3599,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '55.33333',
			lon: '41.63333',
		},
		district: 'Центральный',
		value: 'Меленки',
		population: 15208,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '52.95',
			lon: '55.93333',
		},
		district: 'Приволжский',
		value: 'Мелеуз',
		population: 61408,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '55.9',
			lon: '52.31667',
		},
		district: 'Приволжский',
		value: 'Менделеевск',
		population: 22075,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '55.71667',
			lon: '53.08333',
		},
		district: 'Приволжский',
		value: 'Мензелинск',
		population: 16474,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '54.31667',
			lon: '35.28333',
		},
		district: 'Центральный',
		value: 'Мещовск',
		population: 4101,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '55.05',
			lon: '60.1',
		},
		district: 'Уральский',
		value: 'Миасс',
		population: 151812,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '62.35',
			lon: '50.06667',
		},
		district: 'Северо-Западный',
		value: 'Микунь',
		population: 10732,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '48.91667',
			lon: '40.4',
		},
		district: 'Южный',
		value: 'Миллерово',
		population: 36493,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '44.20083',
			lon: '43.1125',
		},
		district: 'Северо-Кавказский',
		value: 'Минеральные Воды',
		population: 76715,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '53.7',
			lon: '91.68333',
		},
		district: 'Сибирский',
		value: 'Минусинск',
		population: 71171,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '55.06667',
			lon: '57.55',
		},
		district: 'Уральский',
		value: 'Миньяр',
		population: 10195,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '62.76028',
			lon: '40.33528',
		},
		district: 'Северо-Западный',
		value: 'Мирный',
		population: 30259,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '62.53333',
			lon: '113.95',
		},
		district: 'Дальневосточный',
		value: 'Мирный',
		population: 37179,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '54.23333',
			lon: '39.03333',
		},
		district: 'Центральный',
		value: 'Михайлов',
		population: 11783,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '50.06667',
			lon: '43.23333',
		},
		district: 'Южный',
		value: 'Михайловка',
		population: 59153,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '56.43333',
			lon: '59.11667',
		},
		district: 'Уральский',
		value: 'Михайловск',
		population: 9215,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '45.130012',
			lon: '42.027487',
		},
		district: 'Северо-Кавказский',
		value: 'Михайловск',
		population: 71018,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '52.89222',
			lon: '40.49278',
		},
		district: 'Центральный',
		value: 'Мичуринск',
		population: 98758,
		subject: 'Тамбовская область',
	},
	{
		coords: {
			lat: '53.73333',
			lon: '119.76667',
		},
		district: 'Сибирский',
		value: 'Могоча',
		population: 13228,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '55.5',
			lon: '36.03333',
		},
		district: 'Центральный',
		value: 'Можайск',
		population: 31388,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '56.45',
			lon: '52.21667',
		},
		district: 'Приволжский',
		value: 'Можга',
		population: 47959,
		subject: 'Удмуртия',
	},
	{
		coords: {
			lat: '43.74722',
			lon: '44.65694',
		},
		district: 'Северо-Кавказский',
		value: 'Моздок',
		population: 38748,
		subject: 'Северная Осетия — Алания',
	},
	{
		coords: {
			lat: '67.93944',
			lon: '32.91556',
		},
		district: 'Северо-Западный',
		value: 'Мончегорск',
		population: 45381,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '48.35',
			lon: '41.83333',
		},
		district: 'Южный',
		value: 'Морозовск',
		population: 27644,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '53.45',
			lon: '41.8',
		},
		district: 'Центральный',
		value: 'Моршанск',
		population: 41550,
		subject: 'Тамбовская область',
	},
	{
		coords: {
			lat: '54.48333',
			lon: '34.98333',
		},
		district: 'Центральный',
		value: 'Мосальск',
		population: 4285,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '55.75583',
			lon: '37.61778',
		},
		district: 'Центральный',
		value: 'Москва',
		population: 11514330,
		subject: 'Москва',
	},
	{
		coords: {
			lat: '63.79444',
			lon: '74.49722',
		},
		district: 'Уральский',
		value: 'Муравленко',
		population: 33401,
		subject: 'Ямало-Ненецкий АО',
	},
	{
		coords: {
			lat: '59.38333',
			lon: '48.96667',
		},
		district: 'Приволжский',
		value: 'Мураши',
		population: 6752,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '60.04583',
			lon: '30.44861',
		},
		district: 'Северо-Западный',
		value: 'Мурино',
		population: 19775,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '68.96667',
			lon: '33.08333',
		},
		district: 'Северо-Западный',
		value: 'Мурманск',
		population: 307664,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '55.5725',
			lon: '42.05139',
		},
		district: 'Центральный',
		value: 'Муром',
		population: 116078,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '53.27833',
			lon: '36.575',
		},
		district: 'Центральный',
		value: 'Мценск',
		population: 43216,
		subject: 'Орловская область',
	},
	{
		coords: {
			lat: '53.7',
			lon: '87.81667',
		},
		district: 'Сибирский',
		value: 'Мыски',
		population: 43029,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '55.91667',
			lon: '37.73333',
		},
		district: 'Центральный',
		value: 'Мытищи',
		population: 173341,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.78333',
			lon: '38.45',
		},
		district: 'Центральный',
		value: 'Мышкин',
		population: 5932,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '55.7',
			lon: '52.33333',
		},
		district: 'Приволжский',
		value: 'Набережные Челны',
		population: 513242,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '55.53333',
			lon: '42.2',
		},
		district: 'Приволжский',
		value: 'Навашино',
		population: 16413,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '57.46667',
			lon: '41.96667',
		},
		district: 'Центральный',
		value: 'Наволоки',
		population: 10207,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '65.53333',
			lon: '72.51667',
		},
		district: 'Уральский',
		value: 'Надым',
		population: 46550,
		subject: 'Ямало-Ненецкий АО',
	},
	{
		coords: {
			lat: '56.00639',
			lon: '90.39139',
		},
		district: 'Сибирский',
		value: 'Назарово',
		population: 52829,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '43.21667',
			lon: '44.76667',
		},
		district: 'Северо-Кавказский',
		value: 'Назрань',
		population: 93357,
		subject: 'Ингушетия',
	},
	{
		coords: {
			lat: '55.56667',
			lon: '71.35',
		},
		district: 'Сибирский',
		value: 'Называевск',
		population: 12119,
		subject: 'Омская область',
	},
	{
		coords: {
			lat: '43.485259',
			lon: '43.607072',
		},
		district: 'Северо-Кавказский',
		value: 'Нальчик',
		population: 240095,
		subject: 'Кабардино-Балкария',
	},
	{
		coords: {
			lat: '46.68333',
			lon: '47.85',
		},
		district: 'Южный',
		value: 'Нариманов',
		population: 11386,
		subject: 'Астраханская область',
	},
	{
		coords: {
			lat: '55.38333',
			lon: '36.73333',
		},
		district: 'Центральный',
		value: 'Наро-Фоминск',
		population: 64640,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '43.556734',
			lon: '43.862222',
		},
		district: 'Северо-Кавказский',
		value: 'Нарткала',
		population: 31679,
		subject: 'Кабардино-Балкария',
	},
	{
		coords: {
			lat: '67.63778',
			lon: '53.00667',
		},
		district: 'Северо-Западный',
		value: 'Нарьян-Мар',
		population: 21296,
		subject: 'Ненецкий АО',
	},
	{
		coords: {
			lat: '42.81667',
			lon: '132.88333',
		},
		district: 'Дальневосточный',
		value: 'Находка',
		population: 159695,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '56.01667',
			lon: '29.93333',
		},
		district: 'Северо-Западный',
		value: 'Невель',
		population: 16324,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '46.65',
			lon: '141.86667',
		},
		district: 'Дальневосточный',
		value: 'Невельск',
		population: 11682,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '44.63333',
			lon: '41.93333',
		},
		district: 'Северо-Кавказский',
		value: 'Невинномысск',
		population: 118351,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '57.48333',
			lon: '60.2',
		},
		district: 'Уральский',
		value: 'Невьянск',
		population: 25147,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '56.21667',
			lon: '32.78333',
		},
		district: 'Центральный',
		value: 'Нелидово',
		population: 22886,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '55.03333',
			lon: '22.03333',
		},
		district: 'Северо-Западный',
		value: 'Неман',
		population: 11794,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '57.45',
			lon: '40.58333',
		},
		district: 'Центральный',
		value: 'Нерехта',
		population: 22817,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '51.98333',
			lon: '116.58333',
		},
		district: 'Сибирский',
		value: 'Нерчинск',
		population: 14976,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '56.65833',
			lon: '124.725',
		},
		district: 'Дальневосточный',
		value: 'Нерюнгри',
		population: 61746,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '54.63333',
			lon: '22.56667',
		},
		district: 'Северо-Западный',
		value: 'Нестеров',
		population: 4584,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '52.8',
			lon: '51.16667',
		},
		district: 'Приволжский',
		value: 'Нефтегорск',
		population: 18732,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '56.08889',
			lon: '54.24639',
		},
		district: 'Приволжский',
		value: 'Нефтекамск',
		population: 121757,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '44.75056',
			lon: '44.97972',
		},
		district: 'Северо-Кавказский',
		value: 'Нефтекумск',
		population: 27700,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '61.1',
			lon: '72.6',
		},
		district: 'Уральский',
		value: 'Нефтеюганск',
		population: 123276,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '58.29444',
			lon: '43.87806',
		},
		district: 'Центральный',
		value: 'Нея',
		population: 9827,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '60.93389',
			lon: '76.58111',
		},
		district: 'Уральский',
		value: 'Нижневартовск',
		population: 251860,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '55.63333',
			lon: '51.81667',
		},
		district: 'Приволжский',
		value: 'Нижнекамск',
		population: 234108,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '54.9',
			lon: '99.01667',
		},
		district: 'Сибирский',
		value: 'Нижнеудинск',
		population: 37056,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '56.66667',
			lon: '59.3',
		},
		district: 'Уральский',
		value: 'Нижние Серги',
		population: 11217,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '53.53333',
			lon: '43.68333',
		},
		district: 'Приволжский',
		value: 'Нижний Ломов',
		population: 22678,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '56.32694',
			lon: '44.0075',
		},
		district: 'Приволжский',
		value: 'Нижний Новгород',
		population: 1250615,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '57.91667',
			lon: '59.96667',
		},
		district: 'Уральский',
		value: 'Нижний Тагил',
		population: 361883,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '58.08333',
			lon: '60.71667',
		},
		district: 'Уральский',
		value: 'Нижняя Салда',
		population: 17969,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '58.62083',
			lon: '59.84778',
		},
		district: 'Уральский',
		value: 'Нижняя Тура',
		population: 21596,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '53.15',
			lon: '140.73333',
		},
		district: 'Дальневосточный',
		value: 'Николаевск-на-Амуре',
		population: 22773,
		subject: 'Хабаровский край',
	},
	{
		coords: {
			lat: '50.02722',
			lon: '45.46306',
		},
		district: 'Южный',
		value: 'Николаевск',
		population: 15081,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '59.53333',
			lon: '45.45',
		},
		district: 'Северо-Западный',
		value: 'Никольск',
		population: 8515,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '53.71667',
			lon: '46.08333',
		},
		district: 'Приволжский',
		value: 'Никольск',
		population: 22471,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '59.7',
			lon: '30.78333',
		},
		district: 'Северо-Западный',
		value: 'Никольское',
		population: 19345,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '60.1',
			lon: '32.3',
		},
		district: 'Северо-Западный',
		value: 'Новая Ладога',
		population: 8890,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '59.05',
			lon: '60.6',
		},
		district: 'Уральский',
		value: 'Новая Ляля',
		population: 12400,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '45.49333',
			lon: '41.21694',
		},
		district: 'Северо-Кавказский',
		value: 'Новоалександровск',
		population: 26759,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '53.4',
			lon: '83.93333',
		},
		district: 'Сибирский',
		value: 'Новоалтайск',
		population: 73134,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '50.51667',
			lon: '42.66667',
		},
		district: 'Южный',
		value: 'Новоаннинский',
		population: 17911,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '51.31667',
			lon: '39.21667',
		},
		district: 'Центральный',
		value: 'Нововоронеж',
		population: 32635,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '64.41667',
			lon: '40.81667',
		},
		district: 'Северо-Западный',
		value: 'Новодвинск',
		population: 40612,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '52.53333',
			lon: '31.93333',
		},
		district: 'Центральный',
		value: 'Новозыбков',
		population: 40552,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '45.1',
			lon: '41.05',
		},
		district: 'Южный',
		value: 'Новокубанск',
		population: 34847,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '53.73333',
			lon: '87.08333',
		},
		district: 'Сибирский',
		value: 'Новокузнецк',
		population: 547885,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '53.1',
			lon: '49.91667',
		},
		district: 'Приволжский',
		value: 'Новокуйбышевск',
		population: 108449,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '54.03333',
			lon: '39.75',
		},
		district: 'Центральный',
		value: 'Новомичуринск',
		population: 19309,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '54.03333',
			lon: '38.26667',
		},
		district: 'Центральный',
		value: 'Новомосковск',
		population: 131227,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '43.96361',
			lon: '43.63944',
		},
		district: 'Северо-Кавказский',
		value: 'Новопавловск',
		population: 26556,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '57.03333',
			lon: '29.33333',
		},
		district: 'Северо-Западный',
		value: 'Новоржев',
		population: 3695,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '44.71667',
			lon: '37.76667',
		},
		district: 'Южный',
		value: 'Новороссийск',
		population: 241788,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '55.01667',
			lon: '82.91667',
		},
		district: 'Сибирский',
		value: 'Новосибирск',
		population: 1498921,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '52.96667',
			lon: '37.05',
		},
		district: 'Центральный',
		value: 'Новосиль',
		population: 3799,
		subject: 'Орловская область',
	},
	{
		coords: {
			lat: '56.33333',
			lon: '30.15',
		},
		district: 'Северо-Западный',
		value: 'Новосокольники',
		population: 8119,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '51.20667',
			lon: '58.32806',
		},
		district: 'Приволжский',
		value: 'Новотроицк',
		population: 97914,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '50.45',
			lon: '48.15',
		},
		district: 'Приволжский',
		value: 'Новоузенск',
		population: 17015,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '54.15',
			lon: '48.38333',
		},
		district: 'Приволжский',
		value: 'Новоульяновск',
		population: 16032,
		subject: 'Ульяновская область',
	},
	{
		coords: {
			lat: '57.25',
			lon: '60.08333',
		},
		district: 'Уральский',
		value: 'Новоуральск',
		population: 91813,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '51.1',
			lon: '41.61667',
		},
		district: 'Центральный',
		value: 'Новохопёрск',
		population: 6849,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '56.12194',
			lon: '47.4925',
		},
		district: 'Приволжский',
		value: 'Новочебоксарск',
		population: 124094,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '47.43583',
			lon: '40.09861',
		},
		district: 'Южный',
		value: 'Новочеркасск',
		population: 169039,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '47.76667',
			lon: '39.91667',
		},
		district: 'Южный',
		value: 'Новошахтинск',
		population: 111087,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '50.76667',
			lon: '37.86667',
		},
		district: 'Центральный',
		value: 'Новый Оскол',
		population: 19530,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '66.08472',
			lon: '76.67889',
		},
		district: 'Уральский',
		value: 'Новый Уренгой',
		population: 104144,
		subject: 'Ямало-Ненецкий АО',
	},
	{
		coords: {
			lat: '55.85',
			lon: '38.43333',
		},
		district: 'Центральный',
		value: 'Ногинск',
		population: 99762,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.55722',
			lon: '49.93417',
		},
		district: 'Приволжский',
		value: 'Нолинск',
		population: 9556,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '69.33333',
			lon: '88.21667',
		},
		district: 'Сибирский',
		value: 'Норильск',
		population: 175301,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '63.20167',
			lon: '75.45167',
		},
		district: 'Уральский',
		value: 'Ноябрьск',
		population: 110572,
		subject: 'Ямало-Ненецкий АО',
	},
	{
		coords: {
			lat: '54.43333',
			lon: '50.8',
		},
		district: 'Приволжский',
		value: 'Нурлат',
		population: 32600,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '57.93333',
			lon: '55.33333',
		},
		district: 'Приволжский',
		value: 'Нытва',
		population: 19041,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '63.28333',
			lon: '118.33333',
		},
		district: 'Дальневосточный',
		value: 'Нюрба',
		population: 10156,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '62.13333',
			lon: '65.38333',
		},
		district: 'Уральский',
		value: 'Нягань',
		population: 54903,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '56.05',
			lon: '59.6',
		},
		district: 'Уральский',
		value: 'Нязепетровск',
		population: 12452,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '61.66667',
			lon: '40.2',
		},
		district: 'Северо-Западный',
		value: 'Няндома',
		population: 22354,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '49',
			lon: '131.05',
		},
		district: 'Дальневосточный',
		value: 'Облучье',
		population: 9379,
		subject: 'Еврейская АО',
	},
	{
		coords: {
			lat: '55.1',
			lon: '36.61667',
		},
		district: 'Центральный',
		value: 'Обнинск',
		population: 104798,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '51.21111',
			lon: '36.27639',
		},
		district: 'Центральный',
		value: 'Обоянь',
		population: 13562,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '54.99167',
			lon: '82.7125',
		},
		district: 'Сибирский',
		value: 'Обь',
		population: 26137,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '55.67333',
			lon: '37.27333',
		},
		district: 'Центральный',
		value: 'Одинцово',
		population: 139021,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '54.41667',
			lon: '22.01667',
		},
		district: 'Северо-Западный',
		value: 'Озёрск',
		population: 4740,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '55.75',
			lon: '60.71667',
		},
		district: 'Уральский',
		value: 'Озёрск',
		population: 82268,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '54.857875',
			lon: '38.5438194',
		},
		district: 'Центральный',
		value: 'Озёры',
		population: 25788,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '53.16667',
			lon: '48.66667',
		},
		district: 'Приволжский',
		value: 'Октябрьск',
		population: 27244,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '54.48333',
			lon: '53.48333',
		},
		district: 'Приволжский',
		value: 'Октябрьский',
		population: 109379,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '58.38333',
			lon: '33.3',
		},
		district: 'Северо-Западный',
		value: 'Окуловка',
		population: 12464,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '68.15',
			lon: '33.28333',
		},
		district: 'Северо-Западный',
		value: 'Оленегорск',
		population: 23079,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '60.98333',
			lon: '32.96667',
		},
		district: 'Северо-Западный',
		value: 'Олонец',
		population: 9060,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '60.38333',
			lon: '120.43333',
		},
		district: 'Дальневосточный',
		value: 'Олёкминск',
		population: 9487,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '54.96667',
			lon: '73.38333',
		},
		district: 'Сибирский',
		value: 'Омск',
		population: 1154000,
		subject: 'Омская область',
	},
	{
		coords: {
			lat: '58.66667',
			lon: '52.18333',
		},
		district: 'Приволжский',
		value: 'Омутнинск',
		population: 23618,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '63.91667',
			lon: '38.08333',
		},
		district: 'Северо-Западный',
		value: 'Онега',
		population: 21359,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '56.71667',
			lon: '28.65',
		},
		district: 'Северо-Западный',
		value: 'Опочка',
		population: 11601,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '51.76667',
			lon: '55.1',
		},
		district: 'Приволжский',
		value: 'Оренбург',
		population: 570329,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '55.8',
			lon: '38.96667',
		},
		district: 'Центральный',
		value: 'Орехово-Зуево',
		population: 120620,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '58.53889',
			lon: '48.89861',
		},
		district: 'Приволжский',
		value: 'Орлов',
		population: 6959,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '51.2',
			lon: '58.61667',
		},
		district: 'Приволжский',
		value: 'Орск',
		population: 238006,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '52.96667',
			lon: '36.08333',
		},
		district: 'Центральный',
		value: 'Орёл',
		population: 317854,
		subject: 'Орловская область',
	},
	{
		coords: {
			lat: '57.28333',
			lon: '55.45',
		},
		district: 'Приволжский',
		value: 'Оса',
		population: 22420,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '53.61667',
			lon: '87.33333',
		},
		district: 'Сибирский',
		value: 'Осинники',
		population: 45997,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '57.15',
			lon: '33.1',
		},
		district: 'Центральный',
		value: 'Осташков',
		population: 18073,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '57.33333',
			lon: '28.35',
		},
		district: 'Северо-Западный',
		value: 'Остров',
		population: 21670,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '68.05306',
			lon: '39.51306',
		},
		district: 'Северо-Западный',
		value: 'Островной',
		population: 2177,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '50.86667',
			lon: '39.06667',
		},
		district: 'Центральный',
		value: 'Острогожск',
		population: 33842,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '59.78333',
			lon: '30.81667',
		},
		district: 'Северо-Западный',
		value: 'Отрадное',
		population: 23874,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '53.36667',
			lon: '51.35',
		},
		district: 'Приволжский',
		value: 'Отрадный',
		population: 47709,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '53.58333',
			lon: '142.93333',
		},
		district: 'Дальневосточный',
		value: 'Оха',
		population: 23007,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '57.71667',
			lon: '55.38333',
		},
		district: 'Приволжский',
		value: 'Оханск',
		population: 7597,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '57.88333',
			lon: '54.71667',
		},
		district: 'Приволжский',
		value: 'Очёр',
		population: 15003,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '55.96194',
			lon: '43.09',
		},
		district: 'Приволжский',
		value: 'Павлово',
		population: 60699,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '50.45',
			lon: '40.06667',
		},
		district: 'Центральный',
		value: 'Павловск',
		population: 25126,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '55.78333',
			lon: '38.65',
		},
		district: 'Центральный',
		value: 'Павловский Посад',
		population: 63771,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '50.05',
			lon: '46.88333',
		},
		district: 'Южный',
		value: 'Палласовка',
		population: 15984,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '43.13333',
			lon: '133.13333',
		},
		district: 'Дальневосточный',
		value: 'Партизанск',
		population: 38648,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '69.7',
			lon: '170.31667',
		},
		district: 'Дальневосточный',
		value: 'Певек',
		population: 4161,
		subject: 'Чукотский АО',
	},
	{
		coords: {
			lat: '53.2',
			lon: '45',
		},
		district: 'Приволжский',
		value: 'Пенза',
		population: 519592,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '54.86667',
			lon: '43.8',
		},
		district: 'Приволжский',
		value: 'Первомайск',
		population: 14567,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '56.91667',
			lon: '59.95',
		},
		district: 'Уральский',
		value: 'Первоуральск',
		population: 149800,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '55.6',
			lon: '44.55',
		},
		district: 'Приволжский',
		value: 'Перевоз',
		population: 9201,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '56.41667',
			lon: '38.18333',
		},
		district: 'Центральный',
		value: 'Пересвет',
		population: 14142,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '56.7381333',
			lon: '38.8561528',
		},
		district: 'Центральный',
		value: 'Переславль-Залесский',
		population: 41923,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '58.01389',
			lon: '56.24889',
		},
		district: 'Приволжский',
		value: 'Пермь',
		population: 1000679,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '58.6',
			lon: '35.81667',
		},
		district: 'Северо-Западный',
		value: 'Пестово',
		population: 15911,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '50.13333',
			lon: '45.21667',
		},
		district: 'Южный',
		value: 'Петров Вал',
		population: 13264,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '51.28333',
			lon: '108.83333',
		},
		district: 'Сибирский',
		value: 'Петровск-Забайкальский',
		population: 18555,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '52.31667',
			lon: '45.38333',
		},
		district: 'Приволжский',
		value: 'Петровск',
		population: 31158,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '61.79611',
			lon: '34.34917',
		},
		district: 'Северо-Западный',
		value: 'Петрозаводск',
		population: 263540,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '53.01667',
			lon: '158.65',
		},
		district: 'Дальневосточный',
		value: 'Петропавловск-Камчатский',
		population: 179526,
		subject: 'Камчатский край',
	},
	{
		coords: {
			lat: '55.06667',
			lon: '67.88333',
		},
		district: 'Уральский',
		value: 'Петухово',
		population: 11291,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '55.93333',
			lon: '39.46667',
		},
		district: 'Центральный',
		value: 'Петушки',
		population: 15167,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '65.11667',
			lon: '57.11667',
		},
		district: 'Северо-Западный',
		value: 'Печора',
		population: 43458,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '57.81667',
			lon: '27.6',
		},
		district: 'Северо-Западный',
		value: 'Печоры',
		population: 12308,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '59.51324',
			lon: '34.17589',
		},
		district: 'Северо-Западный',
		value: 'Пикалёво',
		population: 21567,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '54.95',
			lon: '20.21667',
		},
		district: 'Северо-Западный',
		value: 'Пионерский',
		population: 11017,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '61.56667',
			lon: '31.48333',
		},
		district: 'Северо-Западный',
		value: 'Питкяранта',
		population: 11484,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '53.7',
			lon: '37.3',
		},
		district: 'Центральный',
		value: 'Плавск',
		population: 16248,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '54.36667',
			lon: '60.81667',
		},
		district: 'Уральский',
		value: 'Пласт',
		population: 17344,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '57.45',
			lon: '41.5',
		},
		district: 'Центральный',
		value: 'Плёс',
		population: 2341,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '51.2',
			lon: '42.25',
		},
		district: 'Центральный',
		value: 'Поворино',
		population: 17692,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '55.42972',
			lon: '37.54444',
		},
		district: 'Центральный',
		value: 'Подольск',
		population: 187956,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '60.91667',
			lon: '34.16667',
		},
		district: 'Северо-Западный',
		value: 'Подпорожье',
		population: 18729,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '61.75',
			lon: '75.58333',
		},
		district: 'Уральский',
		value: 'Покачи',
		population: 17053,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '55.91778',
			lon: '39.175',
		},
		district: 'Центральный',
		value: 'Покров',
		population: 17762,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '61.48333',
			lon: '129.15',
		},
		district: 'Дальневосточный',
		value: 'Покровск',
		population: 9495,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '56.45',
			lon: '60.18333',
		},
		district: 'Уральский',
		value: 'Полевской',
		population: 64316,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '54.86667',
			lon: '21.1',
		},
		district: 'Северо-Западный',
		value: 'Полесск',
		population: 7580,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '54.6',
			lon: '86.28333',
		},
		district: 'Сибирский',
		value: 'Полысаево',
		population: 27624,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '67.36583',
			lon: '32.49806',
		},
		district: 'Северо-Западный',
		value: 'Полярные Зори',
		population: 15106,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '69.19833',
			lon: '33.45611',
		},
		district: 'Северо-Западный',
		value: 'Полярный',
		population: 17304,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '49.21667',
			lon: '143.1',
		},
		district: 'Дальневосточный',
		value: 'Поронайск',
		population: 16461,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '57.76667',
			lon: '29.55',
		},
		district: 'Северо-Западный',
		value: 'Порхов',
		population: 10608,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '53.65',
			lon: '52.13333',
		},
		district: 'Приволжский',
		value: 'Похвистнево',
		population: 28181,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '52.93333',
			lon: '33.45',
		},
		district: 'Центральный',
		value: 'Почеп',
		population: 17933,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '54.4',
			lon: '32.45',
		},
		district: 'Центральный',
		value: 'Починок',
		population: 8776,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '58.5',
			lon: '39.11667',
		},
		district: 'Центральный',
		value: 'Пошехонье',
		population: 6085,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '54.45',
			lon: '21.01667',
		},
		district: 'Северо-Западный',
		value: 'Правдинск',
		population: 4323,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '57.38333',
			lon: '41.28333',
		},
		district: 'Центральный',
		value: 'Приволжск',
		population: 16749,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '54.73333',
			lon: '20',
		},
		district: 'Северо-Западный',
		value: 'Приморск',
		population: 1956,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '60.36667',
			lon: '28.61667',
		},
		district: 'Северо-Западный',
		value: 'Приморск',
		population: 6122,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '46.05',
			lon: '38.18333',
		},
		district: 'Южный',
		value: 'Приморско-Ахтарск',
		population: 32253,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '61.03333',
			lon: '30.11667',
		},
		district: 'Северо-Западный',
		value: 'Приозерск',
		population: 18929,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '53.88333',
			lon: '86.71667',
		},
		district: 'Сибирский',
		value: 'Прокопьевск',
		population: 210150,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '46.70306',
			lon: '41.71917',
		},
		district: 'Южный',
		value: 'Пролетарск',
		population: 20267,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '54.87944',
			lon: '37.21389',
		},
		district: 'Центральный',
		value: 'Протвино',
		population: 37308,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '43.750055',
			lon: '44.033333',
		},
		district: 'Северо-Кавказский',
		value: 'Прохладный',
		population: 59595,
		subject: 'Кабардино-Балкария',
	},
	{
		coords: {
			lat: '57.81667',
			lon: '28.33333',
		},
		district: 'Северо-Западный',
		value: 'Псков',
		population: 203974,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '52.01667',
			lon: '48.8',
		},
		district: 'Приволжский',
		value: 'Пугачёв',
		population: 41705,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '61.8',
			lon: '36.53333',
		},
		district: 'Северо-Западный',
		value: 'Пудож',
		population: 9698,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '56.33333',
			lon: '29.36667',
		},
		district: 'Северо-Западный',
		value: 'Пустошка',
		population: 4619,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '56.98333',
			lon: '43.16667',
		},
		district: 'Центральный',
		value: 'Пучеж',
		population: 8583,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '56.01667',
			lon: '37.85',
		},
		district: 'Центральный',
		value: 'Пушкино',
		population: 102840,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '54.83333',
			lon: '37.61667',
		},
		district: 'Центральный',
		value: 'Пущино',
		population: 20263,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.06667',
			lon: '27.91667',
		},
		district: 'Северо-Западный',
		value: 'Пыталово',
		population: 5826,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '60.75',
			lon: '72.78333',
		},
		district: 'Уральский',
		value: 'Пыть-Ях',
		population: 41453,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '44.0499664',
			lon: '43.0600548',
		},
		district: 'Северо-Кавказский',
		value: 'Пятигорск',
		population: 142397,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '55.99778',
			lon: '40.32972',
		},
		district: 'Центральный',
		value: 'Радужный',
		population: 18212,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '62.13333',
			lon: '77.46667',
		},
		district: 'Уральский',
		value: 'Радужный',
		population: 43394,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '49.8',
			lon: '129.4',
		},
		district: 'Дальневосточный',
		value: 'Райчихинск',
		population: 20499,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '55.56667',
			lon: '38.21667',
		},
		district: 'Центральный',
		value: 'Раменское',
		population: 96355,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '52.66667',
			lon: '41.88333',
		},
		district: 'Центральный',
		value: 'Рассказово',
		population: 45484,
		subject: 'Тамбовская область',
	},
	{
		coords: {
			lat: '56.8',
			lon: '59.91667',
		},
		district: 'Уральский',
		value: 'Ревда',
		population: 61713,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '57.36667',
			lon: '61.4',
		},
		district: 'Уральский',
		value: 'Реж',
		population: 38709,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '55.76667',
			lon: '37.86667',
		},
		district: 'Центральный',
		value: 'Реутов',
		population: 87195,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '56.2655833',
			lon: '34.3275694',
		},
		district: 'Центральный',
		value: 'Ржев',
		population: 62026,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '57.1',
			lon: '41.73333',
		},
		district: 'Центральный',
		value: 'Родники',
		population: 26318,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '53.94917',
			lon: '32.85694',
		},
		district: 'Центральный',
		value: 'Рославль',
		population: 54898,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '50.2',
			lon: '39.58333',
		},
		district: 'Центральный',
		value: 'Россошь',
		population: 62865,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '47.24056',
			lon: '39.71056',
		},
		district: 'Южный',
		value: 'Ростов-на-Дону',
		population: 1091544,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '57.18333',
			lon: '39.41667',
		},
		district: 'Центральный',
		value: 'Ростов',
		population: 31791,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '55.66361',
			lon: '39.865',
		},
		district: 'Центральный',
		value: 'Рошаль',
		population: 21265,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '52.25',
			lon: '43.78333',
		},
		district: 'Приволжский',
		value: 'Ртищево',
		population: 41295,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '51.52722',
			lon: '81.218806',
		},
		district: 'Сибирский',
		value: 'Рубцовск',
		population: 146386,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '54.95',
			lon: '31.06667',
		},
		district: 'Центральный',
		value: 'Рудня',
		population: 10029,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '55.6997667',
			lon: '36.194417',
		},
		district: 'Центральный',
		value: 'Руза',
		population: 13495,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '54.06667',
			lon: '44.95',
		},
		district: 'Приволжский',
		value: 'Рузаевка',
		population: 47529,
		subject: 'Мордовия',
	},
	{
		coords: {
			lat: '58.05',
			lon: '38.83333',
		},
		district: 'Центральный',
		value: 'Рыбинск',
		population: 200771,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '54.73333',
			lon: '39.51667',
		},
		district: 'Центральный',
		value: 'Рыбное',
		population: 18378,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '51.56667',
			lon: '34.68333',
		},
		district: 'Центральный',
		value: 'Рыльск',
		population: 15667,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '53.71667',
			lon: '40.06667',
		},
		district: 'Центральный',
		value: 'Ряжск',
		population: 21676,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '54.61667',
			lon: '39.71667',
		},
		district: 'Центральный',
		value: 'Рязань',
		population: 525062,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '45.13361',
			lon: '33.57722',
		},
		district: 'Южный',
		value: 'Саки',
		population: 25146,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '53.36667',
			lon: '55.93333',
		},
		district: 'Приволжский',
		value: 'Салават',
		population: 156085,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '54.23333',
			lon: '85.8',
		},
		district: 'Сибирский',
		value: 'Салаир',
		population: 8263,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '66.53333',
			lon: '66.63333',
		},
		district: 'Уральский',
		value: 'Салехард',
		population: 42494,
		subject: 'Ямало-Ненецкий АО',
	},
	{
		coords: {
			lat: '46.48333',
			lon: '41.53333',
		},
		district: 'Южный',
		value: 'Сальск',
		population: 61312,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '53.18333',
			lon: '50.11667',
		},
		district: 'Приволжский',
		value: 'Самара',
		population: 1164900,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '59.95',
			lon: '30.31667',
		},
		district: 'Северо-Западный',
		value: 'Санкт-Петербург',
		population: 4848742,
		subject: 'Санкт-Петербург',
	},
	{
		coords: {
			lat: '54.18333',
			lon: '45.18333',
		},
		district: 'Приволжский',
		value: 'Саранск',
		population: 318841,
		subject: 'Мордовия',
	},
	{
		coords: {
			lat: '56.46667',
			lon: '53.8',
		},
		district: 'Приволжский',
		value: 'Сарапул',
		population: 101390,
		subject: 'Удмуртия',
	},
	{
		coords: {
			lat: '51.53333',
			lon: '46',
		},
		district: 'Приволжский',
		value: 'Саратов',
		population: 836900,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '54.93333',
			lon: '43.31667',
		},
		district: 'Приволжский',
		value: 'Саров',
		population: 92073,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '54.35',
			lon: '41.91667',
		},
		district: 'Центральный',
		value: 'Сасово',
		population: 28117,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '55.05',
			lon: '59.05',
		},
		district: 'Уральский',
		value: 'Сатка',
		population: 45465,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '55.1',
			lon: '33.25',
		},
		district: 'Центральный',
		value: 'Сафоново',
		population: 46116,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '53.1',
			lon: '91.4',
		},
		district: 'Сибирский',
		value: 'Саяногорск',
		population: 49889,
		subject: 'Хакасия',
	},
	{
		coords: {
			lat: '54.11667',
			lon: '102.16667',
		},
		district: 'Сибирский',
		value: 'Саянск',
		population: 40786,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '54.93333',
			lon: '20.15',
		},
		district: 'Северо-Западный',
		value: 'Светлогорск',
		population: 10775,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '45.33083',
			lon: '42.85111',
		},
		district: 'Северо-Кавказский',
		value: 'Светлоград',
		population: 38520,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '54.68333',
			lon: '20.13333',
		},
		district: 'Северо-Западный',
		value: 'Светлый',
		population: 21380,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '61.10833',
			lon: '28.85833',
		},
		district: 'Северо-Западный',
		value: 'Светогорск',
		population: 15973,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '53.08333',
			lon: '103.33333',
		},
		district: 'Сибирский',
		value: 'Свирск',
		population: 13649,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '51.38333',
			lon: '128.13333',
		},
		district: 'Дальневосточный',
		value: 'Свободный',
		population: 58594,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '56.28333',
			lon: '28.48333',
		},
		district: 'Северо-Западный',
		value: 'Себеж',
		population: 6375,
		subject: 'Псковская область',
	},
	{
		coords: {
			lat: '44.6',
			lon: '33.53333',
		},
		district: 'Южный',
		value: 'Севастополь',
		population: 393304,
		subject: 'Севастополь',
	},
	{
		coords: {
			lat: '50.68333',
			lon: '156.11667',
		},
		district: 'Дальневосточный',
		value: 'Северо-Курильск',
		population: 2381,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '55.63333',
			lon: '109.31667',
		},
		district: 'Сибирский',
		value: 'Северобайкальск',
		population: 23673,
		subject: 'Бурятия',
	},
	{
		coords: {
			lat: '64.56667',
			lon: '39.85',
		},
		district: 'Северо-Западный',
		value: 'Северодвинск',
		population: 192265,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '69.06917',
			lon: '33.41667',
		},
		district: 'Северо-Западный',
		value: 'Североморск',
		population: 50076,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '60.15',
			lon: '59.93333',
		},
		district: 'Уральский',
		value: 'Североуральск',
		population: 29279,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '56.6',
			lon: '84.85',
		},
		district: 'Сибирский',
		value: 'Северск',
		population: 108466,
		subject: 'Томская область',
	},
	{
		coords: {
			lat: '52.15',
			lon: '34.49389',
		},
		district: 'Центральный',
		value: 'Севск',
		population: 7282,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '63.73333',
			lon: '34.31667',
		},
		district: 'Северо-Западный',
		value: 'Сегежа',
		population: 29660,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '53.36944',
			lon: '34.1',
		},
		district: 'Центральный',
		value: 'Сельцо',
		population: 17933,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '47.51667',
			lon: '40.8',
		},
		district: 'Южный',
		value: 'Семикаракорск',
		population: 23884,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '51.68333',
			lon: '39.03333',
		},
		district: 'Центральный',
		value: 'Семилуки',
		population: 26025,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '56.78944',
			lon: '44.49056',
		},
		district: 'Приволжский',
		value: 'Семёнов',
		population: 24472,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '53.96667',
			lon: '48.8',
		},
		district: 'Приволжский',
		value: 'Сенгилей',
		population: 6959,
		subject: 'Ульяновская область',
	},
	{
		coords: {
			lat: '49.58333',
			lon: '42.73333',
		},
		district: 'Южный',
		value: 'Серафимович',
		population: 9368,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '55.53333',
			lon: '45.46667',
		},
		district: 'Приволжский',
		value: 'Сергач',
		population: 21387,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '56.3',
			lon: '38.13333',
		},
		district: 'Центральный',
		value: 'Сергиев Посад',
		population: 110878,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '52.46667',
			lon: '44.21667',
		},
		district: 'Приволжский',
		value: 'Сердобск',
		population: 35393,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '59.58333',
			lon: '60.56667',
		},
		district: 'Уральский',
		value: 'Серов',
		population: 99381,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '54.91667',
			lon: '37.4',
		},
		district: 'Центральный',
		value: 'Серпухов',
		population: 126496,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '60.15',
			lon: '30.2',
		},
		district: 'Северо-Западный',
		value: 'Сертолово',
		population: 47854,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '52.7',
			lon: '58.65',
		},
		district: 'Приволжский',
		value: 'Сибай',
		population: 62732,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '54.98333',
			lon: '57.68333',
		},
		district: 'Уральский',
		value: 'Сим',
		population: 14465,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '44.94806',
			lon: '34.10417',
		},
		district: 'Южный',
		value: 'Симферополь',
		population: 332317,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '53.98333',
			lon: '123.93333',
		},
		district: 'Дальневосточный',
		value: 'Сковородино',
		population: 9561,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '53.81667',
			lon: '39.55',
		},
		district: 'Центральный',
		value: 'Скопин',
		population: 30374,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '53',
			lon: '78.65',
		},
		district: 'Сибирский',
		value: 'Славгород',
		population: 30370,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '55.05',
			lon: '21.66667',
		},
		district: 'Северо-Западный',
		value: 'Славск',
		population: 4614,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '45.25861',
			lon: '38.12472',
		},
		district: 'Южный',
		value: 'Славянск-на-Кубани',
		population: 63768,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '59.11667',
			lon: '28.08333',
		},
		district: 'Северо-Западный',
		value: 'Сланцы',
		population: 33587,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '58.724167',
			lon: '50.161167',
		},
		district: 'Приволжский',
		value: 'Слободской',
		population: 33983,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '51.66667',
			lon: '103.7',
		},
		district: 'Сибирский',
		value: 'Слюдянка',
		population: 18542,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '54.78278',
			lon: '32.04528',
		},
		district: 'Центральный',
		value: 'Смоленск',
		population: 326863,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '56.08333',
			lon: '60.73333',
		},
		district: 'Уральский',
		value: 'Снежинск',
		population: 48896,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '69.19417',
			lon: '33.23306',
		},
		district: 'Северо-Западный',
		value: 'Снежногорск',
		population: 12698,
		subject: 'Мурманская область',
	},
	{
		coords: {
			lat: '55.99',
			lon: '40.01667',
		},
		district: 'Центральный',
		value: 'Собинка',
		population: 19482,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '55.08333',
			lon: '21.88333',
		},
		district: 'Северо-Западный',
		value: 'Советск',
		population: 41709,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '57.601306',
			lon: '48.938611',
		},
		district: 'Приволжский',
		value: 'Советск',
		population: 16592,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '53.93333',
			lon: '37.63333',
		},
		district: 'Центральный',
		value: 'Советск',
		population: 7537,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '48.9575',
			lon: '140.2811111',
		},
		district: 'Дальневосточный',
		value: 'Советская Гавань',
		population: 27712,
		subject: 'Хабаровский край',
	},
	{
		coords: {
			lat: '61.36667',
			lon: '63.56667',
		},
		district: 'Уральский',
		value: 'Советский',
		population: 26434,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '59.46667',
			lon: '40.11667',
		},
		district: 'Северо-Западный',
		value: 'Сокол',
		population: 38454,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '59.08333',
			lon: '42.28333',
		},
		district: 'Центральный',
		value: 'Солигалич',
		population: 6438,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '59.63333',
			lon: '56.76667',
		},
		district: 'Приволжский',
		value: 'Соликамск',
		population: 97239,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '56.18444',
			lon: '36.995',
		},
		district: 'Центральный',
		value: 'Солнечногорск',
		population: 52996,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '51.16667',
			lon: '54.98333',
		},
		district: 'Приволжский',
		value: 'Соль-Илецк',
		population: 26308,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '61.33333',
			lon: '46.91667',
		},
		district: 'Северо-Западный',
		value: 'Сольвычегодск',
		population: 2460,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '58.11667',
			lon: '30.31667',
		},
		district: 'Северо-Западный',
		value: 'Сольцы',
		population: 10317,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '52.43333',
			lon: '53.15',
		},
		district: 'Приволжский',
		value: 'Сорочинск',
		population: 30136,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '54',
			lon: '90.25',
		},
		district: 'Сибирский',
		value: 'Сорск',
		population: 12140,
		subject: 'Хакасия',
	},
	{
		coords: {
			lat: '61.7',
			lon: '30.66667',
		},
		district: 'Северо-Западный',
		value: 'Сортавала',
		population: 19215,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '54.05',
			lon: '35.96667',
		},
		district: 'Центральный',
		value: 'Сосенский',
		population: 12394,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '56.2543',
			lon: '51.2812',
		},
		district: 'Приволжский',
		value: 'Сосновка',
		population: 11960,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '56.13333',
			lon: '93.36667',
		},
		district: 'Сибирский',
		value: 'Сосновоборск',
		population: 33090,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '59.9',
			lon: '29.08611',
		},
		district: 'Северо-Западный',
		value: 'Сосновый Бор',
		population: 65901,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '63.58333',
			lon: '53.93333',
		},
		district: 'Северо-Западный',
		value: 'Сосногорск',
		population: 27809,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '43.58528',
			lon: '39.72028',
		},
		district: 'Южный',
		value: 'Сочи',
		population: 343285,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '54.41667',
			lon: '34.03333',
		},
		district: 'Центральный',
		value: 'Спас-Деменск',
		population: 4904,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '55.13333',
			lon: '40.16667',
		},
		district: 'Центральный',
		value: 'Спас-Клепики',
		population: 5917,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '44.6',
			lon: '132.81667',
		},
		district: 'Дальневосточный',
		value: 'Спасск-Дальний',
		population: 44166,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '54.4',
			lon: '40.38333',
		},
		district: 'Центральный',
		value: 'Спасск-Рязанский',
		population: 7745,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '53.93333',
			lon: '43.18333',
		},
		district: 'Приволжский',
		value: 'Спасск',
		population: 7442,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '67.458',
			lon: '153.706',
		},
		district: 'Дальневосточный',
		value: 'Среднеколымск',
		population: 3525,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '56.98333',
			lon: '60.46667',
		},
		district: 'Уральский',
		value: 'Среднеуральск',
		population: 20357,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '52.25',
			lon: '117.71667',
		},
		district: 'Сибирский',
		value: 'Сретенск',
		population: 6850,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '45.03333',
			lon: '41.96667',
		},
		district: 'Северо-Кавказский',
		value: 'Ставрополь',
		population: 398266,
		subject: 'Ставропольский край',
	},
	{
		coords: {
			lat: '55.8',
			lon: '38.18333',
		},
		district: 'Центральный',
		value: 'Старая Купавна',
		population: 21859,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.98333',
			lon: '31.35',
		},
		district: 'Северо-Западный',
		value: 'Старая Русса',
		population: 32235,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '56.51667',
			lon: '34.93333',
		},
		district: 'Центральный',
		value: 'Старица',
		population: 8610,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '52.58333',
			lon: '32.76667',
		},
		district: 'Центральный',
		value: 'Стародуб',
		population: 19010,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '45.02917',
			lon: '35.08861',
		},
		district: 'Южный',
		value: 'Старый Крым',
		population: 9277,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '51.29806',
			lon: '37.835',
		},
		district: 'Центральный',
		value: 'Старый Оскол',
		population: 221163,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '53.63333',
			lon: '55.95',
		},
		district: 'Приволжский',
		value: 'Стерлитамак',
		population: 273432,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '60.73333',
			lon: '77.58333',
		},
		district: 'Сибирский',
		value: 'Стрежевой',
		population: 42216,
		subject: 'Томская область',
	},
	{
		coords: {
			lat: '50.78333',
			lon: '36.48333',
		},
		district: 'Центральный',
		value: 'Строитель',
		population: 23933,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '56.37333',
			lon: '38.585',
		},
		district: 'Центральный',
		value: 'Струнино',
		population: 14372,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '54.88694',
			lon: '38.07722',
		},
		district: 'Центральный',
		value: 'Ступино',
		population: 66942,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '54.11667',
			lon: '36.5',
		},
		district: 'Центральный',
		value: 'Суворов',
		population: 18975,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '44.85139',
			lon: '34.9725',
		},
		district: 'Южный',
		value: 'Судак',
		population: 16492,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '51.190694',
			lon: '35.27083',
		},
		district: 'Центральный',
		value: 'Суджа',
		population: 6036,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '55.95',
			lon: '40.86667',
		},
		district: 'Центральный',
		value: 'Судогда',
		population: 11848,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '56.42111',
			lon: '40.44889',
		},
		district: 'Центральный',
		value: 'Суздаль',
		population: 10535,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '43.313475',
			lon: '45.051581',
		},
		district: 'Северо-Кавказский',
		value: 'Сунжа',
		population: 64493,
		subject: 'Ингушетия',
	},
	{
		coords: {
			lat: '62.08333',
			lon: '32.36667',
		},
		district: 'Северо-Западный',
		value: 'Суоярви',
		population: 9763,
		subject: 'Карелия',
	},
	{
		coords: {
			lat: '53.01667',
			lon: '32.4',
		},
		district: 'Центральный',
		value: 'Сураж',
		population: 11640,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '61.25',
			lon: '73.43333',
		},
		district: 'Уральский',
		value: 'Сургут',
		population: 306703,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '48.6',
			lon: '42.85',
		},
		district: 'Южный',
		value: 'Суровикино',
		population: 20527,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '53.08333',
			lon: '45.7',
		},
		district: 'Приволжский',
		value: 'Сурск',
		population: 7032,
		subject: 'Пензенская область',
	},
	{
		coords: {
			lat: '62.78333',
			lon: '148.15',
		},
		district: 'Дальневосточный',
		value: 'Сусуман',
		population: 5865,
		subject: 'Магаданская область',
	},
	{
		coords: {
			lat: '54.1',
			lon: '35.35',
		},
		district: 'Центральный',
		value: 'Сухиничи',
		population: 16295,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '56.90583',
			lon: '62.03417',
		},
		district: 'Уральский',
		value: 'Сухой Лог',
		population: 34836,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '53.16667',
			lon: '48.46667',
		},
		district: 'Приволжский',
		value: 'Сызрань',
		population: 178773,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '61.66667',
			lon: '50.81667',
		},
		district: 'Северо-Западный',
		value: 'Сыктывкар',
		population: 235006,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '56.5',
			lon: '60.81667',
		},
		district: 'Уральский',
		value: 'Сысерть',
		population: 20594,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '55.83333',
			lon: '34.28333',
		},
		district: 'Центральный',
		value: 'Сычёвка',
		population: 8111,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '60.13333',
			lon: '32.56667',
		},
		district: 'Северо-Западный',
		value: 'Сясьстрой',
		population: 13747,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '58.05',
			lon: '65.26667',
		},
		district: 'Уральский',
		value: 'Тавда',
		population: 35421,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '47.23917',
			lon: '38.88333',
		},
		district: 'Южный',
		value: 'Таганрог',
		population: 257692,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '56.06667',
			lon: '85.61667',
		},
		district: 'Сибирский',
		value: 'Тайга',
		population: 25330,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '55.93333',
			lon: '98.01667',
		},
		district: 'Сибирский',
		value: 'Тайшет',
		population: 35481,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '56.73333',
			lon: '37.53333',
		},
		district: 'Центральный',
		value: 'Талдом',
		population: 13819,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.0125',
			lon: '63.72917',
		},
		district: 'Уральский',
		value: 'Талица',
		population: 18339,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '52.71667',
			lon: '41.43333',
		},
		district: 'Центральный',
		value: 'Тамбов',
		population: 280457,
		subject: 'Тамбовская область',
	},
	{
		coords: {
			lat: '56.902383',
			lon: '74.37079',
		},
		district: 'Сибирский',
		value: 'Тара',
		population: 26664,
		subject: 'Омская область',
	},
	{
		coords: {
			lat: '64.91472',
			lon: '77.77278',
		},
		district: 'Уральский',
		value: 'Тарко-Сале',
		population: 20372,
		subject: 'Ямало-Ненецкий АО',
	},
	{
		coords: {
			lat: '54.71667',
			lon: '37.18333',
		},
		district: 'Центральный',
		value: 'Таруса',
		population: 9656,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '55.21667',
			lon: '75.96667',
		},
		district: 'Сибирский',
		value: 'Татарск',
		population: 26114,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '52.76667',
			lon: '87.86667',
		},
		district: 'Сибирский',
		value: 'Таштагол',
		population: 23114,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '56.8578278',
			lon: '35.9219278',
		},
		district: 'Центральный',
		value: 'Тверь',
		population: 403726,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '43.449437',
			lon: '41.74382',
		},
		district: 'Северо-Кавказский',
		value: 'Теберда',
		population: 9097,
		subject: 'Карачаево-Черкесия',
	},
	{
		coords: {
			lat: '56.85',
			lon: '40.55',
		},
		district: 'Центральный',
		value: 'Тейково',
		population: 34993,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '54.63333',
			lon: '43.21667',
		},
		district: 'Приволжский',
		value: 'Темников',
		population: 7247,
		subject: 'Мордовия',
	},
	{
		coords: {
			lat: '45.26667',
			lon: '37.38333',
		},
		district: 'Южный',
		value: 'Темрюк',
		population: 38014,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '43.48333',
			lon: '44.13889',
		},
		district: 'Северо-Кавказский',
		value: 'Терек',
		population: 19170,
		subject: 'Кабардино-Балкария',
	},
	{
		coords: {
			lat: '54.93333',
			lon: '48.83333',
		},
		district: 'Приволжский',
		value: 'Тетюши',
		population: 11596,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '45.61667',
			lon: '38.93333',
		},
		district: 'Южный',
		value: 'Тимашёвск',
		population: 53921,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '59.63333',
			lon: '33.5',
		},
		district: 'Северо-Западный',
		value: 'Тихвин',
		population: 58843,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '45.85',
			lon: '40.11667',
		},
		district: 'Южный',
		value: 'Тихорецк',
		population: 61825,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '58.19528',
			lon: '68.25806',
		},
		district: 'Уральский',
		value: 'Тобольск',
		population: 99698,
		subject: 'Тюменская область',
	},
	{
		coords: {
			lat: '55.23333',
			lon: '84.38333',
		},
		district: 'Сибирский',
		value: 'Тогучин',
		population: 21531,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '53.51667',
			lon: '49.41667',
		},
		district: 'Приволжский',
		value: 'Тольятти',
		population: 719484,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '47.76667',
			lon: '142.06667',
		},
		district: 'Дальневосточный',
		value: 'Томари',
		population: 4537,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '58.96667',
			lon: '126.26667',
		},
		district: 'Дальневосточный',
		value: 'Томмот',
		population: 8054,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '56.48861',
			lon: '84.95222',
		},
		district: 'Сибирский',
		value: 'Томск',
		population: 522940,
		subject: 'Томская область',
	},
	{
		coords: {
			lat: '55.28333',
			lon: '85.61667',
		},
		district: 'Сибирский',
		value: 'Топки',
		population: 28642,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '57.03333',
			lon: '34.96667',
		},
		district: 'Центральный',
		value: 'Торжок',
		population: 47702,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '56.5',
			lon: '31.63333',
		},
		district: 'Центральный',
		value: 'Торопец',
		population: 13018,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '59.55',
			lon: '30.9',
		},
		district: 'Северо-Западный',
		value: 'Тосно',
		population: 39127,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '59.98333',
			lon: '42.76667',
		},
		district: 'Северо-Западный',
		value: 'Тотьма',
		population: 9784,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '54.08333',
			lon: '61.56667',
		},
		district: 'Уральский',
		value: 'Троицк',
		population: 78637,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '52.58333',
			lon: '33.76667',
		},
		district: 'Центральный',
		value: 'Трубчевск',
		population: 15014,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '54.8',
			lon: '58.45',
		},
		district: 'Уральский',
		value: 'Трёхгорный',
		population: 33678,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '44.1',
			lon: '39.08333',
		},
		district: 'Южный',
		value: 'Туапсе',
		population: 63233,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '54.6',
			lon: '53.7',
		},
		district: 'Приволжский',
		value: 'Туймазы',
		population: 66849,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '54.2',
			lon: '37.61667',
		},
		district: 'Центральный',
		value: 'Тула',
		population: 501129,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '54.56667',
			lon: '100.56667',
		},
		district: 'Сибирский',
		value: 'Тулун',
		population: 44603,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '52.1446',
			lon: '93.9181',
		},
		district: 'Сибирский',
		value: 'Туран',
		population: 4988,
		subject: 'Тыва',
	},
	{
		coords: {
			lat: '58.03333',
			lon: '63.7',
		},
		district: 'Уральский',
		value: 'Туринск',
		population: 17990,
		subject: 'Свердловская область',
	},
	{
		coords: {
			lat: '57.88333',
			lon: '39.53333',
		},
		district: 'Центральный',
		value: 'Тутаев',
		population: 41001,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '55.15',
			lon: '124.71667',
		},
		district: 'Дальневосточный',
		value: 'Тында',
		population: 35574,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '43.3892664',
			lon: '42.9189065',
		},
		district: 'Северо-Кавказский',
		value: 'Тырныауз',
		population: 21000,
		subject: 'Кабардино-Балкария',
	},
	{
		coords: {
			lat: '55.86667',
			lon: '72.2',
		},
		district: 'Сибирский',
		value: 'Тюкалинск',
		population: 12050,
		subject: 'Омская область',
	},
	{
		coords: {
			lat: '57.15',
			lon: '65.53333',
		},
		district: 'Уральский',
		value: 'Тюмень',
		population: 581758,
		subject: 'Тюменская область',
	},
	{
		coords: {
			lat: '51.98333',
			lon: '42.26667',
		},
		district: 'Центральный',
		value: 'Уварово',
		population: 26829,
		subject: 'Тамбовская область',
	},
	{
		coords: {
			lat: '49.06667',
			lon: '142.03333',
		},
		district: 'Дальневосточный',
		value: 'Углегорск',
		population: 11880,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '57.53333',
			lon: '38.33333',
		},
		district: 'Центральный',
		value: 'Углич',
		population: 34505,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '66.4',
			lon: '112.3',
		},
		district: 'Дальневосточный',
		value: 'Удачный',
		population: 12611,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '57.88333',
			lon: '35.01667',
		},
		district: 'Центральный',
		value: 'Удомля',
		population: 31048,
		subject: 'Тверская область',
	},
	{
		coords: {
			lat: '55.31667',
			lon: '89.81667',
		},
		district: 'Сибирский',
		value: 'Ужур',
		population: 16079,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '53.9791417',
			lon: '38.1600833',
		},
		district: 'Центральный',
		value: 'Узловая',
		population: 55282,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '51.83333',
			lon: '107.61667',
		},
		district: 'Сибирский',
		value: 'Улан-Удэ',
		population: 431922,
		subject: 'Бурятия',
	},
	{
		coords: {
			lat: '54.31667',
			lon: '48.36667',
		},
		district: 'Приволжский',
		value: 'Ульяновск',
		population: 613793,
		subject: 'Ульяновская область',
	},
	{
		coords: {
			lat: '52.85',
			lon: '32.68333',
		},
		district: 'Центральный',
		value: 'Унеча',
		population: 26196,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '60.13333',
			lon: '64.78333',
		},
		district: 'Уральский',
		value: 'Урай',
		population: 39435,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '57.46667',
			lon: '45.78333',
		},
		district: 'Приволжский',
		value: 'Урень',
		population: 12311,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '57.11667',
			lon: '50',
		},
		district: 'Приволжский',
		value: 'Уржум',
		population: 10213,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '43.129123',
			lon: '45.54167',
		},
		district: 'Северо-Кавказский',
		value: 'Урус-Мартан',
		population: 49071,
		subject: 'Чечня',
	},
	{
		coords: {
			lat: '50.8',
			lon: '42.01667',
		},
		district: 'Южный',
		value: 'Урюпинск',
		population: 41594,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '65.995028',
			lon: '57.557139',
		},
		district: 'Северо-Западный',
		value: 'Усинск',
		population: 41100,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '52.05',
			lon: '39.73333',
		},
		district: 'Центральный',
		value: 'Усмань',
		population: 18752,
		subject: 'Липецкая область',
	},
	{
		coords: {
			lat: '52.75',
			lon: '103.65',
		},
		district: 'Сибирский',
		value: 'Усолье-Сибирское',
		population: 83364,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '59.41667',
			lon: '56.68333',
		},
		district: 'Приволжский',
		value: 'Усолье',
		population: 5694,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '43.8',
			lon: '131.95',
		},
		district: 'Дальневосточный',
		value: 'Уссурийск',
		population: 157946,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '44.08611',
			lon: '41.97194',
		},
		district: 'Северо-Кавказский',
		value: 'Усть-Джегута',
		population: 30602,
		subject: 'Карачаево-Черкесия',
	},
	{
		coords: {
			lat: '58',
			lon: '102.66667',
		},
		district: 'Сибирский',
		value: 'Усть-Илимск',
		population: 86591,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '54.93333',
			lon: '58.16667',
		},
		district: 'Уральский',
		value: 'Усть-Катав',
		population: 23586,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '56.8',
			lon: '105.83333',
		},
		district: 'Сибирский',
		value: 'Усть-Кут',
		population: 45061,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '45.21528',
			lon: '39.68944',
		},
		district: 'Южный',
		value: 'Усть-Лабинск',
		population: 43268,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '58.83333',
			lon: '36.43333',
		},
		district: 'Северо-Западный',
		value: 'Устюжна',
		population: 9478,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '54.73333',
			lon: '55.96667',
		},
		district: 'Приволжский',
		value: 'Уфа',
		population: 1062300,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '63.56667',
			lon: '53.7',
		},
		district: 'Северо-Западный',
		value: 'Ухта',
		population: 99642,
		subject: 'Коми',
	},
	{
		coords: {
			lat: '54.31667',
			lon: '59.38333',
		},
		district: 'Приволжский',
		value: 'Учалы',
		population: 37771,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '55.81667',
			lon: '94.31667',
		},
		district: 'Сибирский',
		value: 'Уяр',
		population: 12666,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '52.08944',
			lon: '35.85889',
		},
		district: 'Центральный',
		value: 'Фатеж',
		population: 5404,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '45.04889',
			lon: '35.37917',
		},
		district: 'Южный',
		value: 'Феодосия',
		population: 69038,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '53.45',
			lon: '34.41667',
		},
		district: 'Центральный',
		value: 'Фокино',
		population: 13874,
		subject: 'Брянская область',
	},
	{
		coords: {
			lat: '42.96667',
			lon: '132.4',
		},
		district: 'Дальневосточный',
		value: 'Фокино',
		population: 23683,
		subject: 'Приморский край',
	},
	{
		coords: {
			lat: '49.76667',
			lon: '43.65',
		},
		district: 'Южный',
		value: 'Фролово',
		population: 39489,
		subject: 'Волгоградская область',
	},
	{
		coords: {
			lat: '55.95',
			lon: '38.05',
		},
		district: 'Центральный',
		value: 'Фрязино',
		population: 55449,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.25',
			lon: '41.1',
		},
		district: 'Центральный',
		value: 'Фурманов',
		population: 36149,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '48.48333',
			lon: '135.06667',
		},
		district: 'Дальневосточный',
		value: 'Хабаровск',
		population: 577668,
		subject: 'Хабаровский край',
	},
	{
		coords: {
			lat: '44.42389',
			lon: '39.53722',
		},
		district: 'Южный',
		value: 'Хадыженск',
		population: 21580,
		subject: 'Краснодарский край',
	},
	{
		coords: {
			lat: '61',
			lon: '69',
		},
		district: 'Уральский',
		value: 'Ханты-Мансийск',
		population: 79410,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '47.4',
			lon: '47.25',
		},
		district: 'Южный',
		value: 'Харабали',
		population: 18209,
		subject: 'Астраханская область',
	},
	{
		coords: {
			lat: '59.95',
			lon: '40.2',
		},
		district: 'Северо-Западный',
		value: 'Харовск',
		population: 10078,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '43.25',
			lon: '46.58333',
		},
		district: 'Северо-Кавказский',
		value: 'Хасавюрт',
		population: 133929,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '52.48333',
			lon: '48.1',
		},
		district: 'Приволжский',
		value: 'Хвалынск',
		population: 13199,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '51.35',
			lon: '110.45',
		},
		district: 'Сибирский',
		value: 'Хилок',
		population: 11530,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '55.88917',
			lon: '37.445',
		},
		district: 'Центральный',
		value: 'Химки',
		population: 207125,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '57.15',
			lon: '31.18333',
		},
		district: 'Северо-Западный',
		value: 'Холм',
		population: 3829,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '47.04028',
			lon: '142.04306',
		},
		district: 'Дальневосточный',
		value: 'Холмск',
		population: 30936,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '56.25',
			lon: '37.98333',
		},
		district: 'Центральный',
		value: 'Хотьково',
		population: 21612,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '55.86667',
			lon: '47.48333',
		},
		district: 'Приволжский',
		value: 'Цивильск',
		population: 13478,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '47.64667',
			lon: '42.09472',
		},
		district: 'Южный',
		value: 'Цимлянск',
		population: 15029,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '51.7602528',
			lon: '128.121175',
		},
		district: 'Дальневосточный',
		value: 'Циолковский',
		population: 6208,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '51.28333',
			lon: '91.56667',
		},
		district: 'Сибирский',
		value: 'Чадан',
		population: 9037,
		subject: 'Тыва',
	},
	{
		coords: {
			lat: '56.76667',
			lon: '54.11667',
		},
		district: 'Приволжский',
		value: 'Чайковский',
		population: 82933,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '52.98333',
			lon: '49.71667',
		},
		district: 'Приволжский',
		value: 'Чапаевск',
		population: 72689,
		subject: 'Самарская область',
	},
	{
		coords: {
			lat: '53.24167',
			lon: '39.96667',
		},
		district: 'Центральный',
		value: 'Чаплыгин',
		population: 12656,
		subject: 'Липецкая область',
	},
	{
		coords: {
			lat: '54.97778',
			lon: '60.37',
		},
		district: 'Уральский',
		value: 'Чебаркуль',
		population: 43405,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '56.11667',
			lon: '47.23333',
		},
		district: 'Приволжский',
		value: 'Чебоксары',
		population: 447929,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '43.566657',
			lon: '43.583325',
		},
		district: 'Северо-Кавказский',
		value: 'Чегем',
		population: 17988,
		subject: 'Кабардино-Балкария',
	},
	{
		coords: {
			lat: '54.1',
			lon: '36.25',
		},
		district: 'Центральный',
		value: 'Чекалин',
		population: 994,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '55.16222',
			lon: '61.40306',
		},
		district: 'Уральский',
		value: 'Челябинск',
		population: 1130273,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '60.4',
			lon: '56.48333',
		},
		district: 'Приволжский',
		value: 'Чердынь',
		population: 4920,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '53.15',
			lon: '103.06667',
		},
		district: 'Сибирский',
		value: 'Черемхово',
		population: 52650,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '54.21667',
			lon: '83.36667',
		},
		district: 'Сибирский',
		value: 'Черепаново',
		population: 19346,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '59.11667',
			lon: '37.9',
		},
		district: 'Северо-Западный',
		value: 'Череповец',
		population: 312311,
		subject: 'Вологодская область',
	},
	{
		coords: {
			lat: '44.213888',
			lon: '42.04431',
		},
		district: 'Северо-Кавказский',
		value: 'Черкесск',
		population: 121439,
		subject: 'Карачаево-Черкесия',
	},
	{
		coords: {
			lat: '56.01472',
			lon: '38.38972',
		},
		district: 'Центральный',
		value: 'Черноголовка',
		population: 20986,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '53.81667',
			lon: '91.28333',
		},
		district: 'Сибирский',
		value: 'Черногорск',
		population: 72117,
		subject: 'Хакасия',
	},
	{
		coords: {
			lat: '56.5',
			lon: '56.08333',
		},
		district: 'Приволжский',
		value: 'Чернушка',
		population: 33275,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '54.63333',
			lon: '21.81667',
		},
		district: 'Северо-Западный',
		value: 'Черняховск',
		population: 40464,
		subject: 'Калининградская область',
	},
	{
		coords: {
			lat: '55.145',
			lon: '37.45556',
		},
		district: 'Центральный',
		value: 'Чехов',
		population: 60677,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '55.36667',
			lon: '50.63333',
		},
		district: 'Приволжский',
		value: 'Чистополь',
		population: 60703,
		subject: 'Татарстан',
	},
	{
		coords: {
			lat: '52.03333',
			lon: '113.5',
		},
		district: 'Сибирский',
		value: 'Чита',
		population: 323964,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '56.76667',
			lon: '43.25',
		},
		district: 'Приволжский',
		value: 'Чкаловск',
		population: 12371,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '59.12806',
			lon: '31.65917',
		},
		district: 'Северо-Западный',
		value: 'Чудово',
		population: 16148,
		subject: 'Новгородская область',
	},
	{
		coords: {
			lat: '55.1',
			lon: '80.96667',
		},
		district: 'Сибирский',
		value: 'Чулым',
		population: 11964,
		subject: 'Новосибирская область',
	},
	{
		coords: {
			lat: '58.28333',
			lon: '57.81667',
		},
		district: 'Приволжский',
		value: 'Чусовой',
		population: 46740,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '58.75',
			lon: '42.7',
		},
		district: 'Центральный',
		value: 'Чухлома',
		population: 5209,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '58.78333',
			lon: '56.15',
		},
		district: 'Приволжский',
		value: 'Чёрмоз',
		population: 4017,
		subject: 'Пермский край',
	},
	{
		coords: {
			lat: '51.53333',
			lon: '92.9',
		},
		district: 'Сибирский',
		value: 'Шагонар',
		population: 10958,
		subject: 'Тыва',
	},
	{
		coords: {
			lat: '56.08333',
			lon: '63.63333',
		},
		district: 'Уральский',
		value: 'Шадринск',
		population: 77744,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '43.145',
			lon: '45.903847',
		},
		district: 'Северо-Кавказский',
		value: 'Шали',
		population: 47715,
		subject: 'Чечня',
	},
	{
		coords: {
			lat: '55.525',
			lon: '89.2',
		},
		district: 'Сибирский',
		value: 'Шарыпово',
		population: 38570,
		subject: 'Красноярский край',
	},
	{
		coords: {
			lat: '58.36667',
			lon: '45.5',
		},
		district: 'Центральный',
		value: 'Шарья',
		population: 23668,
		subject: 'Костромская область',
	},
	{
		coords: {
			lat: '55.5776722',
			lon: '39.5446333',
		},
		district: 'Центральный',
		value: 'Шатура',
		population: 32836,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '47.7122111',
			lon: '40.2083694',
		},
		district: 'Южный',
		value: 'Шахты',
		population: 240152,
		subject: 'Ростовская область',
	},
	{
		coords: {
			lat: '57.67472',
			lon: '46.62083',
		},
		district: 'Приволжский',
		value: 'Шахунья',
		population: 21337,
		subject: 'Нижегородская область',
	},
	{
		coords: {
			lat: '54.03333',
			lon: '41.7',
		},
		district: 'Центральный',
		value: 'Шацк',
		population: 6562,
		subject: 'Рязанская область',
	},
	{
		coords: {
			lat: '50.40778',
			lon: '36.89694',
		},
		district: 'Центральный',
		value: 'Шебекино',
		population: 44277,
		subject: 'Белгородская область',
	},
	{
		coords: {
			lat: '52.2',
			lon: '104.1',
		},
		district: 'Сибирский',
		value: 'Шелехов',
		population: 47960,
		subject: 'Иркутская область',
	},
	{
		coords: {
			lat: '62.1',
			lon: '42.9',
		},
		district: 'Северо-Западный',
		value: 'Шенкурск',
		population: 5702,
		subject: 'Архангельская область',
	},
	{
		coords: {
			lat: '51.85',
			lon: '116.03333',
		},
		district: 'Сибирский',
		value: 'Шилка',
		population: 13947,
		subject: 'Забайкальский край',
	},
	{
		coords: {
			lat: '52',
			lon: '127.66667',
		},
		district: 'Дальневосточный',
		value: 'Шимановск',
		population: 19815,
		subject: 'Амурская область',
	},
	{
		coords: {
			lat: '52.1137809',
			lon: '47.199229',
		},
		district: 'Приволжский',
		value: 'Шиханы',
		population: 6067,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '59.941819',
			lon: '31.034363',
		},
		district: 'Северо-Западный',
		value: 'Шлиссельбург',
		population: 13305,
		subject: 'Ленинградская область',
	},
	{
		coords: {
			lat: '55.5',
			lon: '46.41667',
		},
		district: 'Приволжский',
		value: 'Шумерля',
		population: 33412,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '55.23333',
			lon: '63.28333',
		},
		district: 'Уральский',
		value: 'Шумиха',
		population: 17821,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '56.85',
			lon: '41.36667',
		},
		district: 'Центральный',
		value: 'Шуя',
		population: 58528,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '51.88111',
			lon: '36.90306',
		},
		district: 'Центральный',
		value: 'Щигры',
		population: 17043,
		subject: 'Курская область',
	},
	{
		coords: {
			lat: '55.21667',
			lon: '62.76667',
		},
		district: 'Уральский',
		value: 'Щучье',
		population: 10971,
		subject: 'Курганская область',
	},
	{
		coords: {
			lat: '54',
			lon: '37.51667',
		},
		district: 'Центральный',
		value: 'Щёкино',
		population: 58154,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '45.42361',
			lon: '35.81861',
		},
		district: 'Южный',
		value: 'Щёлкино',
		population: 10620,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '55.91667',
			lon: '38',
		},
		district: 'Центральный',
		value: 'Щёлково',
		population: 110380,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '55.88333',
			lon: '38.78333',
		},
		district: 'Центральный',
		value: 'Электрогорск',
		population: 22120,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '55.8',
			lon: '38.45',
		},
		district: 'Центральный',
		value: 'Электросталь',
		population: 155324,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '55.71667',
			lon: '38.21667',
		},
		district: 'Центральный',
		value: 'Электроугли',
		population: 20120,
		subject: 'Московская область',
	},
	{
		coords: {
			lat: '46.31667',
			lon: '44.26667',
		},
		district: 'Южный',
		value: 'Элиста',
		population: 103728,
		subject: 'Калмыкия',
	},
	{
		coords: {
			lat: '51.46667',
			lon: '46.11667',
		},
		district: 'Приволжский',
		value: 'Энгельс',
		population: 202838,
		subject: 'Саратовская область',
	},
	{
		coords: {
			lat: '51.83333',
			lon: '40.8',
		},
		district: 'Центральный',
		value: 'Эртиль',
		population: 11387,
		subject: 'Воронежская область',
	},
	{
		coords: {
			lat: '61.31667',
			lon: '63.35',
		},
		district: 'Уральский',
		value: 'Югорск',
		population: 34066,
		subject: 'Ханты-Мансийский АО — Югра',
	},
	{
		coords: {
			lat: '56.58333',
			lon: '42.01667',
		},
		district: 'Центральный',
		value: 'Южа',
		population: 14170,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '46.95',
			lon: '142.73333',
		},
		district: 'Дальневосточный',
		value: 'Южно-Сахалинск',
		population: 181727,
		subject: 'Сахалинская область',
	},
	{
		coords: {
			lat: '44.66667',
			lon: '45.65',
		},
		district: 'Северо-Кавказский',
		value: 'Южно-Сухокумск',
		population: 10048,
		subject: 'Дагестан',
	},
	{
		coords: {
			lat: '54.45',
			lon: '61.25',
		},
		district: 'Уральский',
		value: 'Южноуральск',
		population: 37890,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '55.73333',
			lon: '84.9',
		},
		district: 'Сибирский',
		value: 'Юрга',
		population: 81536,
		subject: 'Кемеровская область',
	},
	{
		coords: {
			lat: '56.5',
			lon: '39.68333',
		},
		district: 'Центральный',
		value: 'Юрьев-Польский',
		population: 19588,
		subject: 'Владимирская область',
	},
	{
		coords: {
			lat: '57.31667',
			lon: '43.1',
		},
		district: 'Центральный',
		value: 'Юрьевец',
		population: 10205,
		subject: 'Ивановская область',
	},
	{
		coords: {
			lat: '54.86667',
			lon: '58.43333',
		},
		district: 'Уральский',
		value: 'Юрюзань',
		population: 12568,
		subject: 'Челябинская область',
	},
	{
		coords: {
			lat: '54.75',
			lon: '35.23333',
		},
		district: 'Центральный',
		value: 'Юхнов',
		population: 7056,
		subject: 'Калужская область',
	},
	{
		coords: {
			lat: '55.95',
			lon: '46.2',
		},
		district: 'Приволжский',
		value: 'Ядрин',
		population: 9614,
		subject: 'Чувашия',
	},
	{
		coords: {
			lat: '62.02722',
			lon: '129.73194',
		},
		district: 'Дальневосточный',
		value: 'Якутск',
		population: 269486,
		subject: 'Якутия',
	},
	{
		coords: {
			lat: '44.49944',
			lon: '34.15528',
		},
		district: 'Южный',
		value: 'Ялта',
		population: 76746,
		subject: 'Крым',
	},
	{
		coords: {
			lat: '56.65',
			lon: '66.3',
		},
		district: 'Уральский',
		value: 'Ялуторовск',
		population: 36494,
		subject: 'Тюменская область',
	},
	{
		coords: {
			lat: '56.26667',
			lon: '54.93333',
		},
		district: 'Приволжский',
		value: 'Янаул',
		population: 26988,
		subject: 'Башкортостан',
	},
	{
		coords: {
			lat: '57.303306',
			lon: '47.868806',
		},
		district: 'Приволжский',
		value: 'Яранск',
		population: 17252,
		subject: 'Кировская область',
	},
	{
		coords: {
			lat: '52.93333',
			lon: '78.58333',
		},
		district: 'Сибирский',
		value: 'Яровое',
		population: 18085,
		subject: 'Алтайский край',
	},
	{
		coords: {
			lat: '57.61667',
			lon: '39.85',
		},
		district: 'Центральный',
		value: 'Ярославль',
		population: 591486,
		subject: 'Ярославская область',
	},
	{
		coords: {
			lat: '55.06667',
			lon: '32.68333',
		},
		district: 'Центральный',
		value: 'Ярцево',
		population: 47853,
		subject: 'Смоленская область',
	},
	{
		coords: {
			lat: '54.4793833',
			lon: '37.6933556',
		},
		district: 'Центральный',
		value: 'Ясногорск',
		population: 16804,
		subject: 'Тульская область',
	},
	{
		coords: {
			lat: '51.03333',
			lon: '59.86667',
		},
		district: 'Приволжский',
		value: 'Ясный',
		population: 16082,
		subject: 'Оренбургская область',
	},
	{
		coords: {
			lat: '56.28333',
			lon: '37.48333',
		},
		district: 'Центральный',
		value: 'Яхрома',
		population: 13248,
		subject: 'Московская область',
	},
];

const mainSNGcities = [
	{
		value: 'Астана',
		coords: {
			lat: '51.160522',
			lon: '71.470355',
		},
		population: 1129400,
	},
	{
		value: 'Алматы',
		coords: {
			lat: '43.2565',
			lon: '76.9286',
		},
		population: 2127000,
	},
	{
		value: 'Шымкент',
		coords: {
			lat: '42.317793',
			lon: '69.591593',
		},
		population: 1011493,
	},
	{
		value: 'Караганда',
		coords: {
			lat: '49.8024',
			lon: '73.0791',
		},
		population: 526000,
	},
	{
		value: 'Актобе',
		coords: {
			lat: '50.293936',
			lon: '57.163741',
		},
		population: 447573,
	},
	{
		value: 'Тараз',
		coords: {
			lat: '42.9000',
			lon: '71.3667',
		},
		population: 416808,
	},
	{
		value: 'Бишкек',
		coords: {
			lat: '42.8746',
			lon: '74.5698',
		},
		population: 937400,
	},
	{
		value: 'Душанбе',
		coords: {
			lat: '38.545524',
			lon: '68.790443',
		},
		population: 992464,
	},
	{
		value: 'Ташкент',
		coords: {
			lat: '41.2995',
			lon: '69.2401',
		},
		population: 2309600,
	},
	{
		value: 'Ашхабад',
		coords: {
			lat: '37.9338',
			lon: '58.3619',
		},
		population: 834000,
	},
	{
		value: 'Баку',
		coords: {
			lat: '40.4093',
			lon: '49.8671',
		},
		population: 2151000,
	},
	{
		value: 'Гянджа',
		coords: {
			lat: '40.6817',
			lon: '46.3606',
		},
		population: 308100,
	},
	{
		value: 'Ереван',
		coords: {
			lat: '40.177200',
			lon: '44.503490',
		},
		population: 1075000,
	},
	{
		value: 'Минск',
		coords: {
			lat: '53.9045',
			lon: '27.5615',
		},
		population: 2020000,
	},
	{
		value: 'Гомель',
		coords: {
			lat: '52.4351',
			lon: '30.9754',
		},
		population: 535693,
	},
	{
		value: 'Могилёв',
		coords: {
			lat: '53.8984',
			lon: '30.3310',
		},
		population: 374200,
	},
	{
		value: 'Витебск',
		coords: {
			lat: '55.185791',
			lon: '30.201622',
		},
		population: 373500,
	},
	{
		value: 'Брест (Беларусь)',
		coords: {
			lat: '52.0976',
			lon: '23.7341',
		},
		population: 347900,
	},
];

export default cities.concat(mainSNGcities);
