import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../../hoc/useAuth';
import { AuthContext } from '../../hoc/AuthProvider';
import AuthService from '../../services/AuthService';
import background from '../../img/draft-background.jpg';
import { DialogChangePassword } from '../../components/Dialogs/DialogChangePassword/DialogChangePassword';
import { ChatContext } from '../../context/ChatContext';
import $api from '../../http';

import classes from './Login.module.css';

const Login = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { signin } = useAuth();

	const fromPage = location.state?.from?.pathname || '/';

	const handleSubmit = (event) => {
		event.preventDefault();
		const form = event.target;
		const user = form.username.value;

		signin(user, () => navigate(fromPage, { replace: true }));
	};

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [recoveryEmail, setRecoveryEmail] = useState('');
	const [dialogOpen, setDialogOpen] = useState(false);
	const { store, setAuth } = useContext(AuthContext);
	const [errCookie, setErrCookie] = useState(false);

	const { setNotifications } = useContext(ChatContext);

	const checkCookie = () => {
		if (!navigator.cookieEnabled) {
			setErrCookie(true);
			setMessage('Работа с cookie отключена');
		}
	};

	useEffect(() => {
		checkCookie();
	}, []);

	const newMessage = async () => {
		try {
			const response = await $api.get('/messages/init');
			// console.log("<<<new Message>>>", response.data);
			if (response?.data.length > 0) {
				const notes = response.data.map((el) => {
					const newNote = {
						date: el.createdAt,
						isRead: false,
						senderId: el.senderId,
					};
					return newNote;
				});
				setNotifications((prev) => [...prev, ...notes]);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleLogin = async (e, email, password) => {
		try {
			e.preventDefault();
			setMessage('');
			store.isLoading = true;
			if (email.trim() && password.trim()) {
				const response = await AuthService.login(email, password);

				const email2 = response.data?.user?.email;
				const fio = response.data?.user?.name;
				const userId = response.data?.user?._id;
				localStorage.setItem('token', response.data.accessToken);
				store.setAuth(true);
				store.setUser(response?.data?.user);
				setAuth({ email2, fio, userId });
				// store.newMessage();
				newMessage();
				navigate('/draft/1');
			}
		} catch (error) {
			if (error?.response?.status === 429) {
				setMessage(
					'С вашего IP адреса поступило много неверных сведений. Доступ к авторизации заблокирован на 1 минуту.'
				);
			} else if (error?.response?.status === 400) {
				setMessage('Неверный логин или пароль');
			} else {
				setMessage(error?.response?.data?.message);
			}
		} finally {
			store.isLoading = false;
		}
	};

	const handleRecoverEmail = async () => {
		const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (regex.test(recoveryEmail)) {
			try {
				const formData = new FormData();
				formData.append('email', recoveryEmail);
				const response = await AuthService.postChangePassword(formData);
				setMessage(response?.data?.message);
			} catch (error) {
				console.error(error);
			}
		} else {
			setMessage('Неккоректный адрес почты');
		}
	};

	const handleClickOpen = () => {
		setDialogOpen(true);
	};

	return (
		<div className={classes.body}>
			<DialogChangePassword
				open={dialogOpen}
				setOpen={setDialogOpen}
				value={recoveryEmail}
				setValue={setRecoveryEmail}
				handleSubmit={handleRecoverEmail}
			/>
			<div className={classes.wrapper}>
				<div className={classes.main}>
					<section className={classes.section}>
						<h1>вход в личный кабинет</h1>
						<form className={classes.form}>
							<input
								type="email"
								className={classes.loginInput}
								placeholder="Введите email"
								inputMode="email"
								name="email"
								required
								onChange={(e) => setEmail(e.target.value)}
								value={email}
							/>

							<input
								type="password"
								className={classes.loginInput}
								placeholder="Введите пароль"
								name="password"
								required
								onChange={(e) => setPassword(e.target.value)}
								value={password}
							/>
							<p className={classes.registrationLink} onClick={handleClickOpen}>
								Забыли пароль?
							</p>

							<button
								className={classes.btn}
								// onClick={() => store.login(email, password, navigate)}
								disabled={errCookie}
								onClick={(e) => handleLogin(e, email?.trim(), password?.trim())}
							>
								Вход
							</button>
							<div className="text-red-500 p-y-2 font-semibold">{message}</div>
						</form>
						<div className={classes.registrationBlock}>
							<p>Нет аккаунта?</p>
							<Link to="/registration" className={classes.registrationLink}>
								Зарегистрируйтесь
							</Link>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};

export default Login;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	background-image: url(${background});
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	min-height: calc(100vh - 278.5px - 274px);

	@media screen and (max-width: 480px) {
		min-height: auto;
		height: auto;
	}
`;

const Wrapper2 = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	background: radial-gradient(
		circle,
		rgba(255, 255, 255, 1) 70%,
		rgba(255, 255, 255, 0.9472163865546218) 80%,
		rgba(255, 255, 255, 0.9220063025210083) 90%,
		rgba(255, 255, 255, 0.7259278711484594) 100%
	);
	justify-content: center;
	min-height: calc(100vh - 278.5px - 274px);

	@media screen and (max-width: 480px) {
		min-height: auto;
		height: auto;
	}
`;

const Body = styled.div`
	display: flex;
	margin: 0 auto;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 590px;
	padding: 110px 40px 200px;
	box-sizing: content-box;

	@media screen and (max-width: 450px) {
		padding: 50px 15px 50px;
	}

	h1 {
		font-family: 'Ubuntu', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 128.69%;
		letter-spacing: 0.1em;
		color: #333333;
		text-transform: uppercase;
		text-align: center;

		margin-bottom: 75px;
		@media screen and (max-width: 450px) {
			margin-bottom: 40px;
			font-size: 30px;
		}
	}

	p {
		font-family: 'Ubuntu', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 21px;
		text-align: center;
		margin-right: 10px;

		color: #bfbfbf;
	}
`;

const Input = styled.input`
	padding: 10px 20px;
	width: 100%;
	outline: none;
	border: 2px solid #bfbfbf;
	border-radius: 5px;
	margin-bottom: 26px;
	height: 70px;
	color: black;

	@media screen and (max-width: 450px) {
		height: 50px;
		font-size: 16px;
		padding: 10px 20px;
		border: 1px solid #bfbfbf;
	}
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;

	button {
		font-family: 'Ubuntu', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		text-align: center;

		color: #ffffff;
		background: #00aeae;
		border-radius: 5px;
		max-width: 340px;
		width: 100%;
		height: 70px;
		margin-top: 50px;
		margin-bottom: 25px;

		@media screen and (max-width: 450px) {
			width: 80%;
			height: 60px;
			margin-top: 25px;
		}
	}
`;
