import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { skeletonCost } from '../../utils/skeletonArr';
import config from '../../settings/settings';
import s from './reklama.module.css';

const TopAd = ({ reklama, isLoading }) => {
	const navigate = useNavigate();

	reklama.sort((a, b) => (a.top_place > b.top_place ? 1 : -1));

	return (
		<div className={s.body}>
			{isLoading ? (
				<>
					{skeletonCost(3).map((el) => {
						return (
							<Skeleton variant="rounded" width={210} height={140} key={el} />
						);
					})}
				</>
			) : (
				<>
					{reklama?.map((item, index) => {
						if (item?.enabled === true && item?.top_place !== 0) {
							const handleAdv = (e) => {
								e.preventDefault();
								navigate(`/advertising/${item?._id}`);
							};

							return (
								<div key={index} className={s.adv} onClick={handleAdv}>
									<img
										src={`${config?.baseUrlUpload}/uploads/ad/${item?.preview_url[0]?.filename}`}
										alt="#"
									/>
									<div className={s.overlayText}>
										<span>{item?.overlay}</span>
									</div>
								</div>
							);
						}
					})}
				</>
			)}
		</div>
	);
};

export default TopAd;
