/**
 * Форматирует число в соответствии с локалью и опциями.
 * @param {number} val - Число, которое нужно отформатировать.
 * @param {boolean} [isPrice=true] - Флаг, указывающий, является ли число ценой.
 * @returns {string} Отформатированная строка в соответствии с локалью и опциями.
 */

export const numFormat = (val, isPrice = true) => {
	const loc = 'ru-Ru';
	const payOptions = isPrice
		? {
				style: 'currency',
				currency: 'RUB',
				maximumFractionDigits: 0,
		  }
		: {};
	return new Intl.NumberFormat(loc, payOptions).format(+val);
};
