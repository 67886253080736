const config = {
	newsDoc: 17,
	partnerDoc: 12,
	draftDoc: 12,
	plantDoc: 12,
	MAX_FILE: 10,
	uploadPlantPhoto: '/uploads/plant_ap/',
	uploadDraftPhoto: '/uploads/',
	ZNAK: '★',
	ZNAK2: '☆',

	// baseUrl: 'http://192.168.1.253:5500/api',
	// baseUrl: 'http://192.168.1.65:5500/api',
	// baseUrl: 'http://localhost:5500/api',
	baseUrl: 'https://settings3.copartner.ru/api',
	// baseUrl: 'https://ampapi.copartner.ru/api',

	baseUrlUpload: 'https://settings3.copartner.ru', //это важное
	// baseUrlUpload: 'http://localhost:5500',
	// baseUrlUpload:'http://192.168.1.253:5500/',
	// baseUrlUpload: 'http://192.168.1.65:5500',
	// baseUrlUpload:'https://ampapi.copartner.ru',

	mainUrl: 'https://amp.copartner.ru',
	filesUrl: 'https://files.copartner.ru',
	// filesUrl: 'http://localhost:7700',

	// SOCKET_URL: 'https://socketamp.copartner.ru',
	SOCKET_URL: 'wss://socketamp.copartner.ru',
	// SOCKET_URL: 'http://localhost:8910',
	// SOCKET_URL: 'http://io',
};

module.exports = config;
