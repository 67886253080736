const rusCities = [
	{
		id: 1,
		name: 'Абаза',
	},
	{
		id: 2,
		name: 'Абакан',
	},
	{
		id: 3,
		name: 'Абдулино',
	},
	{
		id: 4,
		name: 'Абинск',
	},
	{
		id: 5,
		name: 'Агидель',
	},
	{
		id: 6,
		name: 'Агрыз',
	},
	{
		id: 7,
		name: 'Адыгейск',
	},
	{
		id: 8,
		name: 'Азнакаево',
	},
	{
		id: 9,
		name: 'Азов',
	},
	{
		id: 10,
		name: 'Ак-Довурак',
	},
	{
		id: 11,
		name: 'Аксай',
	},
	{
		id: 12,
		name: 'Алагир',
	},
	{
		id: 13,
		name: 'Алапаевск',
	},
	{
		id: 14,
		name: 'Алатырь',
	},
	{
		id: 15,
		name: 'Алдан',
	},
	{
		id: 16,
		name: 'Алейск',
	},
	{
		id: 17,
		name: 'Александров',
	},
	{
		id: 18,
		name: 'Александровск-Сахалинский',
	},
	{
		id: 19,
		name: 'Александровск',
	},
	{
		id: 20,
		name: 'Алексеевка',
	},
	{
		id: 21,
		name: 'Алексин',
	},
	{
		id: 22,
		name: 'Алзамай',
	},
	{
		id: 23,
		name: 'Алупка',
	},
	{
		id: 24,
		name: 'Алушта',
	},
	{
		id: 25,
		name: 'Альметьевск',
	},
	{
		id: 26,
		name: 'Амурск',
	},
	{
		id: 27,
		name: 'Анадырь',
	},
	{
		id: 28,
		name: 'Анапа',
	},
	{
		id: 29,
		name: 'Ангарск',
	},
	{
		id: 30,
		name: 'Андреаполь',
	},
	{
		id: 31,
		name: 'Анжеро-Судженск',
	},
	{
		id: 32,
		name: 'Анива',
	},
	{
		id: 33,
		name: 'Апатиты',
	},
	{
		id: 34,
		name: 'Апрелевка',
	},
	{
		id: 35,
		name: 'Апшеронск',
	},
	{
		id: 36,
		name: 'Арамиль',
	},
	{
		id: 37,
		name: 'Аргун',
	},
	{
		id: 38,
		name: 'Ардатов',
	},
	{
		id: 39,
		name: 'Ардон',
	},
	{
		id: 40,
		name: 'Арзамас',
	},
	{
		id: 41,
		name: 'Аркадак',
	},
	{
		id: 42,
		name: 'Армавир',
	},
	{
		id: 43,
		name: 'Армянск',
	},
	{
		id: 44,
		name: 'Арсеньев',
	},
	{
		id: 45,
		name: 'Арск',
	},
	{
		id: 46,
		name: 'Артём',
	},
	{
		id: 47,
		name: 'Артёмовск',
	},
	{
		id: 48,
		name: 'Артёмовский',
	},
	{
		id: 49,
		name: 'Архангельск',
	},
	{
		id: 50,
		name: 'Асбест',
	},
	{
		id: 51,
		name: 'Асино',
	},
	{
		id: 52,
		name: 'Астрахань',
	},
	{
		id: 53,
		name: 'Аткарск',
	},
	{
		id: 54,
		name: 'Ахтубинск',
	},
	{
		id: 55,
		name: 'Ачинск',
	},
	{
		id: 56,
		name: 'Аша',
	},
	{
		id: 57,
		name: 'Бабаево',
	},
	{
		id: 58,
		name: 'Бабушкин',
	},
	{
		id: 59,
		name: 'Бавлы',
	},
	{
		id: 60,
		name: 'Багратионовск',
	},
	{
		id: 61,
		name: 'Байкальск',
	},
	{
		id: 62,
		name: 'Баймак',
	},
	{
		id: 63,
		name: 'Бакал',
	},
	{
		id: 64,
		name: 'Баксан',
	},
	{
		id: 65,
		name: 'Балабаново',
	},
	{
		id: 66,
		name: 'Балаково',
	},
	{
		id: 67,
		name: 'Балахна',
	},
	{
		id: 68,
		name: 'Балашиха',
	},
	{
		id: 69,
		name: 'Балашов',
	},
	{
		id: 70,
		name: 'Балей',
	},
	{
		id: 71,
		name: 'Балтийск',
	},
	{
		id: 72,
		name: 'Барабинск',
	},
	{
		id: 73,
		name: 'Барнаул',
	},
	{
		id: 74,
		name: 'Барыш',
	},
	{
		id: 75,
		name: 'Батайск',
	},
	{
		id: 76,
		name: 'Бахчисарай',
	},
	{
		id: 77,
		name: 'Бежецк',
	},
	{
		id: 78,
		name: 'Белая Калитва',
	},
	{
		id: 79,
		name: 'Белая Холуница',
	},
	{
		id: 80,
		name: 'Белгород',
	},
	{
		id: 81,
		name: 'Белебей',
	},
	{
		id: 82,
		name: 'Белинский',
	},
	{
		id: 83,
		name: 'Белово',
	},
	{
		id: 84,
		name: 'Белогорск',
	},
	{
		id: 85,
		name: 'Белозерск',
	},
	{
		id: 86,
		name: 'Белокуриха',
	},
	{
		id: 87,
		name: 'Беломорск',
	},
	{
		id: 88,
		name: 'Белоозёрский',
	},
	{
		id: 89,
		name: 'Белорецк',
	},
	{
		id: 90,
		name: 'Белореченск',
	},
	{
		id: 91,
		name: 'Белоусово',
	},
	{
		id: 92,
		name: 'Белоярский',
	},
	{
		id: 93,
		name: 'Белый',
	},
	{
		id: 94,
		name: 'Белёв',
	},
	{
		id: 95,
		name: 'Бердск',
	},
	{
		id: 96,
		name: 'Березники',
	},
	{
		id: 97,
		name: 'Берёзовский',
	},
	{
		id: 98,
		name: 'Беслан',
	},
	{
		id: 99,
		name: 'Бийск',
	},
	{
		id: 100,
		name: 'Бикин',
	},
	{
		id: 101,
		name: 'Билибино',
	},
	{
		id: 102,
		name: 'Биробиджан',
	},
	{
		id: 103,
		name: 'Бирск',
	},
	{
		id: 104,
		name: 'Бирюсинск',
	},
	{
		id: 105,
		name: 'Бирюч',
	},
	{
		id: 106,
		name: 'Благовещенск',
	},
	{
		id: 107,
		name: 'Благодарный',
	},
	{
		id: 108,
		name: 'Бобров',
	},
	{
		id: 109,
		name: 'Богданович',
	},
	{
		id: 110,
		name: 'Богородицк',
	},
	{
		id: 111,
		name: 'Богородск',
	},
	{
		id: 112,
		name: 'Боготол',
	},
	{
		id: 113,
		name: 'Богучар',
	},
	{
		id: 114,
		name: 'Бодайбо',
	},
	{
		id: 115,
		name: 'Бокситогорск',
	},
	{
		id: 116,
		name: 'Болгар',
	},
	{
		id: 117,
		name: 'Бологое',
	},
	{
		id: 118,
		name: 'Болотное',
	},
	{
		id: 119,
		name: 'Болохово',
	},
	{
		id: 120,
		name: 'Болхов',
	},
	{
		id: 121,
		name: 'Большой Камень',
	},
	{
		id: 122,
		name: 'Бор',
	},
	{
		id: 123,
		name: 'Борзя',
	},
	{
		id: 124,
		name: 'Борисоглебск',
	},
	{
		id: 125,
		name: 'Боровичи',
	},
	{
		id: 126,
		name: 'Боровск',
	},
	{
		id: 127,
		name: 'Бородино',
	},
	{
		id: 128,
		name: 'Братск',
	},
	{
		id: 129,
		name: 'Бронницы',
	},
	{
		id: 130,
		name: 'Брянск',
	},
	{
		id: 131,
		name: 'Бугульма',
	},
	{
		id: 132,
		name: 'Бугуруслан',
	},
	{
		id: 133,
		name: 'Будённовск',
	},
	{
		id: 134,
		name: 'Бузулук',
	},
	{
		id: 135,
		name: 'Буинск',
	},
	{
		id: 136,
		name: 'Буй',
	},
	{
		id: 137,
		name: 'Буйнакск',
	},
	{
		id: 138,
		name: 'Бутурлиновка',
	},
	{
		id: 139,
		name: 'Валдай',
	},
	{
		id: 140,
		name: 'Валуйки',
	},
	{
		id: 141,
		name: 'Велиж',
	},
	{
		id: 142,
		name: 'Великие Луки',
	},
	{
		id: 143,
		name: 'Великий Новгород',
	},
	{
		id: 144,
		name: 'Великий Устюг',
	},
	{
		id: 145,
		name: 'Вельск',
	},
	{
		id: 146,
		name: 'Венёв',
	},
	{
		id: 147,
		name: 'Верещагино',
	},
	{
		id: 148,
		name: 'Верея',
	},
	{
		id: 149,
		name: 'Верхнеуральск',
	},
	{
		id: 150,
		name: 'Верхний Тагил',
	},
	{
		id: 151,
		name: 'Верхний Уфалей',
	},
	{
		id: 152,
		name: 'Верхняя Пышма',
	},
	{
		id: 153,
		name: 'Верхняя Салда',
	},
	{
		id: 154,
		name: 'Верхняя Тура',
	},
	{
		id: 155,
		name: 'Верхотурье',
	},
	{
		id: 156,
		name: 'Верхоянск',
	},
	{
		id: 157,
		name: 'Весьегонск',
	},
	{
		id: 158,
		name: 'Ветлуга',
	},
	{
		id: 159,
		name: 'Видное',
	},
	{
		id: 160,
		name: 'Вилюйск',
	},
	{
		id: 161,
		name: 'Вилючинск',
	},
	{
		id: 162,
		name: 'Вихоревка',
	},
	{
		id: 163,
		name: 'Вичуга',
	},
	{
		id: 164,
		name: 'Владивосток',
	},
	{
		id: 165,
		name: 'Владикавказ',
	},
	{
		id: 166,
		name: 'Владимир',
	},
	{
		id: 167,
		name: 'Волгоград',
	},
	{
		id: 168,
		name: 'Волгодонск',
	},
	{
		id: 169,
		name: 'Волгореченск',
	},
	{
		id: 170,
		name: 'Волжск',
	},
	{
		id: 171,
		name: 'Волжский',
	},
	{
		id: 172,
		name: 'Вологда',
	},
	{
		id: 173,
		name: 'Володарск',
	},
	{
		id: 174,
		name: 'Волоколамск',
	},
	{
		id: 175,
		name: 'Волосово',
	},
	{
		id: 176,
		name: 'Волхов',
	},
	{
		id: 177,
		name: 'Волчанск',
	},
	{
		id: 178,
		name: 'Вольск',
	},
	{
		id: 179,
		name: 'Воркута',
	},
	{
		id: 180,
		name: 'Воронеж',
	},
	{
		id: 181,
		name: 'Ворсма',
	},
	{
		id: 182,
		name: 'Воскресенск',
	},
	{
		id: 183,
		name: 'Воткинск',
	},
	{
		id: 184,
		name: 'Всеволожск',
	},
	{
		id: 185,
		name: 'Вуктыл',
	},
	{
		id: 186,
		name: 'Выборг',
	},
	{
		id: 187,
		name: 'Выкса',
	},
	{
		id: 188,
		name: 'Высоковск',
	},
	{
		id: 189,
		name: 'Высоцк',
	},
	{
		id: 190,
		name: 'Вытегра',
	},
	{
		id: 191,
		name: 'Вышний Волочёк',
	},
	{
		id: 192,
		name: 'Вяземский',
	},
	{
		id: 193,
		name: 'Вязники',
	},
	{
		id: 194,
		name: 'Вязьма',
	},
	{
		id: 195,
		name: 'Вятские Поляны',
	},
	{
		id: 196,
		name: 'Гаврилов Посад',
	},
	{
		id: 197,
		name: 'Гаврилов-Ям',
	},
	{
		id: 198,
		name: 'Гагарин',
	},
	{
		id: 199,
		name: 'Гаджиево',
	},
	{
		id: 200,
		name: 'Гай',
	},
	{
		id: 201,
		name: 'Галич',
	},
	{
		id: 202,
		name: 'Гатчина',
	},
	{
		id: 203,
		name: 'Гвардейск',
	},
	{
		id: 204,
		name: 'Гдов',
	},
	{
		id: 205,
		name: 'Геленджик',
	},
	{
		id: 206,
		name: 'Георгиевск',
	},
	{
		id: 207,
		name: 'Глазов',
	},
	{
		id: 208,
		name: 'Голицыно',
	},
	{
		id: 209,
		name: 'Горбатов',
	},
	{
		id: 210,
		name: 'Горно-Алтайск',
	},
	{
		id: 211,
		name: 'Горнозаводск',
	},
	{
		id: 212,
		name: 'Горняк',
	},
	{
		id: 213,
		name: 'Городец',
	},
	{
		id: 214,
		name: 'Городище',
	},
	{
		id: 215,
		name: 'Городовиковск',
	},
	{
		id: 216,
		name: 'Гороховец',
	},
	{
		id: 217,
		name: 'Горячий Ключ',
	},
	{
		id: 218,
		name: 'Грайворон',
	},
	{
		id: 219,
		name: 'Гремячинск',
	},
	{
		id: 220,
		name: 'Грозный',
	},
	{
		id: 221,
		name: 'Грязи',
	},
	{
		id: 222,
		name: 'Грязовец',
	},
	{
		id: 223,
		name: 'Губаха',
	},
	{
		id: 224,
		name: 'Губкин',
	},
	{
		id: 225,
		name: 'Губкинский',
	},
	{
		id: 226,
		name: 'Гудермес',
	},
	{
		id: 227,
		name: 'Гуково',
	},
	{
		id: 228,
		name: 'Гулькевичи',
	},
	{
		id: 229,
		name: 'Гурьевск',
	},
	{
		id: 230,
		name: 'Гусев',
	},
	{
		id: 231,
		name: 'Гусиноозёрск',
	},
	{
		id: 232,
		name: 'Гусь-Хрустальный',
	},
	{
		id: 233,
		name: 'Давлеканово',
	},
	{
		id: 234,
		name: 'Дагестанские Огни',
	},
	{
		id: 235,
		name: 'Далматово',
	},
	{
		id: 236,
		name: 'Дальнегорск',
	},
	{
		id: 237,
		name: 'Дальнереченск',
	},
	{
		id: 238,
		name: 'Данилов',
	},
	{
		id: 239,
		name: 'Данков',
	},
	{
		id: 240,
		name: 'Дегтярск',
	},
	{
		id: 241,
		name: 'Дедовск',
	},
	{
		id: 242,
		name: 'Демидов',
	},
	{
		id: 243,
		name: 'Дербент',
	},
	{
		id: 244,
		name: 'Десногорск',
	},
	{
		id: 245,
		name: 'Джанкой',
	},
	{
		id: 246,
		name: 'Дзержинск',
	},
	{
		id: 247,
		name: 'Дзержинский',
	},
	{
		id: 248,
		name: 'Дивногорск',
	},
	{
		id: 249,
		name: 'Дигора',
	},
	{
		id: 250,
		name: 'Димитровград',
	},
	{
		id: 251,
		name: 'Дмитриев',
	},
	{
		id: 252,
		name: 'Дмитров',
	},
	{
		id: 253,
		name: 'Дмитровск',
	},
	{
		id: 254,
		name: 'Дно',
	},
	{
		id: 255,
		name: 'Добрянка',
	},
	{
		id: 256,
		name: 'Долгопрудный',
	},
	{
		id: 257,
		name: 'Долинск',
	},
	{
		id: 258,
		name: 'Домодедово',
	},
	{
		id: 259,
		name: 'Донецк',
	},
	{
		id: 260,
		name: 'Донской',
	},
	{
		id: 261,
		name: 'Дорогобуж',
	},
	{
		id: 262,
		name: 'Дрезна',
	},
	{
		id: 263,
		name: 'Дубна',
	},
	{
		id: 264,
		name: 'Дубовка',
	},
	{
		id: 265,
		name: 'Дудинка',
	},
	{
		id: 266,
		name: 'Духовщина',
	},
	{
		id: 267,
		name: 'Дюртюли',
	},
	{
		id: 268,
		name: 'Дятьково',
	},
	{
		id: 269,
		name: 'Евпатория',
	},
	{
		id: 270,
		name: 'Егорьевск',
	},
	{
		id: 271,
		name: 'Ейск',
	},
	{
		id: 272,
		name: 'Екатеринбург',
	},
	{
		id: 273,
		name: 'Елабуга',
	},
	{
		id: 274,
		name: 'Елец',
	},
	{
		id: 275,
		name: 'Елизово',
	},
	{
		id: 276,
		name: 'Ельня',
	},
	{
		id: 277,
		name: 'Еманжелинск',
	},
	{
		id: 278,
		name: 'Емва',
	},
	{
		id: 279,
		name: 'Енисейск',
	},
	{
		id: 280,
		name: 'Ермолино',
	},
	{
		id: 281,
		name: 'Ершов',
	},
	{
		id: 282,
		name: 'Ессентуки',
	},
	{
		id: 283,
		name: 'Ефремов',
	},
	{
		id: 284,
		name: 'Железноводск',
	},
	{
		id: 285,
		name: 'Железногорск-Илимский',
	},
	{
		id: 286,
		name: 'Железногорск',
	},
	{
		id: 287,
		name: 'Жердевка',
	},
	{
		id: 288,
		name: 'Жигулёвск',
	},
	{
		id: 289,
		name: 'Жиздра',
	},
	{
		id: 290,
		name: 'Жирновск',
	},
	{
		id: 291,
		name: 'Жуков',
	},
	{
		id: 292,
		name: 'Жуковка',
	},
	{
		id: 293,
		name: 'Жуковский',
	},
	{
		id: 294,
		name: 'Завитинск',
	},
	{
		id: 295,
		name: 'Заводоуковск',
	},
	{
		id: 296,
		name: 'Заволжск',
	},
	{
		id: 297,
		name: 'Заволжье',
	},
	{
		id: 298,
		name: 'Задонск',
	},
	{
		id: 299,
		name: 'Заинск',
	},
	{
		id: 300,
		name: 'Закаменск',
	},
	{
		id: 301,
		name: 'Заозёрный',
	},
	{
		id: 302,
		name: 'Заозёрск',
	},
	{
		id: 303,
		name: 'Западная Двина',
	},
	{
		id: 304,
		name: 'Заполярный',
	},
	{
		id: 305,
		name: 'Зарайск',
	},
	{
		id: 306,
		name: 'Заречный',
	},
	{
		id: 307,
		name: 'Заринск',
	},
	{
		id: 308,
		name: 'Звенигово',
	},
	{
		id: 309,
		name: 'Звенигород',
	},
	{
		id: 310,
		name: 'Зверево',
	},
	{
		id: 311,
		name: 'Зеленогорск',
	},
	{
		id: 312,
		name: 'Зеленоградск',
	},
	{
		id: 313,
		name: 'Зеленодольск',
	},
	{
		id: 314,
		name: 'Зеленокумск',
	},
	{
		id: 315,
		name: 'Зерноград',
	},
	{
		id: 316,
		name: 'Зея',
	},
	{
		id: 317,
		name: 'Зима',
	},
	{
		id: 318,
		name: 'Златоуст',
	},
	{
		id: 319,
		name: 'Злынка',
	},
	{
		id: 320,
		name: 'Змеиногорск',
	},
	{
		id: 321,
		name: 'Знаменск',
	},
	{
		id: 322,
		name: 'Зубцов',
	},
	{
		id: 323,
		name: 'Зуевка',
	},
	{
		id: 324,
		name: 'Ивангород',
	},
	{
		id: 325,
		name: 'Иваново',
	},
	{
		id: 326,
		name: 'Ивантеевка',
	},
	{
		id: 327,
		name: 'Ивдель',
	},
	{
		id: 328,
		name: 'Игарка',
	},
	{
		id: 329,
		name: 'Ижевск',
	},
	{
		id: 330,
		name: 'Избербаш',
	},
	{
		id: 331,
		name: 'Изобильный',
	},
	{
		id: 332,
		name: 'Иланский',
	},
	{
		id: 333,
		name: 'Инза',
	},
	{
		id: 334,
		name: 'Иннополис',
	},
	{
		id: 335,
		name: 'Инсар',
	},
	{
		id: 336,
		name: 'Инта',
	},
	{
		id: 337,
		name: 'Ипатово',
	},
	{
		id: 338,
		name: 'Ирбит',
	},
	{
		id: 339,
		name: 'Иркутск',
	},
	{
		id: 340,
		name: 'Исилькуль',
	},
	{
		id: 341,
		name: 'Искитим',
	},
	{
		id: 342,
		name: 'Истра',
	},
	{
		id: 343,
		name: 'Ишим',
	},
	{
		id: 344,
		name: 'Ишимбай',
	},
	{
		id: 345,
		name: 'Йошкар-Ола',
	},
	{
		id: 346,
		name: 'Кадников',
	},
	{
		id: 347,
		name: 'Казань',
	},
	{
		id: 348,
		name: 'Калач-на-Дону',
	},
	{
		id: 349,
		name: 'Калач',
	},
	{
		id: 350,
		name: 'Калачинск',
	},
	{
		id: 351,
		name: 'Калининград',
	},
	{
		id: 352,
		name: 'Калининск',
	},
	{
		id: 353,
		name: 'Калтан',
	},
	{
		id: 354,
		name: 'Калуга',
	},
	{
		id: 355,
		name: 'Калязин',
	},
	{
		id: 356,
		name: 'Камбарка',
	},
	{
		id: 357,
		name: 'Каменка',
	},
	{
		id: 358,
		name: 'Каменногорск',
	},
	{
		id: 359,
		name: 'Каменск-Уральский',
	},
	{
		id: 360,
		name: 'Каменск-Шахтинский',
	},
	{
		id: 361,
		name: 'Камень-на-Оби',
	},
	{
		id: 362,
		name: 'Камешково',
	},
	{
		id: 363,
		name: 'Камызяк',
	},
	{
		id: 364,
		name: 'Камышин',
	},
	{
		id: 365,
		name: 'Камышлов',
	},
	{
		id: 366,
		name: 'Канаш',
	},
	{
		id: 367,
		name: 'Кандалакша',
	},
	{
		id: 368,
		name: 'Канск',
	},
	{
		id: 369,
		name: 'Карабаново',
	},
	{
		id: 370,
		name: 'Карабаш',
	},
	{
		id: 371,
		name: 'Карабулак',
	},
	{
		id: 372,
		name: 'Карасук',
	},
	{
		id: 373,
		name: 'Карачаевск',
	},
	{
		id: 374,
		name: 'Карачев',
	},
	{
		id: 375,
		name: 'Каргат',
	},
	{
		id: 376,
		name: 'Каргополь',
	},
	{
		id: 377,
		name: 'Карпинск',
	},
	{
		id: 378,
		name: 'Карталы',
	},
	{
		id: 379,
		name: 'Касимов',
	},
	{
		id: 380,
		name: 'Касли',
	},
	{
		id: 381,
		name: 'Каспийск',
	},
	{
		id: 382,
		name: 'Катав-Ивановск',
	},
	{
		id: 383,
		name: 'Катайск',
	},
	{
		id: 384,
		name: 'Качканар',
	},
	{
		id: 385,
		name: 'Кашин',
	},
	{
		id: 386,
		name: 'Кашира',
	},
	{
		id: 387,
		name: 'Кедровый',
	},
	{
		id: 388,
		name: 'Кемерово',
	},
	{
		id: 389,
		name: 'Кемь',
	},
	{
		id: 390,
		name: 'Керчь',
	},
	{
		id: 391,
		name: 'Кизел',
	},
	{
		id: 392,
		name: 'Кизилюрт',
	},
	{
		id: 393,
		name: 'Кизляр',
	},
	{
		id: 394,
		name: 'Кимовск',
	},
	{
		id: 395,
		name: 'Кимры',
	},
	{
		id: 396,
		name: 'Кингисепп',
	},
	{
		id: 397,
		name: 'Кинель',
	},
	{
		id: 398,
		name: 'Кинешма',
	},
	{
		id: 399,
		name: 'Киреевск',
	},
	{
		id: 400,
		name: 'Киренск',
	},
	{
		id: 401,
		name: 'Киржач',
	},
	{
		id: 402,
		name: 'Кириллов',
	},
	{
		id: 403,
		name: 'Кириши',
	},
	{
		id: 404,
		name: 'Киров',
	},
	{
		id: 405,
		name: 'Кировград',
	},
	{
		id: 406,
		name: 'Кирово-Чепецк',
	},
	{
		id: 407,
		name: 'Кировск',
	},
	{
		id: 408,
		name: 'Кирс',
	},
	{
		id: 409,
		name: 'Кирсанов',
	},
	{
		id: 410,
		name: 'Киселёвск',
	},
	{
		id: 411,
		name: 'Кисловодск',
	},
	{
		id: 412,
		name: 'Клин',
	},
	{
		id: 413,
		name: 'Клинцы',
	},
	{
		id: 414,
		name: 'Княгинино',
	},
	{
		id: 415,
		name: 'Ковдор',
	},
	{
		id: 416,
		name: 'Ковров',
	},
	{
		id: 417,
		name: 'Ковылкино',
	},
	{
		id: 418,
		name: 'Когалым',
	},
	{
		id: 419,
		name: 'Кодинск',
	},
	{
		id: 420,
		name: 'Козельск',
	},
	{
		id: 421,
		name: 'Козловка',
	},
	{
		id: 422,
		name: 'Козьмодемьянск',
	},
	{
		id: 423,
		name: 'Кола',
	},
	{
		id: 424,
		name: 'Кологрив',
	},
	{
		id: 425,
		name: 'Коломна',
	},
	{
		id: 426,
		name: 'Колпашево',
	},
	{
		id: 427,
		name: 'Кольчугино',
	},
	{
		id: 428,
		name: 'Коммунар',
	},
	{
		id: 429,
		name: 'Комсомольск-на-Амуре',
	},
	{
		id: 430,
		name: 'Комсомольск',
	},
	{
		id: 431,
		name: 'Конаково',
	},
	{
		id: 432,
		name: 'Кондопога',
	},
	{
		id: 433,
		name: 'Кондрово',
	},
	{
		id: 434,
		name: 'Константиновск',
	},
	{
		id: 435,
		name: 'Копейск',
	},
	{
		id: 436,
		name: 'Кораблино',
	},
	{
		id: 437,
		name: 'Кореновск',
	},
	{
		id: 438,
		name: 'Коркино',
	},
	{
		id: 439,
		name: 'Королёв',
	},
	{
		id: 440,
		name: 'Короча',
	},
	{
		id: 441,
		name: 'Корсаков',
	},
	{
		id: 442,
		name: 'Коряжма',
	},
	{
		id: 443,
		name: 'Костерёво',
	},
	{
		id: 444,
		name: 'Костомукша',
	},
	{
		id: 445,
		name: 'Кострома',
	},
	{
		id: 446,
		name: 'Котельники',
	},
	{
		id: 447,
		name: 'Котельниково',
	},
	{
		id: 448,
		name: 'Котельнич',
	},
	{
		id: 449,
		name: 'Котлас',
	},
	{
		id: 450,
		name: 'Котово',
	},
	{
		id: 451,
		name: 'Котовск',
	},
	{
		id: 452,
		name: 'Кохма',
	},
	{
		id: 453,
		name: 'Красавино',
	},
	{
		id: 454,
		name: 'Красноармейск',
	},
	{
		id: 455,
		name: 'Красновишерск',
	},
	{
		id: 456,
		name: 'Красногорск',
	},
	{
		id: 457,
		name: 'Краснодар',
	},
	{
		id: 458,
		name: 'Краснозаводск',
	},
	{
		id: 459,
		name: 'Краснознаменск',
	},
	{
		id: 460,
		name: 'Краснокаменск',
	},
	{
		id: 461,
		name: 'Краснокамск',
	},
	{
		id: 462,
		name: 'Красноперекопск',
	},
	{
		id: 463,
		name: 'Краснослободск',
	},
	{
		id: 464,
		name: 'Краснотурьинск',
	},
	{
		id: 465,
		name: 'Красноуральск',
	},
	{
		id: 466,
		name: 'Красноуфимск',
	},
	{
		id: 467,
		name: 'Красноярск',
	},
	{
		id: 468,
		name: 'Красный Кут',
	},
	{
		id: 469,
		name: 'Красный Сулин',
	},
	{
		id: 470,
		name: 'Красный Холм',
	},
	{
		id: 471,
		name: 'Кремёнки',
	},
	{
		id: 472,
		name: 'Кропоткин',
	},
	{
		id: 473,
		name: 'Крымск',
	},
	{
		id: 474,
		name: 'Кстово',
	},
	{
		id: 475,
		name: 'Кубинка',
	},
	{
		id: 476,
		name: 'Кувандык',
	},
	{
		id: 477,
		name: 'Кувшиново',
	},
	{
		id: 478,
		name: 'Кудрово',
	},
	{
		id: 479,
		name: 'Кудымкар',
	},
	{
		id: 480,
		name: 'Кузнецк',
	},
	{
		id: 481,
		name: 'Куйбышев',
	},
	{
		id: 482,
		name: 'Кукмор',
	},
	{
		id: 483,
		name: 'Кулебаки',
	},
	{
		id: 484,
		name: 'Кумертау',
	},
	{
		id: 485,
		name: 'Кунгур',
	},
	{
		id: 486,
		name: 'Купино',
	},
	{
		id: 487,
		name: 'Курган',
	},
	{
		id: 488,
		name: 'Курганинск',
	},
	{
		id: 489,
		name: 'Курильск',
	},
	{
		id: 490,
		name: 'Курлово',
	},
	{
		id: 491,
		name: 'Куровское',
	},
	{
		id: 492,
		name: 'Курск',
	},
	{
		id: 493,
		name: 'Куртамыш',
	},
	{
		id: 494,
		name: 'Курчалой',
	},
	{
		id: 495,
		name: 'Курчатов',
	},
	{
		id: 496,
		name: 'Куса',
	},
	{
		id: 497,
		name: 'Кушва',
	},
	{
		id: 498,
		name: 'Кызыл',
	},
	{
		id: 499,
		name: 'Кыштым',
	},
	{
		id: 500,
		name: 'Кяхта',
	},
	{
		id: 501,
		name: 'Лабинск',
	},
	{
		id: 502,
		name: 'Лабытнанги',
	},
	{
		id: 503,
		name: 'Лагань',
	},
	{
		id: 504,
		name: 'Ладушкин',
	},
	{
		id: 505,
		name: 'Лаишево',
	},
	{
		id: 506,
		name: 'Лакинск',
	},
	{
		id: 507,
		name: 'Лангепас',
	},
	{
		id: 508,
		name: 'Лахденпохья',
	},
	{
		id: 509,
		name: 'Лебедянь',
	},
	{
		id: 510,
		name: 'Лениногорск',
	},
	{
		id: 511,
		name: 'Ленинск-Кузнецкий',
	},
	{
		id: 512,
		name: 'Ленинск',
	},
	{
		id: 513,
		name: 'Ленск',
	},
	{
		id: 514,
		name: 'Лермонтов',
	},
	{
		id: 515,
		name: 'Лесной',
	},
	{
		id: 516,
		name: 'Лесозаводск',
	},
	{
		id: 517,
		name: 'Лесосибирск',
	},
	{
		id: 518,
		name: 'Ливны',
	},
	{
		id: 519,
		name: 'Ликино-Дулёво',
	},
	{
		id: 520,
		name: 'Липецк',
	},
	{
		id: 521,
		name: 'Липки',
	},
	{
		id: 522,
		name: 'Лиски',
	},
	{
		id: 523,
		name: 'Лихославль',
	},
	{
		id: 524,
		name: 'Лобня',
	},
	{
		id: 525,
		name: 'Лодейное Поле',
	},
	{
		id: 526,
		name: 'Лосино-Петровский',
	},
	{
		id: 527,
		name: 'Луга',
	},
	{
		id: 528,
		name: 'Луза',
	},
	{
		id: 529,
		name: 'Лукоянов',
	},
	{
		id: 530,
		name: 'Луховицы',
	},
	{
		id: 531,
		name: 'Лысково',
	},
	{
		id: 532,
		name: 'Лысьва',
	},
	{
		id: 533,
		name: 'Лыткарино',
	},
	{
		id: 534,
		name: 'Льгов',
	},
	{
		id: 535,
		name: 'Любань',
	},
	{
		id: 536,
		name: 'Люберцы',
	},
	{
		id: 537,
		name: 'Любим',
	},
	{
		id: 538,
		name: 'Людиново',
	},
	{
		id: 539,
		name: 'Лянтор',
	},
	{
		id: 540,
		name: 'Магадан',
	},
	{
		id: 541,
		name: 'Магас',
	},
	{
		id: 542,
		name: 'Магнитогорск',
	},
	{
		id: 543,
		name: 'Майкоп',
	},
	{
		id: 544,
		name: 'Майский',
	},
	{
		id: 545,
		name: 'Макаров',
	},
	{
		id: 546,
		name: 'Макарьев',
	},
	{
		id: 547,
		name: 'Макушино',
	},
	{
		id: 548,
		name: 'Малая Вишера',
	},
	{
		id: 549,
		name: 'Малгобек',
	},
	{
		id: 550,
		name: 'Малмыж',
	},
	{
		id: 551,
		name: 'Малоархангельск',
	},
	{
		id: 552,
		name: 'Малоярославец',
	},
	{
		id: 553,
		name: 'Мамадыш',
	},
	{
		id: 554,
		name: 'Мамоново',
	},
	{
		id: 555,
		name: 'Мантурово',
	},
	{
		id: 556,
		name: 'Мариинск',
	},
	{
		id: 557,
		name: 'Мариинский Посад',
	},
	{
		id: 558,
		name: 'Маркс',
	},
	{
		id: 559,
		name: 'Махачкала',
	},
	{
		id: 560,
		name: 'Мглин',
	},
	{
		id: 561,
		name: 'Мегион',
	},
	{
		id: 562,
		name: 'Медвежьегорск',
	},
	{
		id: 563,
		name: 'Медногорск',
	},
	{
		id: 564,
		name: 'Медынь',
	},
	{
		id: 565,
		name: 'Межгорье',
	},
	{
		id: 566,
		name: 'Междуреченск',
	},
	{
		id: 567,
		name: 'Мезень',
	},
	{
		id: 568,
		name: 'Меленки',
	},
	{
		id: 569,
		name: 'Мелеуз',
	},
	{
		id: 570,
		name: 'Менделеевск',
	},
	{
		id: 571,
		name: 'Мензелинск',
	},
	{
		id: 572,
		name: 'Мещовск',
	},
	{
		id: 573,
		name: 'Миасс',
	},
	{
		id: 574,
		name: 'Микунь',
	},
	{
		id: 575,
		name: 'Миллерово',
	},
	{
		id: 576,
		name: 'Минеральные Воды',
	},
	{
		id: 577,
		name: 'Минусинск',
	},
	{
		id: 578,
		name: 'Миньяр',
	},
	{
		id: 579,
		name: 'Мирный',
	},
	{
		id: 580,
		name: 'Михайлов',
	},
	{
		id: 581,
		name: 'Михайловка',
	},
	{
		id: 582,
		name: 'Михайловск',
	},
	{
		id: 583,
		name: 'Мичуринск',
	},
	{
		id: 584,
		name: 'Могоча',
	},
	{
		id: 585,
		name: 'Можайск',
	},
	{
		id: 586,
		name: 'Можга',
	},
	{
		id: 587,
		name: 'Моздок',
	},
	{
		id: 588,
		name: 'Мончегорск',
	},
	{
		id: 589,
		name: 'Морозовск',
	},
	{
		id: 590,
		name: 'Моршанск',
	},
	{
		id: 591,
		name: 'Мосальск',
	},
	{
		id: 592,
		name: 'Москва',
	},
	{
		id: 593,
		name: 'Муравленко',
	},
	{
		id: 594,
		name: 'Мураши',
	},
	{
		id: 595,
		name: 'Мурино',
	},
	{
		id: 596,
		name: 'Мурманск',
	},
	{
		id: 597,
		name: 'Муром',
	},
	{
		id: 598,
		name: 'Мценск',
	},
	{
		id: 599,
		name: 'Мыски',
	},
	{
		id: 600,
		name: 'Мытищи',
	},
	{
		id: 601,
		name: 'Мышкин',
	},
	{
		id: 602,
		name: 'Набережные Челны',
	},
	{
		id: 603,
		name: 'Навашино',
	},
	{
		id: 604,
		name: 'Наволоки',
	},
	{
		id: 605,
		name: 'Надым',
	},
	{
		id: 606,
		name: 'Назарово',
	},
	{
		id: 607,
		name: 'Назрань',
	},
	{
		id: 608,
		name: 'Называевск',
	},
	{
		id: 609,
		name: 'Нальчик',
	},
	{
		id: 610,
		name: 'Нариманов',
	},
	{
		id: 611,
		name: 'Наро-Фоминск',
	},
	{
		id: 612,
		name: 'Нарткала',
	},
	{
		id: 613,
		name: 'Нарьян-Мар',
	},
	{
		id: 614,
		name: 'Находка',
	},
	{
		id: 615,
		name: 'Невель',
	},
	{
		id: 616,
		name: 'Невельск',
	},
	{
		id: 617,
		name: 'Невинномысск',
	},
	{
		id: 618,
		name: 'Невьянск',
	},
	{
		id: 619,
		name: 'Нелидово',
	},
	{
		id: 620,
		name: 'Неман',
	},
	{
		id: 621,
		name: 'Нерехта',
	},
	{
		id: 622,
		name: 'Нерчинск',
	},
	{
		id: 623,
		name: 'Нерюнгри',
	},
	{
		id: 624,
		name: 'Нестеров',
	},
	{
		id: 625,
		name: 'Нефтегорск',
	},
	{
		id: 626,
		name: 'Нефтекамск',
	},
	{
		id: 627,
		name: 'Нефтекумск',
	},
	{
		id: 628,
		name: 'Нефтеюганск',
	},
	{
		id: 629,
		name: 'Нея',
	},
	{
		id: 630,
		name: 'Нижневартовск',
	},
	{
		id: 631,
		name: 'Нижнекамск',
	},
	{
		id: 632,
		name: 'Нижнеудинск',
	},
	{
		id: 633,
		name: 'Нижние Серги',
	},
	{
		id: 634,
		name: 'Нижний Ломов',
	},
	{
		id: 635,
		name: 'Нижний Новгород',
	},
	{
		id: 636,
		name: 'Нижний Тагил',
	},
	{
		id: 637,
		name: 'Нижняя Салда',
	},
	{
		id: 638,
		name: 'Нижняя Тура',
	},
	{
		id: 639,
		name: 'Николаевск-на-Амуре',
	},
	{
		id: 640,
		name: 'Николаевск',
	},
	{
		id: 641,
		name: 'Никольск',
	},
	{
		id: 642,
		name: 'Никольское',
	},
	{
		id: 643,
		name: 'Новая Ладога',
	},
	{
		id: 644,
		name: 'Новая Ляля',
	},
	{
		id: 645,
		name: 'Новоалександровск',
	},
	{
		id: 646,
		name: 'Новоалтайск',
	},
	{
		id: 647,
		name: 'Новоаннинский',
	},
	{
		id: 648,
		name: 'Нововоронеж',
	},
	{
		id: 649,
		name: 'Новодвинск',
	},
	{
		id: 650,
		name: 'Новозыбков',
	},
	{
		id: 651,
		name: 'Новокубанск',
	},
	{
		id: 652,
		name: 'Новокузнецк',
	},
	{
		id: 653,
		name: 'Новокуйбышевск',
	},
	{
		id: 654,
		name: 'Новомичуринск',
	},
	{
		id: 655,
		name: 'Новомосковск',
	},
	{
		id: 656,
		name: 'Новопавловск',
	},
	{
		id: 657,
		name: 'Новоржев',
	},
	{
		id: 658,
		name: 'Новороссийск',
	},
	{
		id: 659,
		name: 'Новосибирск',
	},
	{
		id: 660,
		name: 'Новосиль',
	},
	{
		id: 661,
		name: 'Новосокольники',
	},
	{
		id: 662,
		name: 'Новотроицк',
	},
	{
		id: 663,
		name: 'Новоузенск',
	},
	{
		id: 664,
		name: 'Новоульяновск',
	},
	{
		id: 665,
		name: 'Новоуральск',
	},
	{
		id: 666,
		name: 'Новохопёрск',
	},
	{
		id: 667,
		name: 'Новочебоксарск',
	},
	{
		id: 668,
		name: 'Новочеркасск',
	},
	{
		id: 669,
		name: 'Новошахтинск',
	},
	{
		id: 670,
		name: 'Новый Оскол',
	},
	{
		id: 671,
		name: 'Новый Уренгой',
	},
	{
		id: 672,
		name: 'Ногинск',
	},
	{
		id: 673,
		name: 'Нолинск',
	},
	{
		id: 674,
		name: 'Норильск',
	},
	{
		id: 675,
		name: 'Ноябрьск',
	},
	{
		id: 676,
		name: 'Нурлат',
	},
	{
		id: 677,
		name: 'Нытва',
	},
	{
		id: 678,
		name: 'Нюрба',
	},
	{
		id: 679,
		name: 'Нягань',
	},
	{
		id: 680,
		name: 'Нязепетровск',
	},
	{
		id: 681,
		name: 'Няндома',
	},
	{
		id: 682,
		name: 'Облучье',
	},
	{
		id: 683,
		name: 'Обнинск',
	},
	{
		id: 684,
		name: 'Обоянь',
	},
	{
		id: 685,
		name: 'Обь',
	},
	{
		id: 686,
		name: 'Одинцово',
	},
	{
		id: 687,
		name: 'Озёрск',
	},
	{
		id: 688,
		name: 'Озёры',
	},
	{
		id: 689,
		name: 'Октябрьск',
	},
	{
		id: 690,
		name: 'Октябрьский',
	},
	{
		id: 691,
		name: 'Окуловка',
	},
	{
		id: 692,
		name: 'Оленегорск',
	},
	{
		id: 693,
		name: 'Олонец',
	},
	{
		id: 694,
		name: 'Олёкминск',
	},
	{
		id: 695,
		name: 'Омск',
	},
	{
		id: 696,
		name: 'Омутнинск',
	},
	{
		id: 697,
		name: 'Онега',
	},
	{
		id: 698,
		name: 'Опочка',
	},
	{
		id: 699,
		name: 'Оренбург',
	},
	{
		id: 700,
		name: 'Орехово-Зуево',
	},
	{
		id: 701,
		name: 'Орлов',
	},
	{
		id: 702,
		name: 'Орск',
	},
	{
		id: 703,
		name: 'Орёл',
	},
	{
		id: 704,
		name: 'Оса',
	},
	{
		id: 705,
		name: 'Осинники',
	},
	{
		id: 706,
		name: 'Осташков',
	},
	{
		id: 707,
		name: 'Остров',
	},
	{
		id: 708,
		name: 'Островной',
	},
	{
		id: 709,
		name: 'Острогожск',
	},
	{
		id: 710,
		name: 'Отрадное',
	},
	{
		id: 711,
		name: 'Отрадный',
	},
	{
		id: 712,
		name: 'Оха',
	},
	{
		id: 713,
		name: 'Оханск',
	},
	{
		id: 714,
		name: 'Очёр',
	},
	{
		id: 715,
		name: 'Павлово',
	},
	{
		id: 716,
		name: 'Павловск',
	},
	{
		id: 717,
		name: 'Павловский Посад',
	},
	{
		id: 718,
		name: 'Палласовка',
	},
	{
		id: 719,
		name: 'Партизанск',
	},
	{
		id: 720,
		name: 'Певек',
	},
	{
		id: 721,
		name: 'Пенза',
	},
	{
		id: 722,
		name: 'Первомайск',
	},
	{
		id: 723,
		name: 'Первоуральск',
	},
	{
		id: 724,
		name: 'Перевоз',
	},
	{
		id: 725,
		name: 'Пересвет',
	},
	{
		id: 726,
		name: 'Переславль-Залесский',
	},
	{
		id: 727,
		name: 'Пермь',
	},
	{
		id: 728,
		name: 'Пестово',
	},
	{
		id: 729,
		name: 'Петров Вал',
	},
	{
		id: 730,
		name: 'Петровск-Забайкальский',
	},
	{
		id: 731,
		name: 'Петровск',
	},
	{
		id: 732,
		name: 'Петрозаводск',
	},
	{
		id: 733,
		name: 'Петропавловск-Камчатский',
	},
	{
		id: 734,
		name: 'Петухово',
	},
	{
		id: 735,
		name: 'Петушки',
	},
	{
		id: 736,
		name: 'Печора',
	},
	{
		id: 737,
		name: 'Печоры',
	},
	{
		id: 738,
		name: 'Пикалёво',
	},
	{
		id: 739,
		name: 'Пионерский',
	},
	{
		id: 740,
		name: 'Питкяранта',
	},
	{
		id: 741,
		name: 'Плавск',
	},
	{
		id: 742,
		name: 'Пласт',
	},
	{
		id: 743,
		name: 'Плёс',
	},
	{
		id: 744,
		name: 'Поворино',
	},
	{
		id: 745,
		name: 'Подольск',
	},
	{
		id: 746,
		name: 'Подпорожье',
	},
	{
		id: 747,
		name: 'Покачи',
	},
	{
		id: 748,
		name: 'Покров',
	},
	{
		id: 749,
		name: 'Покровск',
	},
	{
		id: 750,
		name: 'Полевской',
	},
	{
		id: 751,
		name: 'Полесск',
	},
	{
		id: 752,
		name: 'Полысаево',
	},
	{
		id: 753,
		name: 'Полярные Зори',
	},
	{
		id: 754,
		name: 'Полярный',
	},
	{
		id: 755,
		name: 'Поронайск',
	},
	{
		id: 756,
		name: 'Порхов',
	},
	{
		id: 757,
		name: 'Похвистнево',
	},
	{
		id: 758,
		name: 'Почеп',
	},
	{
		id: 759,
		name: 'Починок',
	},
	{
		id: 760,
		name: 'Пошехонье',
	},
	{
		id: 761,
		name: 'Правдинск',
	},
	{
		id: 762,
		name: 'Приволжск',
	},
	{
		id: 763,
		name: 'Приморск',
	},
	{
		id: 764,
		name: 'Приморско-Ахтарск',
	},
	{
		id: 765,
		name: 'Приозерск',
	},
	{
		id: 766,
		name: 'Прокопьевск',
	},
	{
		id: 767,
		name: 'Пролетарск',
	},
	{
		id: 768,
		name: 'Протвино',
	},
	{
		id: 769,
		name: 'Прохладный',
	},
	{
		id: 770,
		name: 'Псков',
	},
	{
		id: 771,
		name: 'Пугачёв',
	},
	{
		id: 772,
		name: 'Пудож',
	},
	{
		id: 773,
		name: 'Пустошка',
	},
	{
		id: 774,
		name: 'Пучеж',
	},
	{
		id: 775,
		name: 'Пушкино',
	},
	{
		id: 776,
		name: 'Пущино',
	},
	{
		id: 777,
		name: 'Пыталово',
	},
	{
		id: 778,
		name: 'Пыть-Ях',
	},
	{
		id: 779,
		name: 'Пятигорск',
	},
	{
		id: 780,
		name: 'Радужный',
	},
	{
		id: 781,
		name: 'Райчихинск',
	},
	{
		id: 782,
		name: 'Раменское',
	},
	{
		id: 783,
		name: 'Рассказово',
	},
	{
		id: 784,
		name: 'Ревда',
	},
	{
		id: 785,
		name: 'Реж',
	},
	{
		id: 786,
		name: 'Реутов',
	},
	{
		id: 787,
		name: 'Ржев',
	},
	{
		id: 788,
		name: 'Родники',
	},
	{
		id: 789,
		name: 'Рославль',
	},
	{
		id: 790,
		name: 'Россошь',
	},
	{
		id: 791,
		name: 'Ростов-на-Дону',
	},
	{
		id: 792,
		name: 'Ростов',
	},
	{
		id: 793,
		name: 'Рошаль',
	},
	{
		id: 794,
		name: 'Ртищево',
	},
	{
		id: 795,
		name: 'Рубцовск',
	},
	{
		id: 796,
		name: 'Рудня',
	},
	{
		id: 797,
		name: 'Руза',
	},
	{
		id: 798,
		name: 'Рузаевка',
	},
	{
		id: 799,
		name: 'Рыбинск',
	},
	{
		id: 800,
		name: 'Рыбное',
	},
	{
		id: 801,
		name: 'Рыльск',
	},
	{
		id: 802,
		name: 'Ряжск',
	},
	{
		id: 803,
		name: 'Рязань',
	},
	{
		id: 804,
		name: 'Саки',
	},
	{
		id: 805,
		name: 'Салават',
	},
	{
		id: 806,
		name: 'Салаир',
	},
	{
		id: 807,
		name: 'Салехард',
	},
	{
		id: 808,
		name: 'Сальск',
	},
	{
		id: 809,
		name: 'Самара',
	},
	{
		id: 810,
		name: 'Санкт-Петербург',
	},
	{
		id: 811,
		name: 'Саранск',
	},
	{
		id: 812,
		name: 'Сарапул',
	},
	{
		id: 813,
		name: 'Саратов',
	},
	{
		id: 814,
		name: 'Саров',
	},
	{
		id: 815,
		name: 'Сасово',
	},
	{
		id: 816,
		name: 'Сатка',
	},
	{
		id: 817,
		name: 'Сафоново',
	},
	{
		id: 818,
		name: 'Саяногорск',
	},
	{
		id: 819,
		name: 'Саянск',
	},
	{
		id: 820,
		name: 'Светлогорск',
	},
	{
		id: 821,
		name: 'Светлоград',
	},
	{
		id: 822,
		name: 'Светлый',
	},
	{
		id: 823,
		name: 'Светогорск',
	},
	{
		id: 824,
		name: 'Свирск',
	},
	{
		id: 825,
		name: 'Свободный',
	},
	{
		id: 826,
		name: 'Себеж',
	},
	{
		id: 827,
		name: 'Севастополь',
	},
	{
		id: 828,
		name: 'Северо-Курильск',
	},
	{
		id: 829,
		name: 'Северобайкальск',
	},
	{
		id: 830,
		name: 'Северодвинск',
	},
	{
		id: 831,
		name: 'Североморск',
	},
	{
		id: 832,
		name: 'Североуральск',
	},
	{
		id: 833,
		name: 'Северск',
	},
	{
		id: 834,
		name: 'Севск',
	},
	{
		id: 835,
		name: 'Сегежа',
	},
	{
		id: 836,
		name: 'Сельцо',
	},
	{
		id: 837,
		name: 'Семикаракорск',
	},
	{
		id: 838,
		name: 'Семилуки',
	},
	{
		id: 839,
		name: 'Семёнов',
	},
	{
		id: 840,
		name: 'Сенгилей',
	},
	{
		id: 841,
		name: 'Серафимович',
	},
	{
		id: 842,
		name: 'Сергач',
	},
	{
		id: 843,
		name: 'Сергиев Посад',
	},
	{
		id: 844,
		name: 'Сердобск',
	},
	{
		id: 845,
		name: 'Серов',
	},
	{
		id: 846,
		name: 'Серпухов',
	},
	{
		id: 847,
		name: 'Сертолово',
	},
	{
		id: 848,
		name: 'Сибай',
	},
	{
		id: 849,
		name: 'Сим',
	},
	{
		id: 850,
		name: 'Симферополь',
	},
	{
		id: 851,
		name: 'Сковородино',
	},
	{
		id: 852,
		name: 'Скопин',
	},
	{
		id: 853,
		name: 'Славгород',
	},
	{
		id: 854,
		name: 'Славск',
	},
	{
		id: 855,
		name: 'Славянск-на-Кубани',
	},
	{
		id: 856,
		name: 'Сланцы',
	},
	{
		id: 857,
		name: 'Слободской',
	},
	{
		id: 858,
		name: 'Слюдянка',
	},
	{
		id: 859,
		name: 'Смоленск',
	},
	{
		id: 860,
		name: 'Снежинск',
	},
	{
		id: 861,
		name: 'Снежногорск',
	},
	{
		id: 862,
		name: 'Собинка',
	},
	{
		id: 863,
		name: 'Советск',
	},
	{
		id: 864,
		name: 'Советская Гавань',
	},
	{
		id: 865,
		name: 'Советский',
	},
	{
		id: 866,
		name: 'Сокол',
	},
	{
		id: 867,
		name: 'Солигалич',
	},
	{
		id: 868,
		name: 'Соликамск',
	},
	{
		id: 869,
		name: 'Солнечногорск',
	},
	{
		id: 870,
		name: 'Соль-Илецк',
	},
	{
		id: 871,
		name: 'Сольвычегодск',
	},
	{
		id: 872,
		name: 'Сольцы',
	},
	{
		id: 873,
		name: 'Сорочинск',
	},
	{
		id: 874,
		name: 'Сорск',
	},
	{
		id: 875,
		name: 'Сортавала',
	},
	{
		id: 876,
		name: 'Сосенский',
	},
	{
		id: 877,
		name: 'Сосновка',
	},
	{
		id: 878,
		name: 'Сосновоборск',
	},
	{
		id: 879,
		name: 'Сосновый Бор',
	},
	{
		id: 880,
		name: 'Сосногорск',
	},
	{
		id: 881,
		name: 'Сочи',
	},
	{
		id: 882,
		name: 'Спас-Деменск',
	},
	{
		id: 883,
		name: 'Спас-Клепики',
	},
	{
		id: 884,
		name: 'Спасск-Дальний',
	},
	{
		id: 885,
		name: 'Спасск-Рязанский',
	},
	{
		id: 886,
		name: 'Спасск',
	},
	{
		id: 887,
		name: 'Среднеколымск',
	},
	{
		id: 888,
		name: 'Среднеуральск',
	},
	{
		id: 889,
		name: 'Сретенск',
	},
	{
		id: 890,
		name: 'Ставрополь',
	},
	{
		id: 891,
		name: 'Старая Купавна',
	},
	{
		id: 892,
		name: 'Старая Русса',
	},
	{
		id: 893,
		name: 'Старица',
	},
	{
		id: 894,
		name: 'Стародуб',
	},
	{
		id: 895,
		name: 'Старый Крым',
	},
	{
		id: 896,
		name: 'Старый Оскол',
	},
	{
		id: 897,
		name: 'Стерлитамак',
	},
	{
		id: 898,
		name: 'Стрежевой',
	},
	{
		id: 899,
		name: 'Строитель',
	},
	{
		id: 900,
		name: 'Струнино',
	},
	{
		id: 901,
		name: 'Ступино',
	},
	{
		id: 902,
		name: 'Суворов',
	},
	{
		id: 903,
		name: 'Судак',
	},
	{
		id: 904,
		name: 'Суджа',
	},
	{
		id: 905,
		name: 'Судогда',
	},
	{
		id: 906,
		name: 'Суздаль',
	},
	{
		id: 907,
		name: 'Сунжа',
	},
	{
		id: 908,
		name: 'Суоярви',
	},
	{
		id: 909,
		name: 'Сураж',
	},
	{
		id: 910,
		name: 'Сургут',
	},
	{
		id: 911,
		name: 'Суровикино',
	},
	{
		id: 912,
		name: 'Сурск',
	},
	{
		id: 913,
		name: 'Сусуман',
	},
	{
		id: 914,
		name: 'Сухиничи',
	},
	{
		id: 915,
		name: 'Сухой Лог',
	},
	{
		id: 916,
		name: 'Сызрань',
	},
	{
		id: 917,
		name: 'Сыктывкар',
	},
	{
		id: 918,
		name: 'Сысерть',
	},
	{
		id: 919,
		name: 'Сычёвка',
	},
	{
		id: 920,
		name: 'Сясьстрой',
	},
	{
		id: 921,
		name: 'Тавда',
	},
	{
		id: 922,
		name: 'Таганрог',
	},
	{
		id: 923,
		name: 'Тайга',
	},
	{
		id: 924,
		name: 'Тайшет',
	},
	{
		id: 925,
		name: 'Талдом',
	},
	{
		id: 926,
		name: 'Талица',
	},
	{
		id: 927,
		name: 'Тамбов',
	},
	{
		id: 928,
		name: 'Тара',
	},
	{
		id: 929,
		name: 'Тарко-Сале',
	},
	{
		id: 930,
		name: 'Таруса',
	},
	{
		id: 931,
		name: 'Татарск',
	},
	{
		id: 932,
		name: 'Таштагол',
	},
	{
		id: 933,
		name: 'Тверь',
	},
	{
		id: 934,
		name: 'Теберда',
	},
	{
		id: 935,
		name: 'Тейково',
	},
	{
		id: 936,
		name: 'Темников',
	},
	{
		id: 937,
		name: 'Темрюк',
	},
	{
		id: 938,
		name: 'Терек',
	},
	{
		id: 939,
		name: 'Тетюши',
	},
	{
		id: 940,
		name: 'Тимашёвск',
	},
	{
		id: 941,
		name: 'Тихвин',
	},
	{
		id: 942,
		name: 'Тихорецк',
	},
	{
		id: 943,
		name: 'Тобольск',
	},
	{
		id: 944,
		name: 'Тогучин',
	},
	{
		id: 945,
		name: 'Тольятти',
	},
	{
		id: 946,
		name: 'Томари',
	},
	{
		id: 947,
		name: 'Томмот',
	},
	{
		id: 948,
		name: 'Томск',
	},
	{
		id: 949,
		name: 'Топки',
	},
	{
		id: 950,
		name: 'Торжок',
	},
	{
		id: 951,
		name: 'Торопец',
	},
	{
		id: 952,
		name: 'Тосно',
	},
	{
		id: 953,
		name: 'Тотьма',
	},
	{
		id: 954,
		name: 'Троицк',
	},
	{
		id: 955,
		name: 'Трубчевск',
	},
	{
		id: 956,
		name: 'Трёхгорный',
	},
	{
		id: 957,
		name: 'Туапсе',
	},
	{
		id: 958,
		name: 'Туймазы',
	},
	{
		id: 959,
		name: 'Тула',
	},
	{
		id: 960,
		name: 'Тулун',
	},
	{
		id: 961,
		name: 'Туран',
	},
	{
		id: 962,
		name: 'Туринск',
	},
	{
		id: 963,
		name: 'Тутаев',
	},
	{
		id: 964,
		name: 'Тында',
	},
	{
		id: 965,
		name: 'Тырныауз',
	},
	{
		id: 966,
		name: 'Тюкалинск',
	},
	{
		id: 967,
		name: 'Тюмень',
	},
	{
		id: 968,
		name: 'Уварово',
	},
	{
		id: 969,
		name: 'Углегорск',
	},
	{
		id: 970,
		name: 'Углич',
	},
	{
		id: 971,
		name: 'Удачный',
	},
	{
		id: 972,
		name: 'Удомля',
	},
	{
		id: 973,
		name: 'Ужур',
	},
	{
		id: 974,
		name: 'Узловая',
	},
	{
		id: 975,
		name: 'Улан-Удэ',
	},
	{
		id: 976,
		name: 'Ульяновск',
	},
	{
		id: 977,
		name: 'Унеча',
	},
	{
		id: 978,
		name: 'Урай',
	},
	{
		id: 979,
		name: 'Урень',
	},
	{
		id: 980,
		name: 'Уржум',
	},
	{
		id: 981,
		name: 'Урус-Мартан',
	},
	{
		id: 982,
		name: 'Урюпинск',
	},
	{
		id: 983,
		name: 'Усинск',
	},
	{
		id: 984,
		name: 'Усмань',
	},
	{
		id: 985,
		name: 'Усолье-Сибирское',
	},
	{
		id: 986,
		name: 'Усолье',
	},
	{
		id: 987,
		name: 'Уссурийск',
	},
	{
		id: 988,
		name: 'Усть-Джегута',
	},
	{
		id: 989,
		name: 'Усть-Илимск',
	},
	{
		id: 990,
		name: 'Усть-Катав',
	},
	{
		id: 991,
		name: 'Усть-Кут',
	},
	{
		id: 992,
		name: 'Усть-Лабинск',
	},
	{
		id: 993,
		name: 'Устюжна',
	},
	{
		id: 994,
		name: 'Уфа',
	},
	{
		id: 995,
		name: 'Ухта',
	},
	{
		id: 996,
		name: 'Учалы',
	},
	{
		id: 997,
		name: 'Уяр',
	},
	{
		id: 998,
		name: 'Фатеж',
	},
	{
		id: 999,
		name: 'Феодосия',
	},
	{
		id: 1000,
		name: 'Фокино',
	},
	{
		id: 1001,
		name: 'Фролово',
	},
	{
		id: 1002,
		name: 'Фрязино',
	},
	{
		id: 1003,
		name: 'Фурманов',
	},
	{
		id: 1004,
		name: 'Хабаровск',
	},
	{
		id: 1005,
		name: 'Хадыженск',
	},
	{
		id: 1006,
		name: 'Ханты-Мансийск',
	},
	{
		id: 1007,
		name: 'Харабали',
	},
	{
		id: 1008,
		name: 'Харовск',
	},
	{
		id: 1009,
		name: 'Хасавюрт',
	},
	{
		id: 1010,
		name: 'Хвалынск',
	},
	{
		id: 1011,
		name: 'Хилок',
	},
	{
		id: 1012,
		name: 'Химки',
	},
	{
		id: 1013,
		name: 'Холм',
	},
	{
		id: 1014,
		name: 'Холмск',
	},
	{
		id: 1015,
		name: 'Хотьково',
	},
	{
		id: 1016,
		name: 'Цивильск',
	},
	{
		id: 1017,
		name: 'Цимлянск',
	},
	{
		id: 1018,
		name: 'Циолковский',
	},
	{
		id: 1019,
		name: 'Чадан',
	},
	{
		id: 1020,
		name: 'Чайковский',
	},
	{
		id: 1021,
		name: 'Чапаевск',
	},
	{
		id: 1022,
		name: 'Чаплыгин',
	},
	{
		id: 1023,
		name: 'Чебаркуль',
	},
	{
		id: 1024,
		name: 'Чебоксары',
	},
	{
		id: 1025,
		name: 'Чегем',
	},
	{
		id: 1026,
		name: 'Чекалин',
	},
	{
		id: 1027,
		name: 'Челябинск',
	},
	{
		id: 1028,
		name: 'Чердынь',
	},
	{
		id: 1029,
		name: 'Черемхово',
	},
	{
		id: 1030,
		name: 'Черепаново',
	},
	{
		id: 1031,
		name: 'Череповец',
	},
	{
		id: 1032,
		name: 'Черкесск',
	},
	{
		id: 1033,
		name: 'Черноголовка',
	},
	{
		id: 1034,
		name: 'Черногорск',
	},
	{
		id: 1035,
		name: 'Чернушка',
	},
	{
		id: 1036,
		name: 'Черняховск',
	},
	{
		id: 1037,
		name: 'Чехов',
	},
	{
		id: 1038,
		name: 'Чистополь',
	},
	{
		id: 1039,
		name: 'Чита',
	},
	{
		id: 1040,
		name: 'Чкаловск',
	},
	{
		id: 1041,
		name: 'Чудово',
	},
	{
		id: 1042,
		name: 'Чулым',
	},
	{
		id: 1043,
		name: 'Чусовой',
	},
	{
		id: 1044,
		name: 'Чухлома',
	},
	{
		id: 1045,
		name: 'Чёрмоз',
	},
	{
		id: 1046,
		name: 'Шагонар',
	},
	{
		id: 1047,
		name: 'Шадринск',
	},
	{
		id: 1048,
		name: 'Шали',
	},
	{
		id: 1049,
		name: 'Шарыпово',
	},
	{
		id: 1050,
		name: 'Шарья',
	},
	{
		id: 1051,
		name: 'Шатура',
	},
	{
		id: 1052,
		name: 'Шахты',
	},
	{
		id: 1053,
		name: 'Шахунья',
	},
	{
		id: 1054,
		name: 'Шацк',
	},
	{
		id: 1055,
		name: 'Шебекино',
	},
	{
		id: 1056,
		name: 'Шелехов',
	},
	{
		id: 1057,
		name: 'Шенкурск',
	},
	{
		id: 1058,
		name: 'Шилка',
	},
	{
		id: 1059,
		name: 'Шимановск',
	},
	{
		id: 1060,
		name: 'Шиханы',
	},
	{
		id: 1061,
		name: 'Шлиссельбург',
	},
	{
		id: 1062,
		name: 'Шумерля',
	},
	{
		id: 1063,
		name: 'Шумиха',
	},
	{
		id: 1064,
		name: 'Шуя',
	},
	{
		id: 1065,
		name: 'Щигры',
	},
	{
		id: 1066,
		name: 'Щучье',
	},
	{
		id: 1067,
		name: 'Щёкино',
	},
	{
		id: 1068,
		name: 'Щёлкино',
	},
	{
		id: 1069,
		name: 'Щёлково',
	},
	{
		id: 1070,
		name: 'Электрогорск',
	},
	{
		id: 1071,
		name: 'Электросталь',
	},
	{
		id: 1072,
		name: 'Электроугли',
	},
	{
		id: 1073,
		name: 'Элиста',
	},
	{
		id: 1074,
		name: 'Энгельс',
	},
	{
		id: 1075,
		name: 'Эртиль',
	},
	{
		id: 1076,
		name: 'Югорск',
	},
	{
		id: 1077,
		name: 'Южа',
	},
	{
		id: 1078,
		name: 'Южно-Сахалинск',
	},
	{
		id: 1079,
		name: 'Южно-Сухокумск',
	},
	{
		id: 1080,
		name: 'Южноуральск',
	},
	{
		id: 1081,
		name: 'Юрга',
	},
	{
		id: 1082,
		name: 'Юрьев-Польский',
	},
	{
		id: 1083,
		name: 'Юрьевец',
	},
	{
		id: 1084,
		name: 'Юрюзань',
	},
	{
		id: 1085,
		name: 'Юхнов',
	},
	{
		id: 1086,
		name: 'Ядрин',
	},
	{
		id: 1087,
		name: 'Якутск',
	},
	{
		id: 1088,
		name: 'Ялта',
	},
	{
		id: 1089,
		name: 'Ялуторовск',
	},
	{
		id: 1090,
		name: 'Янаул',
	},
	{
		id: 1091,
		name: 'Яранск',
	},
	{
		id: 1092,
		name: 'Яровое',
	},
	{
		id: 1093,
		name: 'Ярославль',
	},
	{
		id: 1094,
		name: 'Ярцево',
	},
	{
		id: 1095,
		name: 'Ясногорск',
	},
	{
		id: 1096,
		name: 'Ясный',
	},
	{
		id: 1097,
		name: 'Яхрома',
	},
	{
		id: 1098,
		name: 'Брест',
	},
];

const mainSNGcities = [
	{ name: 'Астана' },
	{ name: 'Алматы' },
	{ name: 'Шымкент' },
	{ name: 'Караганда' },
	{ name: 'Актобе' },
	{ name: 'Бишкек' },
	{ name: 'Душанбе' },
	{ name: 'Тараз' },
	{ name: 'Ташкент' },
	{ name: 'Ашхабад' },
	{ name: 'Баку' },
	{ name: 'Гянджа' },
	{ name: 'Ереван' },
	{ name: 'Минск' },
	{ name: 'Гомель' },
	{ name: 'Могилёв' },
	{ name: 'Витебск' },
	{ name: 'Брест (Беларусь)' },
];

export const cities = rusCities
	.concat(mainSNGcities)
	.map((el, i) => ({ ...el, id: i + 1 }));
