import React, { useState } from 'react';
import styled from 'styled-components';
import FormLabel from '@mui/material/FormLabel';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import s from './partner.module.css';

const SidebarMech = ({ data, selected, setSelected, setIsOpenMenu }) => {
	const [insideArray, setInsideArray] = useState(selected);

	const handleClose = () => {
		setIsOpenMenu(false);
	};

	const handleCheck = (e, id) => {
		e.stopPropagation();

		if (!insideArray.includes(id)) {
			//добавляем id
			setInsideArray((insideArray) => [...insideArray, id]);
		} else {
			//убираем id
			const temp = insideArray.slice();
			const index = temp.indexOf(id);
			temp.splice(index, 1);
			setInsideArray(temp);
		}
	};

	const resetAll = () => {
		setInsideArray([]);
	};

	const handleSubmite = async (e) => {
		e.preventDefault();
		setSelected(insideArray);
		setIsOpenMenu(false);
	};

	return (
		<div className={s.modalContent}>
			<FormLabel
				id="  controlled-radio-buttons-group"
				style={{
					color: 'black',
					fontWeight: 700,
					fontSize: '1.4rem',
					padding: '30px 0px 15px 0px',
					display: 'block',
				}}
			>
				Поиск предприятий по видам механической обработки:
				<span className={s.close} onClick={handleClose}>
					&times;
				</span>
			</FormLabel>

			<div className={s.checkboxsWrapper}>
				<div className={s.modalBody}>
					{data?.map((item) => {
						return (
							item &&
							item?._id?.id &&
							item?._id?.name &&
							item?.count && (
								<FormControlLabel
									className="width100"
									key={item?._id?.id}
									control={
										<Checkbox
											id={item?._id?.id}
											checked={
												insideArray.includes(item?._id?.id) ? true : false
											}
											onChange={(e) => handleCheck(e, item?._id?.id)}
										/>
									}
									label={
										<div className="font-semibold">
											{item?._id.name} ({item?.count})
										</div>
									}
								/>
							)
						);
					})}
				</div>
			</div>
			<div className={s.btnWrapper}>
				<button onClick={handleSubmite}>Применить</button>
				<button onClick={resetAll}>Снять выделение</button>
			</div>
		</div>
	);
};

export default SidebarMech;
